export default {
    namespaced: true,
    state: {
        items: {}
    },
    mutations: {
        add(state, {tableName, item}) {
            if (!state.items?.[tableName]) {
                state.items[tableName] = []
            }
            
            state.items[tableName].push(item)
        },
        remove(state, {tableName, id}) {
            const i = state.items[tableName]?.map(o => o.id).indexOf(id)
            if (i > -1) {
                state.items[tableName].splice(i, 1)
            }
        }
    },
    actions: {
        get({state, commit, dispatch}, {tableName, id}) {
            
            // If the id is a number ensure it's an int. We need to check if it's a number because some components
            // allow strings to be provided as IDs, such as modulecomponents and sites.
            id = parseInt(id)
            
            let item = state.items[tableName]?.find(o => o.id === id)
            if (!item) {
                // If no object is found then an object with the ID must be immediately added to the state so that
                // subsequent dispatches will source the same object.
                item = {id: id}
                commit('add', {tableName, item})
                
                dispatch('request/get', {
                    url: 'api/component/' + tableName + '/' + id
                }, {root: true})
                    .then(o => {
                        if (o.data) {
                            o = o.data
                            // Because the object has already been added to state with only an id property, the new
                            // properties must use $set for Vue's reactivity to take affect.
                            for (const prop in o) {
                                this._vm.$set(item, prop, o[prop])
                            }
                        }
                    })
            }
            
            return item
        },
        setProp({dispatch}, {tableName, id, property, value}) {
            const item = dispatch('get', {tableName, id})
            if (item) {
                //item[property] = value
                // In case this is a new property, use $set to ensure Vue's reactivity takes effect.
                this._vm.$set(item, property, value)
            }
        },
        async insert({commit, dispatch}, {tableName, data}) {
            const o = await dispatch('request/post', {
                url: 'api/component/' + tableName,
                postData: data
            }, {root: true})
            
            const item = o.data
            commit('add', {tableName, item})
            return item
        },
        async update({commit, dispatch}, {tableName, id, data}) {
            const o = await dispatch('request/patch', {
                url: 'api/component/' + tableName + '/' + id,
                postData: data
            }, {root: true})
            
            const item = o.data
            commit('add', {tableName, item})
            return item
        },
        addMulti({commit}, {tableName, objs}) {
            objs.forEach(o => {
                commit('add', {tableName, o})
            })
        },
        // Utility for pre-caching a collection of items to avoid the UI generating lots of individual requests when
        // ItemData.get is used for individual items. For example, the view factory uses it to preload all items to be
        // presented in the listing.
        async preload({state, commit, dispatch}, {tableName, ids}) {
            if (!ids) return
            
            // Remove IDs for items already in state
            const unsetIds = ids.filter(id => {
                return !state.items[tableName]?.find(o => o.id === parseInt(id))
            })
            if (unsetIds.length === 0) return
            
            const o = await dispatch('request/post', {
                url: 'api/component/' + tableName,
                postData: {id: unsetIds},
                customHeaders: {
                    'X-Http-Method-Override': 'GET'
                }
            }, {root: true})
            const items = o.data.items
            
            items.forEach(item => {
                // If a request is sent for an item which does not exist then the
                // response's obj.data will be set to ""
                if (item !== "") {
                    commit('add', {tableName, item})
                }
            })
        },
        async archive({commit, dispatch}, {tableName, id, doArchive}) {
            await dispatch('request/post', {
                url: 'api/ListingController/archive',
                postData: {
                    tableName: tableName,
                    ids: [id], // ListingController/archive expects an array
                    archive: doArchive
                }
            }, {root: true})
            
            const item = await dispatch('get', {tableName, id})
            
            // TODO - Will this change be reflected in Vue components?
            item.isArchived = doArchive ? 1 : 0
            
            commit('cacheNeedsClearing', null, {root: true})
        },
        async delete({commit, dispatch}, {tableName, id}) {
            await dispatch('request/delete', {
                url: 'api/component/' + tableName + '/' + id
            }, {root: true})
            
            commit('cacheNeedsClearing', null, {root: true})
            
            commit('remove', {tableName, id})
            
            if (tableName === 'modulecomponents') {
                dispatch('components/init', null, {root: true})
            }
        },
        setDisplayOrder({commit}, {tableName, item}) {
            if (item.displayOrder !== undefined) {
                commit('displayOrders/set', {
                    tableName: tableName,
                    id: item.id,
                    displayOrder: item.displayOrder
                }, {root: true})
            }
        },
        async updateDisplayOrders({dispatch, rootState}, {componentId}) {
            const component = rootState.components.items.find(o => o.id === componentId)
            if (component.showDisplayOrder) {
                const tableName = component.tableName
                
                const o = await dispatch('request/get', {
                    url: 'api/component/' + tableName,
                    params: {fields: ['id', 'displayOrder']}
                }, {root: true})
                
                o.data.items.forEach((obj) => {
                    dispatch('setProp', {
                        tableName,
                        id: obj.id,
                        property: 'displayOrder',
                        value: obj.displayOrder,
                    })
                })
            }
        }
    }
}