<template>
    <select required v-model="formData.schoolId" class="form-select form-select-sm" name="school"
            oninvalid="this.setCustomValidity('Please select a school')" oninput="this.setCustomValidity('')">
        <option value="">Select&hellip;</option>
        <option v-for="school in schools" v-if="schools" :key="school.id"
                :value="school.id">
            {{ school.name }}
        </option>
    </select>
</template>

<script>
export default {
    name: "SchoolsFilter",
    computed: {
        formData() {
            return this.$store.state.odpReports.formData
        },
        schools() {
            return this.$store.state.odpReports.schools
        }
    },
    created() {
        const schoolId = this.$route.query.schoolId || ''
        this.$set(this.formData, 'schoolId', schoolId)
    },
    destroyed() {
        this.$delete(this.formData, 'schoolId');
    }
}
</script>

<style scoped>

</style>