<template>
    <section class="d-flex h-100">
        <div v-if="activeTab" class="wrapper flex-fill overflow-scroll">
            <div class="d-flex flex-column h-100">
                <!-- Right margin is used here so that when overflow-scroll kicks in for controls too wide for the
                     sidebar, some spacing appears to the right of the controls. -->
                <div class="flex-fill p-3 pe-0 me-3">
                    <PageEditorContentForm :form-id="formId" :key="activeTab"/>

                    <div v-if="activeTab === 'Edit'" class="d-grid gap-1 pb-3 text-center">
                        <PageEditorContentMoveUpBtn/>
                        <PageEditorContentMoveDownBtn/>
                    </div>
                </div>

                <footer class="border-top">
                    <PageEditorModified :form-id="formId"/>
                </footer>
            </div>
        </div>

        <div class="d-flex flex-column" style="margin-top: -1px">
            <PageEditorContentPanelTabBtn v-for="tab in tabs" :key="tab.name" :icon-class="tab.icon"
                                          :tab-name="tab.name" :active-tab-prop-name="'contentPanelActiveTab'"
                                          :border-class-name="'border-start'"/>
            <div class="flex-fill bg-body-tertiary border-start"></div>
        </div>
    </section>
</template>

<script>
import PageEditorContentMoveDownBtn from "./PageEditorContentMoveDownBtn.vue"
import PageEditorContentMoveUpBtn from "./PageEditorContentMoveUpBtn.vue"
import PageEditorContentPanelTabBtn from "./PageEditorContentPanelTabBtn.vue"
import PageEditorContentForm from "./PageEditorContentForm"
import PageEditorModified from "./PageEditorModified.vue"

export default {
    name: "PageEditorContentPanel",
    components: {
        PageEditorModified,
        PageEditorContentForm,
        PageEditorContentPanelTabBtn,
        PageEditorContentMoveUpBtn,
        PageEditorContentMoveDownBtn
    },
    data() {
        return {
            tabs: [
                {name: 'Edit', icon: 'bi-pencil'},
                {name: 'Scheduling', icon: 'bi-calendar-date'},
                {name: 'Access', icon: 'bi-person-lock'},
                {name: 'Page use', icon: 'bi-diagram-2'},
                {name: 'Settings', icon: 'bi-gear'}
            ]
        }
    },
    computed: {
        pageEditor() {
            return this.$store.state.pageEditor
        },
        pageContentId() {
            return this.pageEditor.activePageContentId
        },
        activeTab() {
            return this.pageEditor.contentPanelActiveTab
        },
        pageContent() {
            return this.$store.state.pageContentData.items.find(o => o.id === this.pageContentId)
        },
        contentId() {
            return this.pageContent?.contentId
        },
        formId() {
            return 'form:content:' + this.contentId
        }
    },
    created() {
        // Fix for iframes not being positioned properly after content panel appears.
        setTimeout(() => {
            this.$store.dispatch('pageEditor/positionAllIframes')
        }, 50)
    },
    watch: {
        activeTab: {
            immediate: true,
            async handler() {
                let fieldNames = []
                switch (this.activeTab) {
                    case 'Edit':
                        fieldNames = ['contentType']
                        break
                    case 'Scheduling':
                        fieldNames = ['validFrom', 'validTo']
                        break
                    case 'Access':
                        fieldNames = ['groupAccess']
                        break
                    case 'Page use':
                        fieldNames = ['pageUse']
                        break
                    case 'Settings':
                        fieldNames = ['']
                        break
                }

                const formId = await this.$store.dispatch('forms/createForm', {
                    tableName: 'content',
                    id: this.contentId,
                    fieldNames: fieldNames,
                    formSmall: true,
                    singularLabel: 'content',
                    onDelete: () => {
                        this.$store.dispatch('pageEditor/deleteContent', this.pageContentId)
                    }
                })

                this.$store.commit(formId + '/ckEditorToolbar', 'pageEditor')
            }
        }
    }
}
</script>

<style scoped>
.wrapper {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}

.wrapper::-webkit-scrollbar {
    display: none;  /* Safari and Chrome */
}
</style>