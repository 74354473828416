<template>
    <!-- :active-class="'btn-warning'" :active-value="isSiteTreePinned" -->
    <Button @click.native="toggleSiteTreePin" class="btn-sm">
        <i class="bi" :class="{'bi-pin':!isSiteTreePinned, 'bi-pin-angle-fill':isSiteTreePinned}"/>
    </Button>
</template>

<script>
import Button from "../../common/Button.vue"

export default {
    name: "PageEditorSiteTreePinBtn",
    components: {
        Button
    },
    computed: {
        pageEditor() {
            return this.$store.state.pageEditor
        },
        isSiteTreePinned() {
            return this.pageEditor.pinSiteTree
        }
    },
    methods: {
        toggleSiteTreePin() {
            this.$store.dispatch('pageEditor/pinSiteTree')
        }
    }
}
</script>

<style scoped>

</style>