<template>
    <div>
        <div v-if="error" class="alert alert-danger" role="alert">{{ error }}</div>

        <div class="p-3 clearfix">
            <div class="float-start">
                <form class="d-flex align-items-center" @submit.prevent="loadRows">
                    <select v-model="formData.report" class="form-select me-2" name="report">
                        <option value="">Select a report&hellip;</option>
                        <option v-for="report in reports" :value="report">{{ report }}</option>
                    </select>

                    <template v-if="formData.report">
                        <input v-model="formData.startDate" class="form-control me-2" name="start_date"
                               placeholder="dd/mm/yyyy" size="12">
                        <span class="form-text me-2">to</span>
                        <input v-model="formData.endDate" class="form-control me-2" name="end_date"
                               placeholder="dd/mm/yyyy" size="12">
                        <button class="btn btn-secondary btn-sm" @click.prevent="loadRows">Go</button>
                    </template>
                </form>
            </div>

            <div v-if="rows && rows.length" class="float-end">
                <button class="btn btn-light" type="button" @click="openPrintModal">
                    <i class="bi-printer"/>
                    <span class="visually-hidden">Print report</span>
                </button>

                <button class="btn btn-light" type="button" @click.prevent="downloadCSVData">
                    <i class="bi-download"/>
                    <span class="visually-hidden">Download CSV</span>
                </button>
            </div>
        </div>

        <p v-if="resultMessage" class="text-center">
            {{ resultMessage }}
        </p>

        <div v-if="rows && rows.length" class="p-2">
            <table class="table table-condensed table-striped">
                <thead>
                <tr>
                    <td v-for="(value, heading) in rows[0]" @click="setSortBy(heading)">
                        <span v-if="heading === sortBy"
                              :class="{ 'bi-chevron-up': sortDir === 'asc', 'bi-chevron-down': sortDir === 'desc' }"/>
                        {{ heading }}
                    </td>
                </tr>
                </thead>

                <tbody>
                <tr v-for="row in sortedRows">
                    <td v-for="(value, heading) in row">
                        {{ value }}
                    </td>
                </tr>
                </tbody>
            </table>
        </div>

        <PrintReport v-if="rows" ref="toPrint" :end-timestamp="endTimestamp" :heading="formData.report"
                     :rows="sortedRows" :start-timestamp="startTimestamp" style="display: none"/>
    </div>
</template>

<script>
import PrintReport from './PrintReport'
import moment from 'moment'
import * as CSV from 'csv-string'
import _ from 'lodash'

export default {
    name: "BigBrandBedsReports",
    components: {
        PrintReport
    },
    data() {
        return {
            reports: [
                'Feefo online sales report',
            ],
            error: '',
            formData: {
                report: '',
                startDate: '',
                endDate: '',
            },
            rows: undefined,
            iframeHtml: '',
            sortBy: '',
            sortDir: 'asc',
        }
    },
    computed: {
        sortedRows() {
            return _.orderBy(this.rows, this.sortBy, this.sortDir)
        },
        startTimestamp() {
            return moment(this.formData.startDate, 'DD-MM-YYYY').unix()
        },
        endTimestamp() {
            return moment(this.formData.endDate, 'DD-MM-YYYY').unix() + 86399 // Increase time from 00:00:00 to 23:59:59
        },
        resultMessage() {
            if (this.rows) {
                if (this.rows.length === 0) {
                    return 'No records match your search.'
                } else {
                    return this.rows.length + ' records found.'
                }
            }

            return ''
        }
    },
    methods: {
        setSortBy(heading) {
            this.sortDir = this.sortBy === heading
                ? (this.sortDir === 'asc' ? 'desc' : 'asc')
                : 'asc'

            this.sortBy = heading
        },
        loadRows() {
            switch (this.formData.report) {
                case 'Feefo online sales report':
                    this.loadFeefoReport()
                    break
            }
        },
        loadFeefoReport() {
            let brands
            let types
            let orders
            let customers
            let orderItems

            this.rows = []

            this.$store.dispatch('request/get', {
                    url: 'api/component/m_products_manufacturers',
                    params: {
                        fields: ['id', 'title', 'urlSlug'],
                    }
                })
                .then((obj) => {
                    brands = obj.data.items

                    return this.$store.dispatch('request/get', {
                        url: 'api/component/product_types',
                        params: {
                            fields: ['id', 'urlSlug'],
                        }
                    })
                })
                .then((obj) => {
                    types = obj.data.items

                    return this.$store.dispatch('request/get', {
                        url: 'api/component/m_basket_orders',
                        params: {
                            requestAReview: {
                                gt: this.startTimestamp,
                                lt: this.endTimestamp,
                            },
                            orderStatus: 'Completed'
                        }
                    })
                })
                .then((obj) => {
                    orders = obj.data.items
                    if (!orders.length) {
                        return
                    }

                    let customerIds = orders.map(a => a.customerId)
                    return this.$store.dispatch('request/post', {
                        url: 'api/component/m_registrants_registrants',
                        postData: {
                            id: customerIds
                        },
                        customHeaders: {
                            'X-Http-Method-Override': 'GET'
                        }
                    })
                })
                .then((obj) => {
                    if (obj) {
                        customers = obj.data.items

                        let orderIds = orders.map(a => a.id)
                        return this.$store.dispatch('request/post', {
                            url: 'api/component/m_basket_orderitems',
                            postData: {
                                orderId: orderIds
                            },
                            customHeaders: {
                                'X-Http-Method-Override': 'GET'
                            }
                        })
                    }
                })
                .then((obj) => {
                    if (obj) {
                        orderItems = obj.data.items

                        let productIds = orderItems.map(a => a.itemId)
                        return this.$store.dispatch('request/post', {
                            url: 'api/component/m_products_products',
                            postData: {
                                id: productIds
                            },
                            customHeaders: {
                                'X-Http-Method-Override': 'GET'
                            }
                        })
                    }
                })
                .then((obj) => {
                    if (obj) {
                        let products = obj.data.items

                        let rows = []
                        orderItems.forEach((item) => {

                            let order = orders.filter((order) => order.id === item.orderId)[0] ?? {}
                            let customer = customers.filter((customer) => customer.id === order.customerId)[0] ?? {}
                            let product = products.filter((product) => product.id === item.itemId)[0] ?? {}
                            let brand = brands.filter((brand) => brand.id === product.brand)[0] ?? {}
                            let type = types.filter((type) => type.id === product.typeId)[0] ?? {}

                            let productLink = 'https://www.bigbrandbeds.co.uk/'
                            if (brand) productLink += brand.urlSlug + '/'
                            if (type) productLink += type.urlSlug + '/'
                            productLink += product.urlSlug + '/'

                            var timestamp = order.completedDate || order.createdDate

                            rows.push({
                                'Name': customer.displayName,
                                'Email': customer.email,
                                'Date': moment(timestamp * 1000).format('DD/MM/YYYY'),
                                'Description': item.title,
                                'Merchant identifier': 'big-brand-beds',
                                'Product search code': product.sku,
                                'Order ref': order.id,
                                'Tags': 'saleschannel=web, brand=' + brand.title,
                                'Feedback date': '',
                                'Customer ref': customer.id,
                                'Amount': (item.gross / item.quantity),
                                'Currency': 'GBP',
                                'Product link': productLink,
                            })
                        })

                        this.rows = rows
                    }
                })
        },
        downloadCSVData() {
            // Headings
            let headings = Object.keys(this.rows[0])
            let csv = CSV.stringify(headings)

            // Body
            this.rows.forEach((row) => {
                let values = Object.values(row)
                csv += CSV.stringify(values)
            })

            const anchor = document.createElement('a')
            anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv)
            anchor.target = '_blank'
            anchor.download = this.formData.report + ' ' + this.getDate(new Date()) + '.csv'
            anchor.click()
        },
        getDate(timestamp) {
            return moment(timestamp).format('DD MMM YY')
        },
        openPrintModal() {
            let toPrint = this.$refs.toPrint
            let html = toPrint.$el.innerHTML

            this.$store.dispatch('modals/show', {
                componentName: 'PrintReportModal',
                obj: {
                    html: html,
                }
            })
        }
    }
}
</script>

<style scoped>
.modal-dialog {
    height: 100%
}

.modal-content {
    height: calc(100% - 60px)
}

.modal-body {
    height: calc(100% - 60px)
}

iframe {
    width: 100%;
    height: 100%;
    border: none
}
</style>