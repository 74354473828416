import {Offcanvas} from "bootstrap"

export default {
    namespaced: true,
    state: {
        pageId: undefined,
        pageIframe: undefined,
        contentAreas: [],
        contentItems: [],
        hoverContentAreaName: '',
        activeContentAreaName: '',
        hoverPageContentId: 0,
        activePageContentId: 0,
        highlightPageContentId: 0,
        toolbarDropdown: undefined,
        offcanvas: undefined,
        inProgress: false,
        siteTreeId: 'pageEditorSiteTree',
        pinSiteTree: false,
        leftSidebarActiveTab: 'Site tree',
        pagePanelActiveTab: 'Edit',
        contentPanelActiveTab: 'Edit',
        showUnpublishedContent: true,
        showPendingContent: true,
        showExpiringContent: true,
        showExpiredContent: true,
        offcanvasEnd: false,
        wheelPromise: undefined
    },
    mutations: {
        pageId(state, pageId) {
            state.pageId = pageId
        },
        pageIframe(state, pageIframe) {
            state.pageIframe = pageIframe
        },
        contentAreas(state, contentAreas) {
            state.contentAreas = contentAreas
        },
        contentItems(state, contentItems) {
            state.contentItems = contentItems
        },
        addContentItem(state, contentItem) {
            state.contentItems.push(contentItem)
        },
        hoverContentAreaName(state, contentAreaName) {
            state.hoverContentAreaName = contentAreaName
        },
        activeContentAreaName(state, contentAreaName) {
            state.activeContentAreaName = contentAreaName
        },
        hoverPageContentId(state, pageContentId) {
            state.hoverPageContentId = pageContentId
        },
        activePageContentId(state, pageContentId) {
            state.activePageContentId = pageContentId
        },
        highlightPageContentId(state, pageContentId) {
            state.highlightPageContentId = pageContentId
        },
        deselectAll(state) {
            state.hoverContentAreaName = ''
            state.activeContentAreaName = ''
            state.hoverPageContentId = 0
            state.activePageContentId = 0
        },
        toolbarDropdown(state, toolbarDropdown) {
            state.toolbarDropdown = toolbarDropdown
        },
        pinSiteTree(state, bool) {
            state.pinSiteTree = bool
        },
        toggleShowUnpublishedContent(state) {
            state.showUnpublishedContent = !state.showUnpublishedContent
        },
        toggleShowPendingContent(state) {
            state.showPendingContent = !state.showPendingContent
        },
        toggleShowExpiringContent(state) {
            state.showExpiringContent = !state.showExpiringContent
        },
        toggleShowExpiredContent(state) {
            state.showExpiredContent = !state.showExpiredContent
        },
        offcanvasEnd(state, bool) {
            state.offcanvasEnd = bool
        },
        leftSidebarActiveTab(state, name) {
            state.leftSidebarActiveTab = name
        }
    },
    actions: {
        // This is needed so that when the PageEditor loads, components don't generate errors
        // due to contentArea data  existing from previous pages.
        unset({commit}) {
            commit('contentAreas', [])
            commit('contentItems', [])
        },
        loadSettings({commit}) {
            const settings = JSON.parse(localStorage.getItem('pageEditor'))
            if (settings?.pinSiteTree) {
                commit('pinSiteTree', true)
            }
        },
        init({state, commit, dispatch}, {
            router,
            iframe: pageIframe,
            area,
            pageContentId
        }) {
            dispatch('unset')
            commit('deselectAll')
            
            commit('activeContentAreaName', area)
            if (pageContentId) {
                commit('activePageContentId', pageContentId)
                // This resulted in the page header scrolling out of view and wasn't a pleasant UX
                //setTimeout(() => {
                //dispatch('scrollToContentInstant', {pageContentId})
                //})
            }
            
            commit('pageIframe', pageIframe)
            
            const body = pageIframe.contentDocument.body
            
            // Allow users to deselect the active iframe by clicking on the page's body
            body.addEventListener('click', () => {
                // Remove ?id and ?area params
                if (state.activePageContentId || state.activeContentAreaName) {
                    router.push({name: 'pageEditor', params: {pageId: state.pageId}})
                }
                
                //commit('deselectAll')
                
                // Without the timeout, the iframes often weren't positioned properly after the sidebar was removed.
                // Update: Now that the right sidebar is permanent this issue no longer occurs.
                //setTimeout(() => {
                dispatch('positionAllIframes')
                //}, 200)
            })
            
            pageIframe.contentDocument.addEventListener('keyup', function(e) {
                dispatch('keyboardEventHandler', e)
            })
            
            window.addEventListener('resize', () => {
                dispatch('positionAllIframes')
            })
            
            // Ensures that the iframes move smoothly with the body when scrolling long pages.
            pageIframe.contentDocument.addEventListener('scroll', () => {
                dispatch('positionAllIframes')
            })
            
            const contentItems = []
            const contentAreas = []
            
            const pageHeaderNode = body.getElementsByTagName('ccms-page-header')[0]
            if (pageHeaderNode) {
                const pageHeaderContent = pageHeaderNode.querySelectorAll('[data-ccms-editor-content]')
                Array.from(pageHeaderContent).forEach(contentEl => {
                    contentEl.removeAttribute('data-ccms-editor-content')
                    
                    const pageContentId = parseInt(contentEl.id.substring(1))
                    
                    contentItems.push({
                        el: contentEl,
                        pageContentId,
                        iframe: undefined,
                        contentAreaName: 'Page header',
                        isHidden: false
                    })
                })
                
                commit('contentItems', contentItems)
                
                contentAreas.push({
                    el: pageHeaderNode,
                    name: 'Page header',
                    iframe: undefined
                })
            }
            
            const pageFooterNode = body.getElementsByTagName('ccms-page-footer')[0]
            if (pageFooterNode) {
                const pageFooterContent = pageFooterNode.querySelectorAll('[data-ccms-editor-content]')
                Array.from(pageFooterContent).forEach(contentEl => {
                    contentEl.removeAttribute('data-ccms-editor-content')
                    
                    const pageContentId = parseInt(contentEl.id.substring(1))
                    
                    contentItems.push({
                        el: contentEl,
                        pageContentId,
                        iframe: undefined,
                        contentAreaName: 'Page footer',
                        isHidden: false
                    })
                })
                
                commit('contentItems', contentItems)
            }
            
            const nodeList = body.querySelectorAll('[data-ccms-content-area]')
            Array.from(nodeList).forEach(contentArea => {
                const contentAreaName = contentArea.dataset.name
                
                // Strip out the data-ccms-editor-content attr from content within areas
                Array.from(contentArea.querySelectorAll('[data-ccms-editor-content]')).forEach(o => {
                    o.removeAttribute('data-ccms-editor-content')
                })
                
                Array.from(contentArea.childNodes).forEach((contentEl, i) => {
                    const pageContentId = parseInt(contentEl.id.substring(1))
                    
                    contentItems.push({
                        el: contentEl,
                        pageContentId,
                        iframe: undefined,
                        contentAreaName,
                        isHidden: false
                    })
                    
                    // When moving between pages with showUnpublishedContent disabled, unpublished content is displayed
                    // for a split second, creating a CLS issue. Part of the reason for the delay is that the
                    // showUnpublishedContent action relies on itemData/get, so there's a slight delay. This script is
                    // intended to fix it by hiding the content ASAP, before it's then shown again below.
                    // todo This doesn't quite work. Is should try adding a script to hide the content as early in this
                    //      action as possible, and if that fails think again.
                    //if (state.showUnpublishedContent === false) {
                    //    contentEl.style.setProperty('display', 'none', 'important')
                    //}
                    
                    // Prevent the body element's click event from deactivating the active content when clicking the
                    // active element itself.
                    contentEl.addEventListener('click', (e) => {
                        if (state.activePageContentId === pageContentId) {
                            e.stopPropagation()
                        }
                    })
                })
                
                commit('contentItems', contentItems)
                
                contentAreas.push({
                    el: contentArea,
                    name: contentAreaName,
                    iframe: undefined
                })
            })
            
            if (pageFooterNode) {
                contentAreas.push({
                    el: pageFooterNode,
                    name: 'Page footer',
                    iframe: undefined
                })
            }
            
            if (state.showUnpublishedContent) {
                dispatch('showUnpublishedContent')
            }
            
            // $nextTick is required in order for PageEditorContentOverlay to refresh when the pageId changes.
            // Without this, overlays are missing from subsequent pages
            setTimeout(() => {
                commit('contentAreas', contentAreas)
            }, 100)
        },
        showOffcanvas({state}) {
            const myOffcanvas = document.getElementById('offcanvasPageEditorContent')
            state.offcanvas = new Offcanvas(myOffcanvas)
            state.offcanvas.show()
        },
        hideOffcanvas({state}) {
            state.offcanvas.hide()
            state.offcanvas = undefined
        },
        async moveUp({state, rootState, dispatch}, pageContentId) {
            if (state.inProgress) return
            state.inProgress = true
            
            const pageItems = rootState.pageContentData.items.filter(o => o.pageId === state.pageId)
            const item = pageItems.find(o => o.id === pageContentId)
            const contentAreaItems = pageItems.filter(o => o.contentArea === item.contentArea)
            const index = contentAreaItems.map(o => o.id).indexOf(pageContentId)
            const isFirst = index === 0
            
            const content = state.contentItems.find(o => o.pageContentId === pageContentId)
            
            if (isFirst) {
                const index = state.contentAreas.map(o => o.name).indexOf(content.contentAreaName)
                const previousContentArea = state.contentAreas[index - 1]
                if (!previousContentArea) {
                    state.inProgress = false
                    return
                }
                const previousContentAreaName = previousContentArea.name
                // Note: Remember that pageContentData includes unpublished content. So if unpublished content
                //       is NOT visible, the indexes may not seem correct.
                const contentAreaItems = pageItems.filter(o => o.contentArea === previousContentAreaName)
                const newDisplayOrder = contentAreaItems.length + 1
                
                await dispatch('request/post', {
                    url: 'api/move-page-content',
                    postData: {
                        pageContentLinkId: pageContentId,
                        contentArea: previousContentAreaName,
                        displayOrder: newDisplayOrder
                    }
                }, {root: true})
                
                await dispatch('pageContentData/init', {}, {root: true})
                
                const parent = content.el.parentNode
                parent.removeChild(content.el)
                
                const contentArea = state.contentAreas.find(o => o.name === previousContentAreaName)
                contentArea.el.append(content.el)
                content.contentAreaName = previousContentAreaName
                
                await dispatch('positionAllIframes')
                
                state.inProgress = false
                
            } else {
                await dispatch('request/post', {
                    url: 'api/move-page-content',
                    postData: {
                        pageContentLinkId: pageContentId,
                        contentArea: item.contentArea,
                        displayOrder: item.displayOrder - 1
                    }
                }, {root: true})
                
                await dispatch('pageContentData/init', {}, {root: true})
                
                const previousEl = content.el.previousSibling
                previousEl?.before(content.el)
                
                await dispatch('positionAllIframes')
                
                state.inProgress = false
            }
            
            dispatch('scrollToContent', {pageContentId})
        },
        async moveDown({state, rootState, dispatch}, pageContentId) {
            if (state.inProgress) return
            state.inProgress = true
            
            const pageItems = rootState.pageContentData.items.filter(o => o.pageId === state.pageId)
            const item = pageItems.find(o => o.id === pageContentId)
            const contentAreaItems = pageItems.filter(o => o.contentArea === item.contentArea)
            const index = contentAreaItems.map(o => o.id).indexOf(pageContentId)
            const isLast = index === contentAreaItems.length - 1
            
            const content = state.contentItems.find(o => o.pageContentId === pageContentId)
            
            if (isLast) {
                const index = state.contentAreas.map(o => o.name).indexOf(content.contentAreaName)
                const nextContentArea = state.contentAreas[index + 1]
                if (!nextContentArea) {
                    state.inProgress = false
                    return
                }
                const nextContentAreaName = nextContentArea.name
                
                await dispatch('request/post', {
                    url: 'api/move-page-content',
                    postData: {
                        pageContentLinkId: pageContentId,
                        contentArea: nextContentAreaName,
                        displayOrder: 1
                    }
                }, {root: true})
                
                await dispatch('pageContentData/init', {}, {root: true})
                
                // Remove the content from the DOM
                const parent = content.el.parentNode
                parent.removeChild(content.el)
                
                // Insert the content into the top of the next content area
                const contentArea = state.contentAreas.find(o => o.name === nextContentAreaName)
                contentArea.el.prepend(content.el)
                content.contentAreaName = nextContentAreaName
                
                dispatch('positionAllIframes')
                
                state.inProgress = false
                
            } else {
                await dispatch('request/post', {
                    url: 'api/move-page-content',
                    postData: {
                        pageContentLinkId: pageContentId,
                        contentArea: item.contentArea,
                        displayOrder: item.displayOrder + 1
                    }
                }, {root: true})
                
                await dispatch('pageContentData/init', {}, {root: true})
                
                const nextEl = content.el.nextSibling
                nextEl?.after(content.el)
                
                await dispatch('positionAllIframes')
                
                state.inProgress = false
            }
            
            dispatch('scrollToContent', {pageContentId})
        },
        detachContent({state, dispatch, commit}, pageContentId) {
            dispatch('modals/show', {
                componentName: 'ConfirmModal',
                obj: {
                    modalTitle: 'Detach content',
                    modalBody: `
                        <p class="alert alert-warning">No other references to this content will be affected.</p>
                    `,
                    onConfirm: () => {
                        dispatch('request/delete', {
                            url: 'api/delete-page-content-link-id/' + pageContentId
                        }, {root: true})
                            .then(() => {
                                dispatch('removeContentFromPage', pageContentId)
                            })
                    }
                }
            }, {root: true})
        },
        deleteContent({state, dispatch, commit, rootState}, pageContentId) {
            const contentId = rootState.pageContentData.items.find(o => o.id === pageContentId)?.contentId
            
            const num = rootState.pageContentData.items.filter(o => o.contentId === contentId)?.length
            const msg = num === 1
                ? 'This is the <strong>only</strong> instance of this content in use.'
                : '<strong>' + num + '</strong> instances of this content will be removed!'
            
            dispatch('modals/show', {
                componentName: 'ConfirmModal',
                obj: {
                    modalTitle: 'Delete content',
                    modalBody: `
                        <p>` + msg + `</p>
                        <p class="alert alert-danger">This <strong>cannot</strong> be undone.</p>
                        <p class="mb-0">Are you sure?</p>
                    `,
                    onConfirm: () => {
                        dispatch('itemData/delete', {
                            tableName: 'content',
                            id: contentId
                        }, {root: true})
                            .then(() => {
                                dispatch('removeContentFromPage', pageContentId)
                            })
                    }
                }
            }, {root: true})
        },
        async removeContentFromPage({state, commit, dispatch}, pageContentId) {
            // Remove the node
            const content = state.contentItems.find(o => o.pageContentId === pageContentId)
            content.el.remove()
            
            // Remove the content's data from VueX to update Vue components
            const i = state.contentItems.map(o => o.pageContentId).indexOf(pageContentId)
            state.contentItems.splice(i, 1)
            
            commit('deselectAll')
            
            await dispatch('pageContentData/init', {}, {root: true})
            
            dispatch('positionAllIframes')
        },
        async showUnpublishedContent({state, dispatch, rootState}) {
            state.contentItems.forEach(obj => {
                const contentId = rootState.pageContentData.items.find(o => o.id === obj.pageContentId)?.contentId
                dispatch('itemData/get', {tableName: 'content', id: contentId}, {root: true})
                    .then((o) => {
                        // Hiding unpublished content
                        if (!state.showUnpublishedContent && o.status === 0) {
                            obj.isHidden = true
                            obj.el.style.setProperty('display', 'none', 'important')
                            
                            // Showing previously hidden content
                        } else if (state.showUnpublishedContent) {
                            obj.isHidden = false
                            obj.el.style.removeProperty('display')
                        }
                        
                        dispatch('positionAllIframes')
                    })
            })
        },
        positionIframe({state}, {iframe, contentEl}) {
            if (iframe) {
                // Prevents iframes from overlapping adjacent content.
                // This has to be disabled because it created an adverse effect with some iframe positioning.
                //contentEl.style.overflow = 'hidden'
                
                const rect = contentEl.getBoundingClientRect()
                
                //setTimeout(() => {
                //contentEl.style.overflow = ''
                //}, 200)
                
                iframe.style.top = rect.top + 'px'
                iframe.style.left = rect.left + 'px'
                iframe.style.width = rect.width + 'px'
                iframe.style.height = rect.height + 'px'
            }
        },
        positionAllIframes({state, dispatch}) {
            state.contentAreas.forEach(o => {
                dispatch('positionIframe', {
                    iframe: o.iframe,
                    contentEl: o.el
                })
            })
            
            state.contentItems.forEach(o => {
                dispatch('positionIframe', {
                    iframe: o.iframe,
                    contentEl: o.el
                })
            })
        },
        scrollToContentArea({state, dispatch}, {contentAreaName, behaviour}) {
            const el = state.contentAreas.find(o => o.name === contentAreaName)?.el
            dispatch('scrollTo', {el, behaviour})
        },
        scrollToContent({state, dispatch}, {pageContentId, behaviour}) {
            const el = state.contentItems.find(o => o.pageContentId === pageContentId)?.el
            dispatch('scrollTo', {el, behaviour})
        },
        async scrollToContentIfNotVisible({state, dispatch}, el) {
            const isVisible = await dispatch('isVisibleInViewport', el)
            if (!isVisible) {
                dispatch('scrollTo', {el})
            }
        },
        isVisibleInViewport({}, el) {
            const rect = el.getBoundingClientRect()
            return (
                rect.top >= 0 &&
                rect.left >= 0 &&
                rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                rect.right <= (window.innerWidth || document.documentElement.clientWidth)
            )
        },
        scrollTo({state}, {el, behaviour}) {
            // Allows scrollIntoView to scroll to slightly above the top of the element
            el.style.scrollMarginTop = '20px'
            
            el.scrollIntoView({
                behavior: behaviour || 'smooth',
                // Scrolls the element into the middle of the viewport
                block: 'center', // Y axis
                //inline: 'center' // X axis
            })
            
            el.style.scrollMarginTop = ''
        },
        showAddContentModal({state, dispatch, commit}, contentAreaName) {
            dispatch('modals/show', {
                componentName: 'AddContentModal',
                obj: {
                    onSelect: o => {
                        dispatch('addContentToPage', {
                            contentId: o.contentId,
                            contentAreaName
                        })
                    }
                }
            }, {root: true})
        },
        async addContentToPage({state, commit, dispatch}, {contentId, contentAreaName}) {
            let o = await dispatch('request/post', {
                url: 'api/save-content-to-page',
                postData: {
                    pageId: state.pageId,
                    contentId: contentId,
                    contentArea: contentAreaName
                }
            }, {root: true})
            
            const pageContentId = o.data.pageContentId
            
            await dispatch('pageContentData/init', {}, {root: true})
            
            await dispatch('placeContentInPage', {pageContentId, contentAreaName})
            
            //dispatch('scrollToContent', pageContentId)
            
            return pageContentId
        },
        async placeContentInPage({state, commit, dispatch}, {pageContentId, contentAreaName}) {
            commit('deselectAll')
            commit('activePageContentId', pageContentId)
            
            let o = await dispatch('request/get', {
                url: 'api/page-editor/content/' + pageContentId,
            }, {root: true})
            
            // Assigning the HTML to an element converts it into a DOM node
            const div = document.createElement('div')
            div.innerHTML = o.data.html
            const contentEl = div.firstChild
            
            // Move the node to the content area
            const contentAreaEl = state.contentAreas.find(o => o.name === contentAreaName).el
            contentAreaEl.append(contentEl)
            
            // Add the node
            commit('addContentItem', {
                el: contentEl,
                pageContentId,
                iframe: undefined,
                contentAreaName
            })
            
            await dispatch('positionAllIframes')
        },
        async reloadContent({state, dispatch}, pageContentId) {
            const o = await dispatch('request/get', {
                url: 'api/page-editor/content/' + pageContentId,
            }, {root: true})
            
            // Assigning the HTML to an element converts it into a DOM node
            const div = document.createElement('div')
            div.innerHTML = o.data.html
            const newContentEl = div.firstChild
            
            const content = state.contentItems.find(o => o.pageContentId === pageContentId)
            content.el.replaceWith(newContentEl)
            content.el = newContentEl
            
            // Prevent the body element's click event from deactivating the active content when clicking the
            // active element itself.
            newContentEl.addEventListener('click', (e) => {
                if (state.activePageContentId === pageContentId) {
                    e.stopPropagation()
                }
            })
            
            await dispatch('positionAllIframes')
        },
        // This should not be used as it causes the page to flicker, the current selection to be unset etc.
        // I've left it in place in case it's useful for testing.
        reloadPage({state, commit, dispatch}) {
            const pageId = state.pageId
            
            // Refresh the page editor by toggling the page ID
            commit('pageId', 0)
            
            dispatch('unset')
            setTimeout(() => {
                commit('pageId', pageId)
            }, 200)
        },
        async pinSiteTree({state, commit, dispatch}) {
            commit('pinSiteTree', !state.pinSiteTree)
            await dispatch('setLocalStorage')
            dispatch('positionAllIframes')
        },
        setLocalStorage({state}) {
            localStorage.setItem('pageEditor', JSON.stringify({
                pinSiteTree: state.pinSiteTree,
            }))
        },
        setContentAreaStyling({state}, contentArea) {
            contentArea.style.minHeight = '50px'
            contentArea.style.marginBottom = '10px'
        },
        keyboardEventHandler({state, dispatch, rootState}, event) {
            if (state.activePageContentId) {
                switch (event.key) {
                    case 'ArrowUp':
                        dispatch('moveUp', state.activePageContentId)
                        return
                    case 'ArrowDown':
                        dispatch('moveDown', state.activePageContentId)
                        return
                    case 'Backspace':
                        // Prevent content deletion when using Backspace within a form control
                        if (event.target.tagName === 'BODY') {
                            const contentId = rootState.pageContentData.items.find(o => o.id === state.activePageContentId)?.contentId
                            const num = rootState.pageContentData.items.filter(o => o.contentId === contentId)?.length
                            num > 1
                                ? dispatch('detachContent', state.activePageContentId)
                                : dispatch('deleteContent', state.activePageContentId)
                        }
                        return
                }
            }
            
            //console.log('event.key', event.key)
            //console.log('event.code', event.code)
        },
        hideIframesOnWheel({state}) {
            const contentAreaIframes = state.contentAreas.map(o => o.iframe)
            const contentIframes = state.contentItems.map(o => o.iframe)
            
            contentAreaIframes.forEach(o => o.style.display = 'none')
            contentIframes.forEach(o => o.style.display = 'none')
            
            clearTimeout(state.wheelPromise)
            state.wheelPromise = setTimeout(() => {
                contentAreaIframes.forEach(o => o.style.display = '')
                contentIframes.forEach(o => o.style.display = '')
            }, 200)
        }
    }
}