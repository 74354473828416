import $ from "expose-loader?exposes=$,jQuery!jquery";
import 'bootstrap-icons/font/bootstrap-icons.css';

import 'bootstrap'

//import 'bootstrap/dist/css/bootstrap.css'
import './css/custom.scss'
import 'bootstrap/scss/bootstrap.scss'

import './js/codemirror'

import './styles'

import Vue from 'vue'
import Vuex from 'vuex'
import store from './store'
import AsyncComputed from 'vue-async-computed'
import VueTimeago from 'vue-timeago'
import focus from './vue/directives/focus'
import select from './vue/directives/select'
import Dump from './vue/common/Dump'
import Picture from './vue/common/Picture'
import VueRouter from 'vue-router'

import Dashboard from './components/dashboard/Dashboard'
import ComponentRoot from './components/component-root/ComponentRoot'
import ComponentSettings from './components/component-settings/ComponentSettings'
import ComponentFields from './components/component-fields/ComponentFields'
import Form from './components/form/Form'
import List from './components/List'
import PageEditor from './components/page-editor/PageEditor'
import Reports from './components/reports/Reports'
import SalesReports from './components/SalesReports'
import Settings from './components/Settings'
import SiteTreePanes from './components/site-tree-panes/SiteTreePanes'
import SubscriptionPlan from './components/subscription-plan/SubscriptionPlan'
import ThemesEditor from './components/themes-editor/ThemesEditor'
import App from './App'

Vue.config.productionTip = false
Vue.config.devtools = false

Vue.use(Vuex)
Vue.use(AsyncComputed)
Vue.use(VueTimeago, {})

////////// Global directives //////////

Vue.directive('focus', focus)
Vue.directive('select', select)

////////// Global components //////////

Vue.component('Dump', Dump)
Vue.component('Picture', Picture)

////////// vue-router //////////
Vue.use(VueRouter)

const router = new VueRouter({
    routes: [
        {
            name: 'dashboard',
            path: '/',
            component: Dashboard
        },
        {
            name: 'salesReports',
            path: '/sales-reports',
            component: SalesReports
        },
        {
            name: 'settings',
            path: '/settings',
            component: Settings
        },
        {
            path: '/subscription-plan',
            component: SubscriptionPlan
        },
        {
            name: 'reports',
            path: '/reports',
            component: Reports
        },
        {
            name: 'siteTree',
            path: '/site_tree/:pageOrContentId?',
            component: SiteTreePanes
        },
        {
            name: 'pageEditor',
            path: '/editor/:pageId?/:pageContentId?',
            component: PageEditor
        },
        {
            name: 'themesEditor',
            path: '/themes_editor',
            component: ThemesEditor
        },
        {
            name: 'list',
            path: '/:component',
            component: ComponentRoot,
            children: [
                {
                    // Without naming this default child route with the same name as the parent the route
                    // failed to load when using this.$router.push({ name: 'list' });
                    name: 'list',
                    path: '',
                    component: List
                },
                {
                    name: 'componentSettings',
                    path: 'settings',
                    component: ComponentSettings,
                    meta: {
                        breadcrumb: 'Settings'
                    }
                },
                {
                    name: 'componentFields',
                    path: 'fields/:fieldsetId?/:fieldId?',
                    component: ComponentFields,
                    meta: {
                        breadcrumb: 'Attributes'
                    }
                },
                {
                    name: 'form',
                    path: ':id',
                    component: Form,
                },
            ]
        },
        {
            name: 'pageContent',
            path: '/pages/:pageId/:pageContentId',
            component: Form,
        }
    ]
})

router.beforeEach((to, from, next) => {
    
    let formId
    if (from.name === 'form') {
        formId = 'form:' + from.params.component + ':' + from.params.id
    } else if (from.name === 'settings') {
        formId = 'form:account_settings:1'
    }
    
    // Unsaved form changes confirmation modal
    if (formId) {
        const isModified = store.getters[formId + '/isModified']()
        if (isModified) {
            const modifiedData = store.getters[formId + '/modifiedData']()
            //console.log({modifiedData})
            
            store.dispatch('modals/show', {
                componentName: 'ConfirmModal',
                obj: {
                    modalTitle: 'Are you sure?',
                    modalBody: `
                        <p class="alert alert-danger">
                            Your unsaved changes will be lost.
                        </p>
                    `,
                    onConfirm: () => {
                        next()
                    }
                }
            })
            return
        }
    }
    
    //console.log(from)
    
    next()
})

////////// end: vue-router //////////

new Vue({
    el: "#app",
    template: "<App/>",
    //provide: services, // Does't work
    components: {App},
    router,
    store,
    strict: process.env.NODE_ENV !== 'production',
})