<template>
    <iframe ref="iframe" @mouseleave="onMouseleave" @mouseenter="onMouseenter"
            :class="{ hover: isHover, active: isActive, empty: isEmpty }"/>
</template>

<script>
export default {
    name: "PageEditorContentAreaIframe",
    props: {
        contentArea: Object
    },
    computed: {
        pageEditor() {
            return this.$store.state.pageEditor
        },
        hoverContentAreaName() {
            return this.pageEditor.hoverContentAreaName
        },
        activeContentAreaName() {
            return this.pageEditor.activeContentAreaName
        },
        activePageContentId() {
            return this.pageEditor.activePageContentId
        },
        contentAreaName() {
            return this.contentArea.name
        },
        isHover() {
            return this.hoverContentAreaName === this.contentAreaName
        },
        isActive() {
            return this.activeContentAreaName === this.contentAreaName
        },
        isEmpty() {
            return this.pageEditor.contentItems.filter(o => o.contentAreaName === this.contentAreaName).length === 0
        }
    },
    mounted() {
        this.contentArea.iframe = this.$refs.iframe
        this.setIframeStyling()
        this.positionIframe()

        if (this.activeContentAreaName === this.contentAreaName) {
            this.$store.dispatch('pageEditor/scrollToContentArea', {
                contentAreaName: this.activeContentAreaName,
                behaviour: 'instant'
            })
        }
    },
    watch: {
        isEmpty: {
            immediate: true,
            handler() {
                const contentArea = this.pageEditor.contentAreas.find(o => o.name === this.contentAreaName).el
                this.$store.dispatch('pageEditor/setContentAreaStyling', contentArea)
            }
        }
    },
    methods: {
        onMouseenter() {
            this.$store.commit('pageEditor/hoverContentAreaName', this.contentAreaName)
        },
        onMouseleave() {
            this.$store.commit('pageEditor/hoverContentAreaName', '')
        },
        positionIframe() {
            this.$store.dispatch('pageEditor/positionIframe', {
                iframe: this.$refs.iframe,
                contentEl: this.contentArea.el
            })
        },
        setIframeStyling() {
            const iframe = this.$refs.iframe

            addLink(iframe, 'https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.min.css')
            addLink(iframe, 'https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css')

            const bodyClassList = iframe.contentWindow.document.body.classList
            // Bootstrap sets the body's bg to white, but it must be transparent
            bodyClassList.add('bg-transparent')
            bodyClassList.add('lh-1')
            bodyClassList.add('overflow-hidden')

            function addLink(iframe, src) {
                const cssLink = document.createElement('link')
                cssLink.href = src
                cssLink.rel = 'stylesheet'
                //cssLink.type = 'text/css';
                iframe.contentDocument.head.appendChild(cssLink)
            }
        }
    }
}
</script>

<style scoped>
iframe {
    position: absolute;
    z-index: 998; /* Bootstrap's dropdown z-indexes are set to 1000 */
    /*z-index: 1053; Bootstrap's modals' z-indexes start at 1055 */
    /*z-index: 2147483647; https://stackoverflow.com/questions/491052/minimum-and-maximum-value-of-z-index; */
    overflow: hidden;

    border: 2px dashed transparent !important;
    border-radius: 5px;
}

.empty {
    min-height: 50px;
    border-color: lightgrey !important;
}

.hover {
    border-color: rgba(25, 135, 84, .5) !important;
}

.active {
    border-color: rgb(25, 135, 84) !important;
}
</style>