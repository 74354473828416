var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "modal-dialog modal-lg add-content-modal" }, [
    _c("div", { staticClass: "modal-content" }, [
      _c("div", { staticClass: "modal-header" }, [
        _c("h4", { staticClass: "modal-title" }, [_vm._v("Add content")]),
        _vm._v(" "),
        _c("button", {
          staticClass: "btn-close",
          attrs: { "aria-label": "Close", type: "button" },
          on: {
            click: function ($event) {
              return _vm.$emit("hide-modal")
            },
          },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "modal-body" }, [
        _c("form", { staticClass: "form form-horizontal" }, [
          _c("fieldset", [
            _c("legend", [_vm._v("New content")]),
            _vm._v(" "),
            _c("div", { staticClass: "mb-3 mt-3" }, [
              _c("label", { staticClass: "col-sm-3 form-label" }, [
                _vm._v("Title"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-9" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.formData.title,
                      expression: "formData.title",
                    },
                    { name: "focus", rawName: "v-focus" },
                  ],
                  staticClass: "form-control",
                  domProps: { value: _vm.formData.title },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.formData, "title", $event.target.value)
                    },
                  },
                }),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "mb-3" }, [
              _c("label", { staticClass: "col-sm-3 form-label" }, [
                _vm._v("Content type"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-9" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.formData.contentType,
                        expression: "formData.contentType",
                      },
                    ],
                    staticClass: "form-select",
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.formData,
                          "contentType",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                    },
                  },
                  _vm._l(_vm.selectOptions, function (option) {
                    return _c("option", { domProps: { value: option.value } }, [
                      _vm._v(
                        "\n                                    " +
                          _vm._s(option.text) +
                          "\n                                "
                      ),
                    ])
                  }),
                  0
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "mb-3" }, [
              _c(
                "div",
                { staticClass: "col-sm-offset-3 col-sm-9" },
                [
                  _c(
                    "Button",
                    {
                      staticClass: "border",
                      attrs: {
                        disabled: _vm.isDisabled,
                        "active-value": !_vm.isDisabled,
                      },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.saveNewContent.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                                Save\n                            "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("fieldset", { staticClass: "mt-4" }, [
            _c("legend", [_vm._v("Existing content")]),
            _vm._v(" "),
            _c("div", { staticClass: "mb-3" }, [
              _c("div", { staticClass: "col-sm-9" }, [
                _c(
                  "div",
                  { staticClass: "btn-group" },
                  [
                    _c(
                      "Button",
                      {
                        staticClass: "border",
                        nativeOn: {
                          click: function ($event) {
                            return _vm.showSiteTreeBrowser.apply(
                              null,
                              arguments
                            )
                          },
                        },
                      },
                      [_c("i", { staticClass: "bi-diagram-3-fill" })]
                    ),
                    _vm._v(" "),
                    _c(
                      "Button",
                      {
                        staticClass: "border",
                        nativeOn: {
                          click: function ($event) {
                            return _vm.showSelectList.apply(null, arguments)
                          },
                        },
                      },
                      [_vm._v("Browse…")]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }