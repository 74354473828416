<template>
    <div class="ms-3 my-1 rounded-1 bg-secondary-subtle p-2 pt-1 small">
        <SiteTreeContentArea v-if="contentAreas.length" v-for="contentArea in contentAreas" :key="contentArea"
                             :content-area="contentArea" :page-id="pageId" :site-tree-id="siteTreeId"/>
    </div>
</template>

<script>
import SiteTreeContentArea from './SiteTreeContentArea'

export default {
    name: "SiteTreeContentAreas",
    components: {SiteTreeContentArea},
    data() {
        return {
            contentAreas: []
        }
    },
    props: {
        siteTreeId: String,
        pageId: Number
    },
    asyncComputed: {
        contentAreas() {
            return this.$store.dispatch('pageTemplatesContentAreas/getPagesContentAreas', this.pageId)
        }
    }
}
</script>

<style scoped>

</style>