<template>
    <div class="modal-dialog modal-lg add-content-modal">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Add content</h4>

                <button aria-label="Close" class="btn-close" type="button" @click="$emit('hide-modal')"></button>
            </div>

            <div class="modal-body">
                <form class="form form-horizontal">
                    <fieldset>
                        <legend>New content</legend>

                        <div class="mb-3 mt-3">
                            <label class="col-sm-3 form-label">Title</label>

                            <div class="col-sm-9">
                                <input v-model="formData.title" v-focus class="form-control">
                            </div>
                        </div>

                        <div class="mb-3">
                            <label class="col-sm-3 form-label">Content type</label>

                            <div class="col-sm-9">
                                <select v-model="formData.contentType" class="form-select">
                                    <option v-for="option in selectOptions" :value="option.value">
                                        {{ option.text }}
                                    </option>
                                </select>
                            </div>
                        </div>

                        <div class="mb-3">
                            <div class="col-sm-offset-3 col-sm-9">
                                <Button @click.native="saveNewContent" :disabled="isDisabled"
                                        :active-value="!isDisabled" class="border">
                                    Save
                                </Button>
                            </div>
                        </div>
                    </fieldset>

                    <fieldset class="mt-4">
                        <legend>Existing content</legend>

                        <div class="mb-3">
                            <div class="col-sm-9">
                                <div class="btn-group">
                                    <Button @click.native="showSiteTreeBrowser" class="border"><i
                                        class="bi-diagram-3-fill"/></Button>
                                    <Button @click.native="showSelectList" class="border">Browse&hellip;</Button>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import Button from "../../common/Button.vue"

export default {
    name: "AddContentModal",
    components: {Button},
    props: {
        options: Object,
    },
    data() {
        return {
            onSelect: this.options.onSelect,
            formData: {
                title: '',
                contentType: '',
            }
        }
    },
    computed: {
        isDisabled() {
            return !this.formData.title || !this.formData.contentType
        },
        selectOptions() {
            let options = []

            this.$store.getters['templates/contentTypes'].forEach((o) => {
                options.push({
                    value: o.id,
                    text: o.title
                })
            })

            options = options.sort((a, b) => (a.text > b.text) ? 1 : -1)

            options.unshift({
                value: '',
                text: 'Select…'
            })

            return options
        }
    },
    methods: {
        showSiteTreeBrowser() {
            this.$store.dispatch('modals/show', {
                componentName: 'SiteTreeModal',
                obj: {
                    selectContentMode: true,
                    onContentSelect: pageContentId => {
                        const contentId = this.$store.state.pageContentData.items.find(o => o.id === pageContentId)?.contentId
                        this.setContentId(contentId)
                    }
                }
            })
        },
        showSelectList() {
            let modal = this.$store.dispatch('modals/show', {
                componentName: 'SelectListModal',
                obj: {
                    listingName: 'content.foo', // foo = namespace to prevent listing from loading
                    selectedIds: [],
                    onSelect: (newIds) => {
                        this.setContentId(newIds.pop())
                        modal.then((obj) => {
                            this.$store.dispatch('modals/remove', obj.index)
                        })
                    }
                }
            })
        },
        saveNewContent() {
            this.$store.dispatch('request/post', {
                    url: 'api/component/content',
                    postData: {
                        title: this.formData.title,
                        contentType: this.formData.contentType
                    }
                })
                .then((obj) => {
                    this.setContentId(obj.data.id)
                })
        },
        setContentId(contentId) {
            this.onSelect({contentId: contentId})
            this.$emit('hide-modal')
        }
    }
}
</script>

<style scoped>
.add-content-modal legend {
    font-size: 16px;
    font-weight: 400;
}
</style>