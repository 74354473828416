import moment from "moment"

export default {
    namespaced: true,
    state: {
        reports: [
            {name: 'relatedProducts', label: 'Related products'}
        ],
        formData: {
            reportName: '',
            primaryCategory: '',
            startDate: '',
            endDate: ''
        },
        isLoading: false,
        rows: undefined
    },
    mutations: {
        formData(state, formData) {
            state.formData = formData
        },
        rows(state, rows) {
            state.rows = rows
            
            state.isLoading = false
        }
    },
    getters: {
        startTimestamp(state) {
            return moment(state.formData.startDate, 'YYYY-MM-DD').unix()
        },
        endTimestamp(state) {
            // The date input type has no time, so equates to the time 00:00:00,
            // so add the number of seconds in a day minus 1 to get 23:59:59
            return moment(state.formData.endDate, 'YYYY-MM-DD').unix() + 86399
        },
        getDate: () => (timestamp) => {
            return timestamp ? moment(timestamp).format('DD/MM/YYYY') : ''
        }
    },
    actions: {
        init({state, commit, dispatch, rootState}, {query}) {
            // Support for copying and pasting URLs.
            // On load, if a query string has already been set then apply it to state.formData and load the rows
            if (
                query.reportName // Only apply the query to formData if the query string has been set with vars
                //&& JSON.stringify(query) !== JSON.stringify(state.formData)
            ) {
                Object.assign(state.formData, query)
                state.formData.reportName = query.reportName
                dispatch('loadRows')
            }
        },
        loadRows({state, commit, dispatch}) {
            commit('rows', undefined)
            
            state.isLoading = true
            
            switch (state.formData.reportName) {
                case 'relatedProducts':
                    dispatch('relatedProducts')
                    break
            }
        },
        async relatedProducts({state, dispatch, commit, getters}) {
            let o
            
            o = await dispatch('request/get', {
                url: 'api/categories/get-table-column-data/m_products_products/relatedProducts'
            }, {root: true})
            
            const categoryData = o.data
            const productIds = [...new Set(categoryData.map(o => o.itemId))]
            const categoryIds = [...new Set(categoryData.map(o => o.catId))]
            
            o = await dispatch('request/post', {
                url: 'api/component/m_products_products',
                postData: {
                    ids: productIds,
                    fields: ['id', 'title'],
                    categoryId: state.formData.primaryCategory,
                    modifiedDate: {
                        ge: getters.startTimestamp,
                        le: getters.endTimestamp
                    }
                },
                customHeaders: {
                    'X-Http-Method-Override': 'GET'
                }
            }, {root: true})
            const productTitles = o.data.items
            
            o = await dispatch('request/post', {
                url: 'api/component/m_products_products',
                postData: {
                    ids: categoryIds,
                    fields: ['id', 'status']
                },
                customHeaders: {
                    'X-Http-Method-Override': 'GET'
                }
            }, {root: true})
            const productStatuses = o.data.items
            
            const rows = []
            
            productTitles.forEach(product => {
                const href = '/#/m_products_products/' + product.id
                
                const categoryIds = categoryData.filter(o => o.itemId === product.id).map(o => o.catId)
                const statuses = productStatuses.filter(o => categoryIds.indexOf(o.id) > -1)
                const publishedCategoryIds = statuses.filter(o => o.status === 1).map(o => o.id)
                const unpublishedCategoryIds = statuses.filter(o => o.status === 0).map(o => o.id)
                const row = {
                    'Product': product.title,
                    'ID': '<a href="' + href + '">' + product.id + '</a>',
                    'No. of published products': publishedCategoryIds.length,
                    'Published products': publishedCategoryIds.join(', '),
                    'No. of unpublished products': unpublishedCategoryIds.length,
                    'Unpublished products': unpublishedCategoryIds.join(', ')
                }
                
                rows.push(row)
            })
            
            commit('rows', rows)
        }
    }
}