var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "d-flex h-100" }, [
    _vm.activeTab
      ? _c("div", { staticClass: "wrapper flex-fill overflow-scroll" }, [
          _c("div", { staticClass: "d-flex flex-column h-100" }, [
            _c(
              "div",
              { staticClass: "flex-fill p-3 pe-0 me-3 overflow-scroll" },
              [
                _vm.activeTab === "Edit"
                  ? _c("PageEditorPageForm", {
                      key: "edit",
                      attrs: { "form-id": _vm.formId },
                    })
                  : ["Scheduling", "Access", "SEO", "Settings"].indexOf(
                      _vm.activeTab
                    ) > -1
                  ? _c("PageEditorPageForm", {
                      key: _vm.activeTab,
                      attrs: { "form-id": _vm.formId },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "footer",
              { staticClass: "border-top" },
              [_c("PageEditorModified", { attrs: { "form-id": _vm.formId } })],
              1
            ),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "d-flex flex-column",
        staticStyle: { "margin-top": "-1px" },
      },
      [
        _vm._l(_vm.tabs, function (tab) {
          return _c("PageEditorContentPanelTabBtn", {
            key: tab.name,
            attrs: {
              "icon-class": tab.icon,
              "tab-name": tab.name,
              "active-tab-prop-name": "pagePanelActiveTab",
              "border-class-name": "border-start",
            },
          })
        }),
        _vm._v(" "),
        _c("div", { staticClass: "flex-fill bg-body-tertiary border-start" }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }