<template>
    <div class="container-fluid dashboard">
        <h1 class="h4 my-4">Dashboard</h1>

        <!--
        <div>
            {{ [item1?.id, item1?.title] }}
        </div>
        <div>
            {{ [item2?.id, item2?.title] }}
        </div>

        <button @click="update">Update</button>
        -->

        <div class="row">
            <div class="col-sm-6">
                <DashboardWelcome/>
                <DashboardStateHistory/>
            </div>

            <div class="col-sm-6">
                <DashboardActivityStream/>
            </div>
        </div>
    </div>
</template>

<script>
import DashboardWelcome from './DashboardWelcome'
import DashboardStateHistory from './DashboardStateHistory'
import DashboardActivityStream from './DashboardActivityStream'

export default {
    name: "Dashboard",
    components: {
        DashboardWelcome,
        DashboardStateHistory,
        DashboardActivityStream,
    },
    created() {
        this.$store.commit('navigationComponent', false)
    },
    /*asyncComputed: {
        item1() {
            return this.$store.dispatch('itemDataNew/get', {
                tableName: 'banners',
                id: 4
            })
        },
        item2() {
            return this.$store.dispatch('itemDataNew/get', {
                tableName: 'banners',
                id: 4
            })
        }
    },
    methods: {
        update() {
            this.item2.title = 'Hello'
        }
    }*/
}
</script>

<style>
.dashboard .card {
    border-top: 4px solid #00d7b2;
}

.dashboard .card-header {
    padding: 15px;
    border: none;
    background: none;
}

.dashboard-panel-state-history .card-body {
    padding-top: 0;
    height: 255px;
    overflow: scroll;
}
</style>