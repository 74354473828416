var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pt-1 list-group list-group-flush" },
    [
      _vm._l(_vm.contentAreaNames, function (contentAreaName) {
        return [
          _c("PageEditorContentNavContentArea", {
            attrs: { "content-area-name": contentAreaName },
          }),
          _vm._v(" "),
          _c("PageEditorContentNavContent", {
            attrs: { "content-area-name": contentAreaName },
          }),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }