var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("iframe", {
    ref: "iframe",
    class: { hover: _vm.isHover, active: _vm.isActive, empty: _vm.isEmpty },
    on: { mouseleave: _vm.onMouseleave, mouseenter: _vm.onMouseenter },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }