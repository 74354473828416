<template>
    <FormSidebarBtn v-if="show" @click.prevent.native="onClick" class="text-warning-emphasis">
        <i class="me-2 bi bi-x-lg"/>
        Detach from page
    </FormSidebarBtn>
</template>

<script>
import FormSidebarBtn from "./FormSidebarBtn.vue"

export default {
    name: "PageEditorDetachFromPageBtn",
    components: {FormSidebarBtn},
    props: {
        formId: String
    },
    computed: {
        tableName() {
            return this.$store.state[this.formId].tableName
        },
        isPageEditor() {
            return this.$route.name === 'pageEditor'
        },
        pageEditor() {
            return this.$store.state.pageEditor
        },
        pageContentId() {
            return this.pageEditor.activePageContentId
        },
        pageContent() {
            return this.pageEditor.contentItems.find(o => o.pageContentId === this.pageContentId)
        },
        isHeaderOrFooterContent() {
            return this.pageContent?.contentAreaName === 'Page header' || this.pageContent?.contentAreaName === 'Page footer'
        },
        show() {
            return this.isPageEditor && this.tableName === 'content' && !this.isHeaderOrFooterContent
        }
    },
    methods: {
        onClick() {
            this.$store.dispatch('pageEditor/detachContent', this.pageContentId)
        }
    }
}
</script>

<style scoped>

</style>