<template>
    <Button class="btn-sm mx-1 p-0 px-1" :disabled="!hasEditAccess" @click.native="gotToThemesEditor">
        {{ contentTypeTitle }}
    </Button>
</template>

<script>
import Button from "./Button.vue"

export default {
    name: "ContentTypeLabel",
    components: {Button},
    props: {
        contentTypeId: Number,
    },
    computed: {
        contentTypeTitle() {
            return this.$store.getters['templates/contentTypes'].find(o => o.id === this.contentTypeId)?.title
        },
        hasEditAccess() {
            return this.$store.getters['user/access']('templates_templates', 'edit')
        },
    },
    created() {
        this.$store.dispatch('templates/init')
    },
    methods: {
        gotToThemesEditor() {
            this.$router.push({name: 'themesEditor', query: {section: 'content_types', id: this.contentTypeId}})
        }
    },
}
</script>

<style scoped>

</style>