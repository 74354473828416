<template>
    <div ref="offcanvas" id="addContentOffcanvas" aria-labelledby="addContentOffcanvasLabel"
         class="offcanvas" :class="{'offcanvas-start':!offcanvasEnd,'offcanvas-end':offcanvasEnd}" tabindex="-1">
        <div class="offcanvas-header">
            <button ref="closeBtn" type="button" class="btn-close" data-bs-dismiss="offcanvas"
                    aria-label="Close"></button>
        </div>

        <div class="offcanvas-body">
            <div class="accordion small border-0" id="accordionExample">
                <div class="accordion-item border-0 rounded overflow-hidden" v-for="(type, key) in types"
                     v-if="getTypes(type).length">
                    <h4 class="accordion-header" :id="type">
                        <button class="accordion-button collapsed p-2" type="button" data-bs-toggle="collapse"
                                :data-bs-target="'#collapse' + key" aria-expanded="false"
                                :aria-controls="'collapse' + key">
                            {{ type }}
                        </button>
                    </h4>

                    <div :id="'collapse' + key" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                        <div class="accordion-body p-0 pb-4">
                            <div class="d-grid gap-1">
                                <Button v-for="contentType in getTypes(type)" :key="contentType.id"
                                        @click.native="addContent(contentType.id)"
                                        class="btn-sm border d-flex align-items-center gap-2">
                                    {{ contentType.title }}
                                </Button>
                            </div>

                            <!--<div class="list-group list-group-flush rounded overflow-hidden">
                                <a v-for="contentType in getTypes(type)" @click.prevent="addContent(contentType.id)"
                                   href="#" class="list-group-item list-group-item-action bg-dark-subtle">
                                    {{ contentType.title }}
                                </a>
                            </div>-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Button from "../../common/Button.vue"

export default {
    name: "PageEditorAddContentOffcanvas",
    components: {Button},
    computed: {
        pageEditor() {
            return this.$store.state.pageEditor
        },
        activePageContentId() {
            return this.pageEditor.activePageContentId
        },
        activeContent() {
            return this.pageEditor.contentItems.find(o => o.pageContentId === this.activePageContentId)
        },
        contentAreaName() {
            return this.pageEditor.activeContentAreaName || this.activeContent?.contentAreaName
        },
        contentTypes() {
            return this.$store.getters['templates/contentTypes']
        },
        types() {
            let types = this.contentTypes
                // The "Form..." CTs are grouped under a generic "Form" type.
                .filter(o => !o.title.toLowerCase().includes('form'))
                .map(o => o.contentType)

            types.push('Forms')

            return [...new Set(types)].sort()
        },
        pageId() {
            return this.pageEditor.pageId
        },
        offcanvasEnd() {
            return this.pageEditor.offcanvasEnd
        }
    },
    asyncComputed: {
        page() {
            return this.$store.dispatch('itemData/get', {
                tableName: 'pages',
                id: this.pageId
            })
        }
    },
    methods: {
        getTypes(type) {
            let contentTypes
            switch (type) {
                case 'Basket':
                    contentTypes = this.contentTypes.filter(o => o.title.toLowerCase().includes('basket'))
                    break
                case 'Forms':
                    contentTypes = this.contentTypes.filter(o => o.title.toLowerCase().includes('form'))
                    break
                case 'Navigation':
                    contentTypes = this.contentTypes.filter(o => o.title.toLowerCase().includes('navigation'))
                    break
                default:
                    contentTypes = this.contentTypes.filter(o =>
                        o.contentType === type
                        && !o.title.toLowerCase().includes('basket')
                        && !o.title.toLowerCase().includes('form')
                        && !o.title.toLowerCase().includes('navigation')
                    )
            }

            if (this.page) {
                // Filter out all listing CTs which target components other than the page's componentId
                if (type === 'Listing') {
                    contentTypes = contentTypes.filter(o => this.page.componentId === o.componentId)
                } else if (type === 'Listing component') {
                    // Only allow listing component CTs on listing pages
                    contentTypes = this.page.type === 'Listing'
                        ? contentTypes.filter(o => this.page.componentId === o.componentId
                            // Listing component CTs can be generic or specific
                            || o.componentId === 0
                        )
                        : []
                }
            }

            return contentTypes
        },
        async addContent(contentTypeId) {
            const o = await this.$store.dispatch('request/post', {
                url: 'api/component/content',
                postData: {contentType: contentTypeId}
            })
            const contentId = o.data.id

            const pageContentId = await this.$store.dispatch('pageEditor/addContentToPage', {
                contentId: contentId,
                contentAreaName: this.contentAreaName
            })

            await this.$router.push({name: 'pageEditor', params: {pageId: this.pageId}, query: {id: pageContentId}})

            this.$refs.offcanvas.querySelector('[data-bs-dismiss="offcanvas"]').click()
        }
    }
}
</script>

<style scoped>
.accordion-button:not(.collapsed) {
    background-color: transparent !important;
    color: inherit;
}

button.accordion-button:focus {
    box-shadow: inherit;
}
</style>