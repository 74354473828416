<template>
    <a v-if="content && !content.isArchived" href="#" @click.prevent="selectContent"
       :class="{ 'active': isSelected, 'selected-secondary': isLastSelected }" :data-content-id="pageContentId"
       class="list-group-item list-group-item-action d-flex align-items-center py-1">

        <div class="flex-fill">
            <span v-if="content.title">{{ content.title }}</span>

            <ContentTypeLabel v-if="showContentTypeButtons && content" :content-type-id="content.contentType" class="ms-1"/>
            <span v-else :class="{'ms-2 text-body-secondary':content.title}">{{ contentTypeTitle }}</span>
        </div>

        <ContentUsageCount v-if="!selectContentMode && content" class="ms-1 small" :page-content-id="pageContentId"/>

        <ItemStatusIcons :table-name="'content'" :id="contentId"/>
    </a>
</template>

<script>
import ContentTypeLabel from '../../common/ContentTypeLabel'
import ContentUsageCount from '../../common/ContentUsageCount'
import Button from "../Button.vue"
import ItemStatusIcons from "../ItemStatusIcons.vue"

export default {
    name: "SiteTreeContentItem",
    components: {ItemStatusIcons, Button, ContentTypeLabel, ContentUsageCount},
    props: {
        pageContentId: Number,
        siteTreeId: String
    },
    computed: {
        siteTree() {
            return this.$store.state[this.siteTreeId]
        },
        selectContentMode() {
            return this.siteTree.selectContentMode
        },
        showContentTypeButtons() {
            return this.siteTree?.showContentTypeButtons
        },
        selectedContentId() {
            return this.siteTree?.selectedContentId
        },
        lastSelectedContentId() {
            return this.siteTree?.lastSelectedContentId
        },
        contentId() {
            return this.$store.state.pageContentData.items.find(o => o.id === this.pageContentId).contentId
        },
        isSelected() {
            return this.selectedContentId === this.pageContentId
        },
        isLastSelected() {
            return (
                this.selectedContentId === 0
                && this.lastSelectedContentId === this.pageContentId
            )
        },
        contentTypeTitle() {
            if (this.content) {
                return this.$store.getters['templates/contentTypes'].find(o => o.id === this.content.contentType)?.title
            }
        }
    },
    asyncComputed: {
        content() {
            return this.$store.dispatch('itemData/get', {
                tableName: 'content',
                id: this.contentId
            })
        }
    },
    methods: {
        selectContent() {
            if (this.siteTree.onContentSelect) {
                this.siteTree.onContentSelect(this.pageContentId)
            }
        }
    }
}
</script>

<style scoped>

</style>