var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "modal-dialog" }, [
    _c("div", { staticClass: "modal-content" }, [
      _vm.options.modalTitle
        ? _c("div", { staticClass: "modal-header" }, [
            _c("h4", { staticClass: "modal-title" }, [
              _vm._v(_vm._s(_vm.options.modalTitle)),
            ]),
            _vm._v(" "),
            _c("button", {
              staticClass: "btn-close",
              attrs: { "aria-label": "Close", type: "button" },
              on: {
                click: function ($event) {
                  return _vm.$emit("hide-modal")
                },
              },
            }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "modal-body" }, [
        _c("div", { domProps: { innerHTML: _vm._s(_vm.options.modalBody) } }),
        _vm._v(" "),
        _vm.confirmStr
          ? _c(
              "form",
              {
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.onSubmit.apply(null, arguments)
                  },
                },
              },
              [
                _c("p", [
                  _vm._v("\n                    Please type "),
                  _c("strong", [_vm._v(_vm._s(_vm.confirmStr))]),
                  _vm._v(" to confirm.\n                "),
                ]),
                _vm._v(" "),
                _c("p", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.input,
                        expression: "input",
                      },
                      { name: "select", rawName: "v-select" },
                    ],
                    staticClass: "form-control",
                    attrs: { autocomplete: "no", type: "text" },
                    domProps: { value: _vm.input },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.input = $event.target.value
                      },
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("p", [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-danger mt-2 w-100",
                      attrs: { disabled: _vm.isDisabled },
                    },
                    [
                      _vm._v(
                        "\n                        I understand the consequences, confirm delete.\n                    "
                      ),
                    ]
                  ),
                ]),
              ]
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      !_vm.confirmStr
        ? _c(
            "div",
            { staticClass: "modal-footer text-end" },
            [
              _c(
                "Button",
                {
                  nativeOn: {
                    click: function ($event) {
                      return _vm.$emit("hide-modal")
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _vm._v(" "),
              _c(
                "Button",
                {
                  ref: "submitBtn",
                  attrs: { "default-class": "btn-warning" },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.onSubmit.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("OK")]
              ),
            ],
            1
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }