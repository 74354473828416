var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "offcanvas",
      staticClass: "offcanvas",
      class: {
        "offcanvas-start": !_vm.offcanvasEnd,
        "offcanvas-end": _vm.offcanvasEnd,
      },
      attrs: {
        id: "addContentOffcanvas",
        "aria-labelledby": "addContentOffcanvasLabel",
        tabindex: "-1",
      },
    },
    [
      _c("div", { staticClass: "offcanvas-header" }, [
        _c("button", {
          ref: "closeBtn",
          staticClass: "btn-close",
          attrs: {
            type: "button",
            "data-bs-dismiss": "offcanvas",
            "aria-label": "Close",
          },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "offcanvas-body" }, [
        _c(
          "div",
          {
            staticClass: "accordion small border-0",
            attrs: { id: "accordionExample" },
          },
          _vm._l(_vm.types, function (type, key) {
            return _vm.getTypes(type).length
              ? _c(
                  "div",
                  {
                    staticClass:
                      "accordion-item border-0 rounded overflow-hidden",
                  },
                  [
                    _c(
                      "h4",
                      { staticClass: "accordion-header", attrs: { id: type } },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "accordion-button collapsed p-2",
                            attrs: {
                              type: "button",
                              "data-bs-toggle": "collapse",
                              "data-bs-target": "#collapse" + key,
                              "aria-expanded": "false",
                              "aria-controls": "collapse" + key,
                            },
                          },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(type) +
                                "\n                    "
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "accordion-collapse collapse",
                        attrs: {
                          id: "collapse" + key,
                          "data-bs-parent": "#accordionExample",
                        },
                      },
                      [
                        _c("div", { staticClass: "accordion-body p-0 pb-4" }, [
                          _c(
                            "div",
                            { staticClass: "d-grid gap-1" },
                            _vm._l(_vm.getTypes(type), function (contentType) {
                              return _c(
                                "Button",
                                {
                                  key: contentType.id,
                                  staticClass:
                                    "btn-sm border d-flex align-items-center gap-2",
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.addContent(contentType.id)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(contentType.title) +
                                      "\n                            "
                                  ),
                                ]
                              )
                            }),
                            1
                          ),
                        ]),
                      ]
                    ),
                  ]
                )
              : _vm._e()
          }),
          0
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }