var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._l(_vm.groupedPageIds, function (pageIds, navigationGroup) {
      return navigationGroup === _vm.selectedNavigationGroup
        ? _c(
            "div",
            {
              key: navigationGroup,
              staticClass: "list-group list-group-flush",
            },
            _vm._l(pageIds, function (pageId) {
              return _c("SiteTreePageItem", {
                key: pageId,
                attrs: { "page-id": pageId, "site-tree-id": _vm.siteTreeId },
              })
            }),
            1
          )
        : _vm._e()
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }