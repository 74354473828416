<template>
    <form ref="form" v-if="show" @submit.prevent="onSubmit" class="input-group d-inline-flex w-auto">
        <input ref="input" v-model="name" list="savedFilterNames" autocomplete="false" placeholder="Name" required
               class="form-control form-control-sm"/>

        <datalist v-if="savedFilterNames" id="savedFilterNames">
            <option v-for="name in savedFilterNames" :value="name"></option>
        </datalist>

        <Button type="submit" class="btn-sm border border-start-0 focus-ring">Save</Button>
    </form>
</template>

<script>
import Button from "../Button.vue"

export default {
    name: "SearchFilterSaveFiltersForm",
    components: {Button},
    props: {
        viewId: String
    },
    data() {
        return {
            name: ''
        }
    },
    computed: {
        filters() {
            return this.$store.state[this.viewId].filters
        },
        show() {
            return Object.values(this.filters).length > 0
        },
        savedFilters() {
            return this.$store.state[this.viewId].savedFilters
        },
        savedFilterNames() {
            return this.savedFilters.map(o => o.name)
        }
    },
    methods: {
        onSubmit() {
            this.$store.dispatch(this.viewId + '/saveFilters', this.name)
            this.name = ''
        }
    }
}
</script>

<style scoped>

</style>