var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Button",
    {
      staticClass: "border",
      attrs: {
        "data-bs-toggle": "offcanvas",
        "data-bs-target": "#contentTypeFieldsetOffcanvas",
        "aria-controls": "contentTypeFieldsetOffcanvas",
        disabled: !_vm.hasFieldsets,
      },
    },
    [_c("i", { staticClass: "bi bi-plus-lg" }), _vm._v("\n    Fieldset\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }