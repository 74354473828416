<template>
    <Button v-if="isEnabled" :disabled="id === 0" class="rounded-0" @click.native="onClick">
        <i class="bi-plus-lg"/>
    </Button>
</template>

<script>
import Button from "../Button.vue"

export default {
    name: "FormAddBtn",
    components: {Button},
    props: {
        formId: String,
    },
    computed: {
        tableName() {
            return this.$store.state[this.formId].tableName
        },
        id() {
            return this.$store.state[this.formId].id
        },
        isSiteTree() {
            return this.$route.name === 'siteTree'
        },
        addAccess() {
            return this.$store.getters['user/access'](this.tableName, 'add')
        },
        isEnabled() {
            if (
                this.tableName === 'product_options'
                || this.tableName === 'product_variables'
                || this.tableName === 'account_settings'
            ) {
                return false
            }

            return this.addAccess
        }
    },
    methods: {
        onClick() {
            this.isSiteTree
                // The + button should always show the Add Page form, whether content or a page is selected.
                ? this.$router.push({name: 'siteTree', params: {pageOrContentId: '0'}}) // 0 needed to be a string!
                : this.$router.push({name: 'form', params: {component: this.tableName, id: 0}})
        }
    }
}
</script>

<style scoped>

</style>