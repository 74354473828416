<template>
    <div ref="offcanvas" id="addExistingContentOffcanvas" aria-labelledby="addExistingContentOffcanvasLabel"
         class="offcanvas" :class="{'offcanvas-start':!offcanvasEnd,'offcanvas-end':offcanvasEnd}" tabindex="-1">
        <div class="offcanvas-header">
            <SiteTreeNavigationMenu :site-tree-id="siteTreeId" class="w-auto"/>
            <button ref="closeBtn" type="button" class="btn-close" data-bs-dismiss="offcanvas"
                    aria-label="Close"></button>
        </div>

        <div class="offcanvas-body">
            <SiteTree :site-tree-id="siteTreeId"/>
        </div>
    </div>
</template>

<script>
import Button from "../../common/Button.vue"
import SiteTreeNavigationMenu from "../../common/site-tree/SiteTreeNavigationMenu.vue"
import SiteTree from "../../common/site-tree/SiteTree.vue"
import siteTree from "../../store/modules/siteTree"

export default {
    name: "PageEditorAddExistingContentOffcanvas",
    components: {SiteTree, SiteTreeNavigationMenu, Button},
    data() {
        return {
            siteTreeId: 'siteTreeOffCanvas'
        }
    },
    computed: {
        pageEditor() {
            return this.$store.state.pageEditor
        },
        offcanvasEnd() {
            return this.pageEditor.offcanvasEnd
        },
        pageId() {
            return this.pageEditor.pageId
        },
        activePageContentId() {
            return this.pageEditor.activePageContentId
        },
        activeContent() {
            return this.pageEditor.contentItems.find(o => o.pageContentId === this.activePageContentId)
        },
        contentAreaName() {
            return this.pageEditor.activeContentAreaName || this.activeContent?.contentAreaName
        },
    },
    created() {
        this.$store.registerModule(this.siteTreeId, siteTree)
        this.$store.dispatch(this.siteTreeId + '/init', {
            selectContentMode: true,
            onContentSelect: async pageContentId => {
                const contentId = this.$store.state.pageContentData.items.find(o => o.id === pageContentId)?.contentId

                const content = await this.$store.dispatch('itemData/get', {
                    tableName: 'content',
                    id: contentId
                })

                const contentType = await this.$store.dispatch('itemData/get', {
                    tableName: 'templates_templates',
                    id: content.contentType
                })

                const type = contentType.contentType
                const componentId = contentType.componentId
                if (type === 'Listing' || type === 'Listing component') {
                    const page = await this.$store.dispatch('itemData/get', {
                        tableName: 'pages',
                        id: this.pageId
                    })

                    let error = ''
                    if (page.type !== 'Listing') {
                        error = 'Only listing pages can support this content.'
                    } else if (type === 'Listing' && componentId !== page.componentId) {
                        error = 'This listing page does not support this content.'
                    } else if (type === 'Listing component' && componentId > 0 && componentId !== page.componentId) {
                        error = 'This listing page does not support this content.'
                    }
                    if (error) {
                        await this.$store.dispatch('toasts/add', {body: error, colourScheme: 'warning'})
                        return
                    }
                }

                const newPageContentId = await this.$store.dispatch('pageEditor/addContentToPage', {
                    contentId: contentId,
                    contentAreaName: this.contentAreaName
                })

                await this.$router.push({name: 'pageEditor', params: {pageId: this.pageId}, query: {id: newPageContentId}})

                this.$refs.offcanvas.querySelector('[data-bs-dismiss="offcanvas"]').click()
            }
        })
    }
}
</script>

<style scoped>

</style>