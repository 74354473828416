<template>
    <select v-model="selectedNavigationGroup" class="form-select form-select-sm w-auto">
        <option v-for="option in navigationGroups" :key="option" :value="option">
            {{ option }}
        </option>
    </select>
</template>

<script>
export default {
    name: "SiteTreeNavigationMenu",
    props: {
        siteTreeId: String
    },
    computed: {
        navigationGroups() {
            return this.$store.state[this.siteTreeId]?.navigationGroups
        },
        selectedNavigationGroup: {
            get() {
                return this.$store.state[this.siteTreeId]?.selectedNavigationGroup
            },
            set(val) {
                this.$store.commit(this.siteTreeId + '/selectedNavigationGroup', val)
            }
        }
    },
}
</script>

<style scoped>

</style>