var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "select",
    {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.formData.reportName,
          expression: "formData.reportName",
        },
      ],
      staticClass: "form-select form-select-sm",
      attrs: { name: "reportName" },
      on: {
        change: function ($event) {
          var $$selectedVal = Array.prototype.filter
            .call($event.target.options, function (o) {
              return o.selected
            })
            .map(function (o) {
              var val = "_value" in o ? o._value : o.value
              return val
            })
          _vm.$set(
            _vm.formData,
            "reportName",
            $event.target.multiple ? $$selectedVal : $$selectedVal[0]
          )
        },
      },
    },
    [
      _c("option", { attrs: { value: "", selected: "", disabled: "" } }, [
        _vm._v("Select a report…"),
      ]),
      _vm._v(" "),
      _vm._l(_vm.reports, function (report) {
        return _c("option", { domProps: { value: report.name } }, [
          _vm._v(_vm._s(report.label)),
        ])
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }