var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isSet
    ? _c(
        "Button",
        {
          staticClass: "btn-sm focus-ring",
          nativeOn: {
            click: function ($event) {
              return _vm.onClick.apply(null, arguments)
            },
          },
        },
        [_c("i", { staticClass: "bi-x-lg" })]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }