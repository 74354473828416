<template>
    <FormControlPlainText v-if="field.readonly" :value="currentData[field.name]" :form-id="formId"/>
    <span v-else class="d-flex-inline align-items-center">
        <input ref="input" :id="field.id" v-model="currentData[field.name]" v-select="field.autofocus" v-form-validation
               :disabled="field.disabled" :max="field.max" :min="field.min" :name="field.name"
               :required="field.required" :step="field.step" autocomplete="off" class="form-control w-auto"
               :class="{'is-invalid': field.error, 'form-control-sm': formSmall}" type="datetime-local">
        <button v-if="value" @click="clearValue" type="button" class="btn btn-link">clear</button>
    </span>
</template>

<script>
import uniqueValueRequired from '../../vue/mixins/unique-value-required'
import formValidation from '../../vue/directives/formValidation'
import FormControlPlainText from "../form-control/FormControlPlainText.vue"
import moment from "moment/moment"

export default {
    name: "FormControlTypeDatetimeLocal",
    components: {FormControlPlainText},
    mixins: [uniqueValueRequired],
    directives: {
        formValidation,
    },
    props: {
        formId: String,
        field: Object,
        currentData: Object,
        value: String
    },
    computed: {
        formSmall() {
            return this.$store.state[this.formId].formSmall
        }
    },
    created() {
        /*
        Disabled because it was preventing the field from populating with saved dates in both Safari and Chrome.
        let value = this.currentData[this.field.name]
        if (value) {
            // Safari fix. Safari will output "Invalid Date" if the string contains hyphens
            value = value.replace(/-/g, '/')

            let date = new Date(value)
            this.$store.commit(this.formId + '/presetData', {
                name: this.field.name,
                value: date,
            })
        } else {
            this.$store.dispatch(this.formId + '/setDefaultValue', {
                name: this.field.name,
                value: ''
            })
        }
        */
    },
    watch: {
        value(newValue) {
            // Fix for validation issues caused by the inconsistency between HTML's datetime-local format
            // (e.g. "2028-06-13T11:34") and MySQL's DATETIME (e.g. "2028-06-13 11:34:00")
            if (newValue.includes('T')) {
                this.currentData[this.field.name] = moment(newValue).format('YYYY-MM-DD HH:mm:ss')
            }
        }
    },
    methods: {
        clearValue() {
            this.currentData[this.field.name] = null
            // Safari bug fix. After unsetting the value using the above, Safari still invalidates the form.
            // Setting the value a second time addresses this.
            // https://stackoverflow.com/questions/77537783/safari-datetime-local-form-validity-bug-after-unsetting-value
            this.$nextTick(() => {
                this.$refs.input.value = ''
            })
        }
    }
}
</script>

<style scoped>

</style>