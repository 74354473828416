<template>
    <div class="list-group list-group-flush">
        <a data-bs-toggle="offcanvas" role="button" data-bs-target="#historyOffcanvas" v-if="currentData.modifiedDate"
             class="list-group-item list-group-item-action border-bottom-0 d-flex align-items-start px-2 py-1 small">
            <UserAvatar v-if="currentData.modifiedBy" :user-id="currentData.modifiedBy" :is-small="false"
                        :tooltip-placement="'right'" class="mt-1 me-2"/>

            <small>
                <UserName v-if="currentData.modifiedBy" :id="currentData.modifiedBy"
                          :no-user-found-str="'<em>user not found</em>'"/>
                <br>
                {{ getDate(currentData.modifiedDate * 1000) }}
            </small>
        </a>

        <FormHistoryOffcanvas :form-id="formId"/>
    </div>
</template>

<script>
import UserAvatar from "../../common/UserAvatar.vue"
import UserName from "../../common/UserName.vue"
import moment from "moment/moment"
import FormHistoryOffcanvas from "../../common/form/FormHistoryOffcanvas.vue"

export default {
    name: "PageEditorModified",
    components: {FormHistoryOffcanvas, UserName, UserAvatar},
    props: {
        formId: String
    },
    computed: {
        currentData() {
            return this.$store.state[this.formId].currentData
        }
    },
    methods: {
        getDate(timestamp) {
            return moment(timestamp).format('DD MMM YYYY @ HH:mm')
        }
    }
}
</script>

<style scoped>

</style>