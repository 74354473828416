<template>
    <select required v-model="formData.month" class="form-select form-select-sm" name="month"
            oninvalid="this.setCustomValidity('Please select a month')" oninput="this.setCustomValidity('')">
        <option value="">Month&hellip;</option>
        <option
            v-for="(month, index) in ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']"
            :value="index+1">
            {{ month }}
        </option>
    </select>
</template>

<script>
export default {
    name: "MonthsFilter",
    computed: {
        formData() {
            return this.$store.state.odpReports.formData
        }
    },
    created() {
        const month = this.$route.query.month || ''
        this.$set(this.formData, 'month', month)
    },
    destroyed() {
        this.$delete(this.formData, 'month');
    }
}
</script>

<style scoped>

</style>