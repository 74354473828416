<template>
    <div class="d-flex align-items-center">
        <Button class="me-2 border" @click.native="internalLink">Browse…</Button>

        <p v-if="pageId || contentId" class="well m-0 p-1 text-nowrap">
            <PagePath :page-id="pageId" :page-content-id="contentId"/>
            <Button v-if="!removeDisabledBtn" class="btn-sm" @click.native="onChange(0)"><i class="bi-x-lg"/></Button>
        </p>
    </div>
</template>

<script>
import FormControlTypeText from './form-control-type/FormControlTypeText'
import PagePath from '../common/PagePath'
import Button from "./Button.vue"

export default {
    name: "InternalLinkBtn",
    components: {Button, FormControlTypeText, PagePath},
    props: {
        formId: String,
        field: Object,
        id: [String, Number],
        selectContentMode: Boolean,
        onChange: Function,
        removeDisabledBtn: Boolean
    },
    computed: {
        currentData() {
            return this.$store.state[this.formId].currentData
        },
        pageId() {
            this.cleanId()

            return this.selectContentMode ? null : parseInt(this.id)
        },
        contentId() {
            this.cleanId()

            if (this.selectContentMode) {
                const idStr = '' + this.id
                let id = idStr.indexOf('c') === 0 ? this.id.substr(1) : this.id
                return parseInt(id)
            }

            return null
        },
        tableName() {
            return this.$store.state[this.formId].tableName
        }
    },
    methods: {
        cleanId() {
            if (this.id) {
                let isString = typeof this.id === 'string'
                let isObject = typeof this.id === 'object'

                // todo - This is support for the miscellaneous ways in which the internal
                // link data is saved. The internal link button should now always save
                // as an object, or an ID (to be confirmed).
                // Converts {"internal":"1"} to object
                if (
                    isString
                    && this.id.indexOf('{') > -1
                ) {
                    this.id = JSON.parse(this.id)
                }

                // Converts {internal:x} to x
                if (isObject) {
                    this.id = this.id.internal
                }
            }
        },
        internalLink() {
            this.$store.dispatch('modals/show', {
                componentName: 'SiteTreeModal',
                obj: {
                    selectContentMode: this.selectContentMode,
                    pageId: this.pageId,
                    pageContentLinkId: this.contentId,
                    onPageSelect: this.onPageSelect,
                    onContentSelect: this.onChange,
                }
            })
        },
        onPageSelect(pageId) {
            // The Pages component's Parent control
            if (this.tableName === 'pages' && this.field.name === 'parent') {
                if (pageId === this.currentData.id) {
                    this.$store.dispatch('toasts/add', {
                        body: 'A page cannot be the parent of itself.',
                        colourScheme: 'warning'
                    })
                    return false
                }

                const selectedPageId = pageId

                // todo This should somehow return false if it fails, so that the modal doesn't close.
                //      I wasn't sure how to do this with a recursive function. Perhaps there's a way to apply
                //      async/await to recursive functions!
                // A category cannot be the child of its own descendant.
                // Recursively check the targetPageId's ancestors to make sure the this.currentData.id isn't found.
                const checkAncestors = (formPageId, pageId) => {
                    this.$store.dispatch('itemData/get', {
                            tableName: 'pages',
                            id: pageId
                        })
                        .then(o => {
                            // If the targeted page has a parent then ensure that it's not the page being edited.
                            if (o.parent === formPageId) {
                                this.$store.dispatch('toasts/add', {
                                    body: 'A page cannot be the child of its descendant.',
                                    colourScheme: 'warning'
                                })
                                // If not then we need to check the parent's parent, and so forth.
                            } else if (o.parent) {
                                checkAncestors(formPageId, o.parent)
                            } else {
                                this.onChange(selectedPageId)
                                return true
                            }
                        })
                }

                checkAncestors(this.currentData.id, pageId)
                return true
            }

            this.onChange(pageId)
        }
    }
}
</script>

<style scoped>

</style>