<template>
    <FormControlTypeSelect v-if="options" :current-data="currentData" :field="field" :form-id="formId"
                           :options="options" :required="field.required"/>
</template>

<script>
import FormControlTypeSelect from '../form-control-type/FormControlTypeSelect'

export default {
    name: "FormControlModulestructureCategoryComponentId",
    components: {
        FormControlTypeSelect
    },
    props: {
        formId: String,
        currentData: Object,
        field: Object
    },
    computed: {
        options() {
            const options = []

            let items = this.$store.state.components.items

            if (
                this.$route.name === 'componentFields'
                || (
                    this.$route.name === 'themesEditor'
                    && this.$route.query.section === 'content_types'
                )
            ) {
                items = items.filter(
                    o => o.listing
                        || o.categoryList
                        // This has been added so custom components, e.g. ODP's Party bookings, can target customers
                        || o.tableName === 'm_registrants_registrants'
                )
            }

            items.forEach(o => {
                options.push({
                    value: o.id,
                    text: o.title
                })
            })

            return options.sort((a, b) => (a.text > b.text) ? 1 : -1)
        }
    },
    created() {
        if (
            this.currentData.type !== 'relationshipOneToMany'
            && this.currentData.type !== 'relationshipManyToMany'
        ) {
            this.field.hidden = true
        }
    }
}
</script>

<style scoped>

</style>