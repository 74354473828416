<template>
    <select @change="setPropagatedContent" @click.stop.prevent class="form-select form-select-sm w-auto">

        <!-- By default, the first content area is assigned to a page when it's selected. This avoids the need to
             validate the menus, which would add a lot of complexity to the propagation feature. -->
        <option selected disabled>Select&hellip;</option>-->
        <option v-for="contentArea in pageTemplatesContentAreas" :selected="contentArea === selectedContentArea">
            {{ contentArea }}
        </option>
    </select>
</template>

<script>
export default {
    name: "SiteTreePageItemSelectMenu",
    props: {
        siteTreeId: String,
        pageId: Number
    },
    computed: {
        siteTree() {
            return this.$store.state[this.siteTreeId]
        },
        pageTemplatesContentAreas() {
            if (this.page) {
                return this.$store.state.pageTemplatesContentAreas.pageTemplatesContentAreas[this.page.templateId]
            }
        },
        selectedContentArea() {
            return this.siteTree.propagationData.find(o => o.pageId === this.pageId)?.contentArea
        }
    },
    asyncComputed: {
        page() {
            return this.$store.dispatch('itemData/get', {
                tableName: 'pages',
                id: this.pageId
            })
        }
    },
    methods: {
        setPropagatedContent(e) {
            const contentArea = e.target.value
            this.$store.commit(this.siteTreeId + '/propagationData', {pageId: this.pageId, contentArea})
        }
    }
}
</script>

<style scoped>

</style>