<template>
    <div ref="overlay" v-show="show">
        <a @wheel="hideIframesOnWheel" @click.prevent="edit" role="button" href
           class="d-block w-100 h-100 d-flex align-items-center justify-content-center h1 text-body-tertiary text-decoration-none">
            <!-- Area for presenting buttons -->
        </a>
    </div>
</template>

<script>
export default {
    name: "PageEditorContentAreaOverlay",
    computed: {
        pageEditor() {
            return this.$store.state.pageEditor
        },
        pageId() {
            return this.pageEditor.pageId
        },
        contentAreaName() {
            return this.pageEditor.hoverContentAreaName || this.pageEditor.activeContentAreaName
        },
        activeContentArea() {
            return this.pageEditor.contentAreas.find(o => o.name === this.contentAreaName)
        },
        show() {
            return !!this.contentAreaName
        }
    },
    mounted() {
        this.activeContentArea.iframe.contentDocument.body.append(this.$refs.overlay)
    },
    methods: {
        hideIframesOnWheel() {
            this.$store.dispatch('pageEditor/hideIframesOnWheel')
        },
        edit() {
            if (this.$route.query.area !== this.contentAreaName) {
                this.$router.push({
                    name: 'pageEditor',
                    params: {pageId: this.pageId},
                    query: {area: this.contentAreaName}
                })
            }

            this.$store.commit('pageEditor/leftSidebarActiveTab', 'Content')
        }
    }
}
</script>

<style scoped>

</style>