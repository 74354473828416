var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isLoading
    ? _c("div", { staticClass: "text-center" }, [_vm._m(0)])
    : _vm.noItemHistory
    ? _c("div", { staticClass: "text-center" }, [_vm._v("No history")])
    : _c("div", [
        _c(
          "div",
          { staticClass: "list-group list-group-flush" },
          _vm._l(_vm.items, function (item) {
            return _c("div", { staticClass: "list-group-item d-flex p-0" }, [
              _c(
                "div",
                { staticClass: "m-2 mb-0" },
                [
                  _c("UserAvatar", {
                    attrs: {
                      "user-id": item.createdBy,
                      "tooltip-placement": "right",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex-fill list-group list-group-flush m-0" },
                _vm._l(item.data.old, function (oldValue, property) {
                  return _c("ItemHistoryChange", {
                    key: property,
                    staticClass: "list-group-item px-0 visible-on-hover",
                    staticStyle: { "border-style": "dashed" },
                    attrs: {
                      "form-id": _vm.formId,
                      property: property,
                      "table-name": _vm.tableName,
                      id: _vm.id,
                      value: oldValue,
                      "new-value": item.data?.new?.[property],
                      "old-value": oldValue,
                    },
                  })
                }),
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "m-2 mb-0 mt-1 text-nowrap small text-end code",
                  attrs: { title: _vm.getDate(item.createdDate) },
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.getDate(item.createdDate)) +
                      "\n                "
                  ),
                  _c("br"),
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.getTime(item.createdDate)) +
                      "\n            "
                  ),
                ]
              ),
            ])
          }),
          0
        ),
        _vm._v(" "),
        _c("div", { staticClass: "mt-3 text-center" }, [
          !_vm.loadingMore
            ? _c("div", [
                _vm.isShowingAll
                  ? _c("p", { staticClass: "small fst-italic" }, [
                      _vm._v("Showing all"),
                    ])
                  : _vm.total
                  ? _c("p", [
                      _vm._v(
                        "Showing 1 to " +
                          _vm._s(_vm.start + _vm.limit) +
                          " of " +
                          _vm._s(_vm.total)
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !_vm.isShowingAll
                  ? _c(
                      "div",
                      [
                        _c(
                          "Button",
                          {
                            staticClass: "border",
                            nativeOn: {
                              click: function ($event) {
                                return _vm.viewMore.apply(null, arguments)
                              },
                            },
                          },
                          [_vm._v("View " + _vm._s(_vm.limit) + " more")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ])
            : _c(
                "div",
                { staticClass: "spinner-border", attrs: { role: "status" } },
                [
                  _c("span", { staticClass: "visually-hidden" }, [
                    _vm._v("Loading…"),
                  ]),
                ]
              ),
        ]),
      ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "spinner-border", attrs: { role: "status" } },
      [_c("span", { staticClass: "visually-hidden" }, [_vm._v("Loading…")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }