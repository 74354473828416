<template>
    <span>
        <Button @click.native="openSiteTreeModal" class="btn-sm border me-2">Browse…</Button>
        <span v-if="pageId" class="well well-sm m-0 p-1 text-nowrap">
            <PagePath :page-id="pageId"/>
        </span>
    </span>
</template>

<script>
import PagePath from '../../common/PagePath'
import Button from "../Button.vue"

export default {
    name: "SearchFilterFieldInternalLink",
    components: {Button, PagePath},
    props: {
        viewId: String,
        filterKey: Number
    },
    computed: {
        filter() {
            return this.$store.state[this.viewId].filters[this.filterKey]
        },
        pageId() {
            return parseInt(this.filter.value) || 0
        }
    },
    methods: {
        openSiteTreeModal() {
            this.$store.dispatch('modals/show', {
                componentName: 'SiteTreeModal',
                obj: {
                    pageId: this.pageId,
                    onPageSelect: pageId => {
                        this.$store.dispatch(this.viewId + '/setFilterProp', {
                            key: this.filterKey,
                            name: 'value',
                            value: pageId
                        })
                    }
                }
            })
        }
    }
}
</script>

<style scoped>

</style>