<template>
    <div class="border-bottom">
        <div class="text-center p-3">
            <SiteTreeNavigationMenu :site-tree-id="siteTreeId"/>
        </div>

        <SiteTree :site-tree-id="siteTreeId"/>

        <!--<div class="text-end">
            <PageEditorSiteTreePinBtn/>
        </div>-->
    </div>
</template>

<script>
import SiteTree from "../../common/site-tree/SiteTree.vue"
import PageEditorSiteTreePinBtn from "./PageEditorSiteTreePinBtn.vue"
import SiteTreeNavigationMenu from "../../common/site-tree/SiteTreeNavigationMenu.vue"

export default {
    name: "PageEditorSiteTree",
    components: {
        SiteTreeNavigationMenu,
        PageEditorSiteTreePinBtn,
        SiteTree
    },
    computed: {
        pageEditor() {
            return this.$store.state.pageEditor
        },
        siteTreeId() {
            return this.pageEditor.siteTreeId
        }
    }
}
</script>

<style scoped>

</style>