import axios from 'axios'
import yaml from 'js-yaml'

import Vue from 'vue'
import Vuex from 'vuex'
import accessGroups from './modules/accessGroups'
import advancedFilters from './modules/advancedFilters'
import assumeUser from './modules/assumeUser'
import categories from './modules/categories'
import collectionFilters from './modules/collectionFilters'
import componentFields from './modules/componentFields'
import componentRoot from './modules/componentRoot'
import components from './modules/components'
import componentStructure from './modules/componentStructure'
import displayOrders from './modules/displayOrders'
import documentTitle from './modules/documentTitle'
import emailEvents from './modules/emailEvents'
import fieldsets from './modules/fieldsets'
import form from './modules/form'
import forms from './modules/forms'
import itemData from './modules/itemData'
import itemDataNew from './modules/itemDataNew'
import lightbox from './modules/lightbox'
import modals from './modules/modals'
import modifiedItems from './modules/modifiedItems'
import navigation from './modules/navigation'
import notificationsMenu from './modules/notificationsMenu'
import odpReports from './modules/odpReports'
import pageContentData from './modules/pageContentData'
import pageContentLayouts from './modules/pageContentLayouts'
import pageEditor from './modules/pageEditor'
import pagePath from './modules/pagePath'
import pageTemplatesContentAreas from './modules/pageTemplatesContentAreas'
import preloadViewItemData from './modules/preloadViewItemData'
import printGiftsReports from './modules/printGiftsReports'
import productVariations from './modules/productVariations'
import request from './modules/request'
import siteTree from './modules/siteTree'
import stateHistory from './modules/stateHistory'
import templates from './modules/templates'
import themes from './modules/themes'
import toasts from './modules/toasts'
import user from './modules/user'
import userRoles from './modules/userRoles'
import userTracking from './modules/userTracking'
import view from './modules/view'
import wizard from './modules/wizard'
import loadView from './plugins/loadView'

Vue.use(Vuex)

export default new Vuex.Store({
    // In strict mode, whenever Vuex state is mutated outside of mutation handlers, an error will be thrown.
    // This ensures that all state mutations can be explicitly tracked by debugging tools.
    // todo This currently generates loads of errors so cannot be introduced.
    //strict: process.env.NODE_ENV !== 'production',
    
    state: {
        _beta_content_types: false,
        alphaMode: 0,
        apiEndpoint: 'https://127.0.0.1:8000',
        websiteEndpoint: '',
        ccmsEndpoint: '',
        ccmsSchemeAndHost: '',
        betaMode: 0,
        cacheNeedsClearing: false,
        isLocal: !!window.location.port,
        env: 'gae',
        importServiceEndpoint: 'https://import-service-dot-creativecms-london.appspot.com',
        importServiceToken: 'ccms:zn:zh&27r+Aq/:;C66T6k"6SGpHBb@',
        mainViewId: false,
        navigationComponent: false,
        showSupport: false,
        settings: {},
        sitename: window.location.host.split('.')[0],
        sidebarToggle: {},
        activeFieldsets: {},
        pageEditorMode: false
    },
    mutations: {
        cacheNeedsClearing(state) {
            state.cacheNeedsClearing = true
        },
        mainViewId(state, string) {
            state.mainViewId = string
        },
        navigationComponent(state, string) {
            state.navigationComponent = string
        },
        showSupport(state, bool) {
            state.showSupport = bool
        },
        setEndpoints(state) {
            
            // todo - Could this be simplified to !!localStorage.getItem('betaMode') ?
            let betaMode = localStorage.getItem('betaMode')
            if (betaMode) {
                state.betaMode = parseInt(betaMode)
            }
            
            let alphaMode = localStorage.getItem('alphaMode')
            if (alphaMode) {
                state.alphaMode = parseInt(alphaMode)
            }
            
            state.pageEditorMode = localStorage.getItem('pageEditorMode') === '1'
            
            // A port number exists when testing locally, so this is the flag to determine debug mode.
            if (state.isLocal) {
                // Comment out to use REMOTE endpoints when testing locally.
                state.env = 'debug'
            }
            
            switch (state.env) {
                case 'debug':
                    state.importServiceEndpoint = 'https://127.0.0.1:8002'
                    //state.importServiceEndpoint = 'https://import-service-staging-dot-creativecms-london.appspot.com';
                    break
                case 'gae':
                    state.apiEndpoint = 'https://api-dot-creativecms-london.appspot.com'
                    //state.apiEndpoint = 'https://api-staging-dot-creativecms-london.appspot.com'
                    break
            }
        }
    },
    getters: {
        cacheLastCleared() {
            let time = localStorage.getItem('cacheLastCleared')
            if (time) {
                return parseInt(time)
            }
            
            let now = Math.ceil(Date.now() / 1000)
            localStorage.setItem('cacheLastCleared', now)
        },
        bucketName(state) {
            return 'creativecms-london--' + state.sitename
        },
        storageUrl(state, getters) {
            return 'https://storage.googleapis.com/' + getters.bucketName
        },
        cdnUrl(state, getters) {
            return 'https://agptxipylp.cloudimg.io/v7/storage.googleapis.com/' + getters.bucketName
        },
        fileSrc: (state, getters) => (directoryName, fileName) => {
            return getters.storageUrl + '/' + directoryName + '/' + fileName
        },
        cdnSrc: (state, getters) => (directoryName, fileName) => {
            return getters.cdnUrl + '/' + directoryName + '/' + fileName
        },
        imageWidthSrc: (state, getters) => (directoryName, fileName, imgWidth, modifiedDate, thumbnailWidth) => {
            let src = getters.cdnSrc(directoryName, fileName)
            
            if (state.sitename === 'foo-bar') {
                src += '?v' + modifiedDate
                
                if (thumbnailWidth) {
                    src += '&width=' + thumbnailWidth
                }
                
            } else {
                if (thumbnailWidth) {
                    src += '?width=' + thumbnailWidth
                }
            }
            
            return src
        }
    },
    actions: {
        clearCache({state, dispatch}, {notificationId}) {
            return dispatch('request/get', {
                url: 'api/cache-warmer',
                params: {
                    notificationId: notificationId
                }
            }, {root: true})
                .catch(() => {
                    // Suppresses a console error.
                })
                .then((response) => {
                    state.cacheNeedsClearing = false
                    
                    let now = Math.ceil(Date.now() / 1000)
                    localStorage.setItem('cacheLastCleared', now)
                    
                    dispatch('notificationsMenu/setItems', {}, {root: true})
                    
                    return response
                })
        },
        setParams({state, commit, dispatch}) {
            
            commit('setEndpoints')
            
            if (
                [
                    'nick'
                ]
                    .indexOf(state.sitename) > -1
            ) {
                state._beta_content_types = true
            }
            
            return new Promise((resolve, reject) => {
                axios({
                    method: 'get',
                    url: state.apiEndpoint + '/api/check-build',
                    headers: {
                        'Content-Type': 'application/json',
                        'Db-Name': state.sitename
                    }
                })
                    .catch((error) => {
                        // The API will return a 404 if the targeted database does not exist.
                        if (error.response.status === 404) {
                            window.location.href = 'https://www.creativecms.io'
                        }
                        
                        reject()
                    })
                    .then((obj) => {
                        if (obj) {
                            const localSchemeAndHost = 'https://' + state.sitename + '.wip'
                            
                            state.ccmsEndpoint = state.isLocal
                                ? 'http://' + state.sitename + '.ccms.io:8081/'
                                : 'https://' + state.sitename + '.ccms.io/'
                            
                            state.websiteEndpoint = state.isLocal
                                ? localSchemeAndHost
                                : obj.data.url
                            
                            state.ccmsSchemeAndHost = state.isLocal
                                ? localSchemeAndHost
                                : 'https://' + state.sitename + '.creativecms.io'
                        }
                        
                        if (localStorage.getItem('token')) {
                            dispatch('setSettings')
                                .then(() => {
                                    return dispatch('fieldsets/init')
                                })
                                .then(() => {
                                    return dispatch('componentStructure/init')
                                })
                                .then(() => {
                                    return dispatch('components/init')
                                })
                                .then(() => {
                                    return dispatch('categories/init')
                                })
                                .then(() => {
                                    return dispatch('accessGroups/init')
                                })
                                .then(() => {
                                    return dispatch('pageContentData/init')
                                })
                                .then(() => {
                                    return dispatch('templates/init')
                                })
                                .then(() => {
                                    resolve()
                                })
                        }
                    })
            })
        },
        setSettings({state, dispatch}) {
            // Store account settings in root state.
            return dispatch('itemData/get', {
                tableName: 'account_settings',
                id: 1
            }, {root: true})
                .then((obj) => {
                    const clone = JSON.parse(JSON.stringify(obj))
                    clone.config = clone.config ? yaml.load(clone.config) : {}
                    state.settings = clone
                })
        },
    },
    modules: {
        accessGroups,
        advancedFilters,
        assumeUser,
        categories,
        collectionFilters,
        componentFields,
        componentRoot,
        components,
        componentStructure,
        displayOrders,
        documentTitle,
        emailEvents,
        fieldsets,
        forms,
        itemData,
        itemDataNew,
        lightbox,
        modals,
        modifiedItems,
        navigation,
        notificationsMenu,
        odpReports,
        pageContentData,
        pageContentLayouts,
        pageEditor,
        pageEditorForm: form,
        'view:pageEditorSelectListModal': view,
        pagePath,
        pageTemplatesContentAreas,
        preloadViewItemData,
        printGiftsReports,
        productVariations,
        request,
        siteTree,
        stateHistory,
        templates,
        themes,
        toasts,
        user,
        userRoles,
        userTracking,
        wizard,
    },
    plugins: [loadView],
    //strict: debug,
    //plugins: debug ? [createLogger()] : []
})