<template>
    <button type="button" class="btn" :class="darkModeBtnClass">
        <slot/>
    </button>
</template>

<script>
import {defineComponent} from 'vue'

export default defineComponent({
    name: "Button",
    props: {
        defaultClass: String,
        activeClass: String,
        activeValue: Boolean
    },
    computed: {
        darkModeBtnClass() {
            const darkModeClass = this.$store.getters['user/pref']('darkMode') ? 'btn-dark' : 'btn-light btn-white'

            return this.activeValue
                ? [
                    this.activeClass === undefined // Allows an empty string to disable btn-warning
                        ? 'btn-warning' : this.activeClass
                ]
                : [this.defaultClass || darkModeClass]
        }
    }
})
</script>

<style scoped>
.btn-white:not(:hover) {
    background-color: #fff;
    border-color: #fff;
}
/*
    In light mode, buttons have a light grey background.
    The following removes it, but :not is required to allow light text colours
    for background colours.
*/
[data-bs-theme="light"] button:not(:hover):not(.btn-primary, .btn-secondary, .btn-warning, .btn-light, .btn-dark) {
    background-color: transparent;
    border-color: transparent;
}

/* When a light button is inside a light button apply a lighter bg color
   when hovering on the inner button to add contrast between them.
 */
[data-bs-theme="light"] .btn .btn:hover {
    background-color: #fff !important;
}
[data-bs-theme="dark"] .btn .btn:hover {
    background-color: #333 !important;
}
</style>