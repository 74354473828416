<template>
    <div v-if="count" class="dropdown">
        <Button class="btn-sm border" title="Usage count" data-bs-toggle="dropdown">
            {{ count }}
        </Button>

        <div class="dropdown-menu dropdown-menu-end p-1" @click.stop>
            <Button class="btn-sm w-100 text-start" @click.native="unlinkContent">
                Unlink content
            </Button>
        </div>
    </div>
</template>

<script>
import Button from "./Button.vue"

export default {
    name: "ContentUsageCount",
    components: {Button},
    props: {
        pageContentId: Number
    },
    computed: {
        pageContentLinkItem() {
            return this.$store.state.pageContentData.items.find(o => o.id === this.pageContentId)
        },
        contentItems() {
            if (this.pageContentLinkItem) {
                return this.$store.state.pageContentData.items.filter(o => o.contentId === this.pageContentLinkItem.contentId)
            }
        },
        count() {
            if (this.contentItems) {
                const count = this.contentItems.length
                return count > 1 ? count : 0
            }
        }
    },
    methods: {
        unlinkContent: function() {
            this.$store.dispatch('request/post', {
                    url: 'api/unlink-content',
                    postData: {
                        pageContentId: this.pageContentId
                    }
                })
                .then(() => {
                    // Refresh the page content IDs so that the pageContentId's new contentId is loaded.
                    return this.$store.dispatch('pageContentData/init')
                })
        }
    }
}
</script>

<style scoped>

</style>