<script>
import FormControlTypeCheckbox from "../form-control-type/FormControlTypeCheckbox.vue"

export default {
    name: "FormControlPagesDisplayInSitemap",
    extends: FormControlTypeCheckbox,
    props: {
        formId: String,
        currentData: Object,
        field: Object
    },
    created() {
        this.field.hidden = this.currentData.type !== 'Listing'
    }
}
</script>

<style scoped>

</style>