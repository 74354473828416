<template>
    <div ref="offcanvas" class="offcanvas offcanvas-start w-50" data-bs-scroll="true" data-bs-backdrop="false"
         tabindex="-1" :id="offCanvasId" :aria-labelledby="offCanvasId + 'Label'">
        <div class="offcanvas-header">
            <h5 class="offcanvas-title" :id="offCanvasId + 'Label'">Filters</h5>
            <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>

        <div class="offcanvas-body">
            <SearchFilterSavedFiltersMenu :view-id="viewId"/>

            <SearchFilters :view-id="viewId"/>

            <div class="pt-3 d-flex align-items-center">
                <div class="flex-fill">
                    <Button @click.native="addFilter" class="btn-sm border focus-ring">
                        <i class="bi bi-plus-lg"/> filter
                    </Button>
                </div>
                <SearchFilterSaveFiltersForm :view-id="viewId"/>
            </div>
        </div>
    </div>
</template>

<script>
import SearchFilters from "./SearchFilters"
import SearchFilterSavedFiltersMenu from "./SearchFilterSavedFiltersMenu"
import SearchFilterSaveFiltersForm from "./SearchFilterSaveFiltersForm"
import Button from "../Button.vue"

export default {
    name: "SearchFilterToolbarFilterOffcanvas",
    components: {Button, SearchFilterSaveFiltersForm, SearchFilterSavedFiltersMenu, SearchFilters},
    props: {
        viewId: String,
        offCanvasId: String
    },
    mounted() {
        // An issue with Bootstrap's offcanvas is that, when open, it disables all elements outside the offcanvas.
        // To fix this, I've had to use data-bs-scroll="true" data-bs-backdrop="false" and then manually recreate
        // the backdrop.
        const myOffcanvas = document.getElementById(this.offCanvasId)

        const div = document.createElement('div')
        div.classList.add('offcanvas-backdrop', 'fade', 'show', 'd-none')
        div.addEventListener('click', () => {
            // I tried using Offcanvas(this.$refs.offcanvas).hide() but it failed
            this.$refs.offcanvas.querySelector('[data-bs-dismiss="offcanvas"]').click()
        })
        this.$refs.offcanvas?.after(div)

        myOffcanvas.addEventListener('show.bs.offcanvas', event => {
            div.classList.remove('d-none')
        })

        myOffcanvas.addEventListener('hide.bs.offcanvas', event => {
            div.classList.add('d-none')
        })
    },
    methods: {
        addFilter() {
            this.$store.dispatch(this.viewId + '/addFilter')
        }
    }
}
</script>

<style scoped>

</style>