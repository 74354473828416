<template>
    <div>
        <PageEditorContentNavContentItem v-for="pageContentId in pageContentIds" :key="pageContentId"
                                         :page-content-id="pageContentId"/>
    </div>
</template>

<script>
import PageEditorContentNavContentItem from "./PageEditorContentNavContentItem.vue"

export default {
    name: "PageEditorContentNavContent",
    components: {PageEditorContentNavContentItem},
    props: {
        contentAreaName: String
    },
    computed: {
        pageEditor() {
            return this.$store.state.pageEditor
        },
        pageId() {
            return this.pageEditor.pageId
        },
        pageContentIds() {
            if (this.contentAreaName === 'Page header' || this.contentAreaName === 'Page footer') {
                return this.pageEditor.contentItems.filter(o => o.contentAreaName === this.contentAreaName).map(o => o.pageContentId)
            } else {
                return this.$store.state.pageContentData.items
                    .filter(o => o.pageId === this.pageId && o.contentArea === this.contentAreaName)
                    .sort((a, b) => (a.displayOrder > b.displayOrder) ? 1 : -1)
                    .map(o => o.id)
            }
        }
    }
}
</script>

<style scoped>

</style>