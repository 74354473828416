var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.content && !_vm.content.isArchived
    ? _c(
        "a",
        {
          staticClass:
            "list-group-item list-group-item-action d-flex align-items-center py-1",
          class: {
            active: _vm.isSelected,
            "selected-secondary": _vm.isLastSelected,
          },
          attrs: { href: "#", "data-content-id": _vm.pageContentId },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.selectContent.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "flex-fill" },
            [
              _vm.content.title
                ? _c("span", [_vm._v(_vm._s(_vm.content.title))])
                : _vm._e(),
              _vm._v(" "),
              _vm.showContentTypeButtons && _vm.content
                ? _c("ContentTypeLabel", {
                    staticClass: "ms-1",
                    attrs: { "content-type-id": _vm.content.contentType },
                  })
                : _c(
                    "span",
                    {
                      class: { "ms-2 text-body-secondary": _vm.content.title },
                    },
                    [_vm._v(_vm._s(_vm.contentTypeTitle))]
                  ),
            ],
            1
          ),
          _vm._v(" "),
          !_vm.selectContentMode && _vm.content
            ? _c("ContentUsageCount", {
                staticClass: "ms-1 small",
                attrs: { "page-content-id": _vm.pageContentId },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("ItemStatusIcons", {
            attrs: { "table-name": "content", id: _vm.contentId },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }