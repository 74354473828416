var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "select",
    {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.formData.month,
          expression: "formData.month",
        },
      ],
      staticClass: "form-select form-select-sm",
      attrs: {
        required: "",
        name: "month",
        oninvalid: "this.setCustomValidity('Please select a month')",
        oninput: "this.setCustomValidity('')",
      },
      on: {
        change: function ($event) {
          var $$selectedVal = Array.prototype.filter
            .call($event.target.options, function (o) {
              return o.selected
            })
            .map(function (o) {
              var val = "_value" in o ? o._value : o.value
              return val
            })
          _vm.$set(
            _vm.formData,
            "month",
            $event.target.multiple ? $$selectedVal : $$selectedVal[0]
          )
        },
      },
    },
    [
      _c("option", { attrs: { value: "" } }, [_vm._v("Month…")]),
      _vm._v(" "),
      _vm._l(
        [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        function (month, index) {
          return _c("option", { domProps: { value: index + 1 } }, [
            _vm._v("\n        " + _vm._s(month) + "\n    "),
          ])
        }
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }