<template>
    <div v-if="hasGroupAccess || isUnpublished || isPending || isExpiring || isExpired || isArchived" class="d-inline-flex gap-1">
        <i v-if="hasGroupAccess" class="bi bi-person-fill" title="This page is assigned group access"/>

        <i v-if="isArchived" class="bi bi-archive-fill" :class="{'text-danger': !isActive}" title="Archived"/>

        <i v-if="isUnpublished" class="bi bi-x-circle-fill" :class="{'text-danger': !isActive}" title="Unpublished"/>
        <i v-else-if="isPending" class="bi bi-clock-fill" :class="{'text-info': !isActive}" title="Pending"/>
        <i v-else-if="isExpiring" class="bi bi-clock-fill" :class="{'text-warning': !isActive}" title="Expiring"/>
        <i v-else-if="isExpired" class="bi bi-clock-fill" :class="{'text-danger': !isActive}" title="Expired"/>
    </div>
</template>

<script>
export default {
    name: "ItemStatusIcons",
    props: {
        tableName: String,
        id: Number,
        isActive: false
    },
    computed: {
        isUnpublished() {
            return this.item?.status === 0
        },
        isPending() {
            return Date.parse(this.item?.validFrom) >= Date.now()
        },
        isExpiring() {
            return Date.parse(this.item?.validTo) >= Date.now()
        },
        isExpired() {
            return Date.parse(this.item?.validTo) <= Date.now()
        },
        isArchived() {
            return this.item?.isArchived === 1
        },
        hasGroupAccess() {
            return this.$store.getters['categories/get'](this.tableName, 'groupAccess', this.item?.id)?.length > 0
        }
    },
    asyncComputed: {
        async item() {
            return await this.$store.dispatch('itemData/get', {
                tableName: this.tableName,
                id: this.id
            })
        }
    }
}
</script>

<style scoped>

</style>