var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.page
    ? _c(
        "div",
        [
          _c(
            "a",
            {
              staticClass:
                "list-group-item list-group-item-action d-flex align-items-center gap-2 border-0 small",
              class: {
                "p-1 pe-2": !_vm.propagatedContentId,
                "p-0 px-2": _vm.propagatedContentId,
                active:
                  _vm.isLastSelected ||
                  _vm.isSelectedContentsPageId ||
                  _vm.isActive,
              },
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.onPageClick.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "Button",
                {
                  staticClass: "btn-sm p-0",
                  attrs: {
                    disabled: !_vm.hasChildPages && !_vm.hasContent,
                    "default-class": "btn-transparent",
                  },
                  nativeOn: {
                    click: function ($event) {
                      $event.preventDefault()
                      $event.stopPropagation()
                      return _vm.onIconClick.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("i", {
                    class: {
                      "bi-chevron-down": _vm.isOpen,
                      "bi-chevron-right": !_vm.isOpen,
                      invisible: !_vm.hasChildPages && !_vm.hasContent,
                    },
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "flex-fill d-inline-flex align-items-center gap-2",
                },
                [
                  _vm.propagatedContentId
                    ? [
                        _c("span", {
                          class: {
                            "bi-square": !_vm.isChecked,
                            "bi-check-square": _vm.isChecked,
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticClass: "flex-fill d-inline-block py-2" },
                          [_vm._v(_vm._s(_vm.page.title))]
                        ),
                        _vm._v(" "),
                        _vm.isChecked && !_vm.selectedContentArea
                          ? _c("SiteTreePageItemSelectMenu", {
                              attrs: {
                                "site-tree-id": _vm.siteTreeId,
                                "page-id": _vm.pageId,
                              },
                            })
                          : _vm._e(),
                      ]
                    : _c("span", [_vm._v(_vm._s(_vm.page.title))]),
                  _vm._v(" "),
                  _c("small", { staticClass: "text-body-secondary" }, [
                    _vm._v(_vm._s(_vm.page.pageLabel)),
                  ]),
                ],
                2
              ),
              _vm._v(" "),
              _vm.isHomePage
                ? _c("i", {
                    staticClass: "bi-house",
                    attrs: { title: "Home page" },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.hasGroupAccess
                ? _c("i", {
                    staticClass: "bi-person-fill",
                    attrs: { title: "This page is assigned group access" },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.page.hidden
                ? _c("i", { staticClass: "bi-eye-slash-fill" })
                : _vm._e(),
              _vm._v(" "),
              _c("ItemStatusIcons", {
                attrs: {
                  "table-name": "pages",
                  id: _vm.pageId,
                  "is-active": _vm.isActive,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.hasContent && _vm.isOpen
            ? _c("SiteTreeContentAreas", {
                attrs: {
                  "page-id": _vm.pageId,
                  "site-tree-id": _vm.siteTreeId,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showChildPages
            ? _c(
                "div",
                { staticClass: "ps-3" },
                _vm._l(_vm.parentChildIds[_vm.pageId], function (pageId) {
                  return _c("SiteTreePageItem", {
                    key: pageId,
                    attrs: {
                      "page-id": pageId,
                      "site-tree-id": _vm.siteTreeId,
                    },
                  })
                }),
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }