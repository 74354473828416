<template>
    <li :title="'#c' + pageContentLinkId" class="list-group-item py-1 pe-2 d-flex align-items-center">
        <div class="flex-fill">
            <FormPageContentField :content-id="pageContentData.contentId"/>
        </div>

        <ContentUsageCount :page-content-id="pageContentData.id" :show-menu="true" class="me-2 lh-1"/>

        <ItemStatusIcons :id="content.id" :table-name="'content'"/>

        <Button class="btn-sm" @click.native="goToContent">
            <i class="bi-pencil-square"/>
        </Button>

        <Button :disabled="!contentIndex && contentAreaFirst" class="btn-sm" @click.prevent.stop.native="moveUp">
            <i class="bi-chevron-up"/>
        </Button>

        <Button :disabled="contentLast && contentAreaLast" class="btn-sm" @click.prevent.stop.native="moveDown">
            <i class="bi-chevron-down"/>
        </Button>

        <Button class="btn-sm" @click.native="removeContent">
            <i class="bi-x-lg"/>
        </Button>
    </li>
</template>

<script>
import FormPageContentField from '../form/FormPageContentField'
import ContentUsageCount from '../ContentUsageCount'
import ItemStatusIcons from '../../common/ItemStatusIcons'
import Button from "../Button.vue"

export default {
    name: "PageContentMobileContent",
    components: {
        Button,
        FormPageContentField,
        ContentUsageCount,
        ItemStatusIcons
    },
    props: {
        pageContentLinkId: Number,
        contentAreas: Array,
        contentArea: String,
        contentAreaFirst: Boolean,
        contentAreaLast: Boolean,
        contentIndex: Number,
        contentLast: Boolean,
    },
    data() {
        return {
            content: {}
        }
    },
    computed: {
        pageContentData() {
            return this.$store.state.pageContentData.items.find((item) => item.id === this.pageContentLinkId)
        },
        items() {
            return this.$store.state.pageContentData.items.filter((obj) => {
                return (
                    obj.pageId === this.pageContentData.pageId
                    && obj.contentArea === this.contentArea
                )
            })
        },
        isSiteTree() {
            return this.$route.name === 'siteTree'
        }
    },
    asyncComputed: {
        content: {
            default: {},
            get() {
                return this.$store.dispatch('itemData/get', {
                    tableName: 'content',
                    id: this.pageContentData.contentId
                })
            }
        }
    },
    methods: {
        goToContent() {
            this.isSiteTree
                ? this.$router.push({
                    name: 'siteTree',
                    params: {
                        pageOrContentId: 'c' + this.pageContentLinkId
                    }
                })
                : this.$router.push({
                    name: 'form',
                    params: {
                        component: 'content',
                        id: this.pageContentData.contentId
                    }
                })
        },
        moveUp() {
            if (this.contentIndex === 0) {
                // get previous content area
                let i = this.contentAreas.indexOf(this.contentArea)
                let contentArea = this.contentAreas[i - 1]

                // Get the number of items in the new area
                let items = this.$store.state.pageContentData.items.filter((obj) => {
                    return (
                        obj.pageId === this.pageContentData.pageId
                        && obj.contentArea === contentArea
                    )
                })
                let newDisplayOrder = items.length + 1

                this.$store.dispatch('request/post', {
                        url: 'api/move-page-content',
                        postData: {
                            pageContentLinkId: this.pageContentData.id,
                            contentArea: contentArea,
                            displayOrder: newDisplayOrder
                        }
                    })
                    .then(() => {
                        this.$store.dispatch('pageContentData/init')
                    })
            } else {
                this.$store.dispatch('request/post', {
                        url: 'api/move-page-content',
                        postData: {
                            pageContentLinkId: this.pageContentData.id,
                            contentArea: this.contentArea,
                            displayOrder: this.pageContentData.displayOrder - 1
                        }
                    })
                    .then(() => {
                        this.$store.dispatch('pageContentData/init')
                    })
            }
        },
        moveDown() {
            if (this.contentLast) {
                // get next content area
                let i = this.contentAreas.indexOf(this.contentArea)
                let contentArea = this.contentAreas[i + 1]

                this.$store.dispatch('request/post', {
                        url: 'api/move-page-content',
                        postData: {
                            pageContentLinkId: this.pageContentData.id,
                            contentArea: contentArea,
                            displayOrder: 1
                        }
                    })
                    .then(() => {
                        this.$store.dispatch('pageContentData/init')
                    })
            } else {
                this.$store.dispatch('request/post', {
                        url: 'api/move-page-content',
                        postData: {
                            pageContentLinkId: this.pageContentData.id,
                            contentArea: this.contentArea,
                            displayOrder: this.pageContentData.displayOrder + 1
                        }
                    })
                    .then(() => {
                        this.$store.dispatch('pageContentData/init')
                    })
            }
        },
        removeContent() {
            this.$store.dispatch('modals/show', {
                componentName: 'ConfirmModal',
                obj: {
                    modalTitle: 'Are you sure?',
                    //modalBody: '<p>Deleting <strong>' + obj.title + '</strong>.</p>',
                    onConfirm: () => {
                        this.$store.dispatch('request/delete', {
                            url: 'api/delete-page-content-link-id/' + this.pageContentData.id
                        }).then(() => {
                            this.$store.dispatch('pageContentData/init')
                        })
                    }
                }
            })
        }
    }
}
</script>

<style scoped>
.content-icon {
    padding: 0 10px;
}

.content-icon-unpublished {
    color: #d9534f;
}

.content-icon-pending {
    color: #198754;
}

.content-icon-expiring {
    color: #ffc107;
}

.content-icon-expired {
    color: #dc3545;
}
</style>