var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("FormPublishBtn", {
        staticClass: "mb-3",
        attrs: { "form-id": _vm.formId },
      }),
      _vm._v(" "),
      _vm.currentData.id
        ? _c("FormDisplayOrderMenu", {
            staticClass: "mb-2",
            attrs: { "form-id": _vm.formId },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("FormDuplicateBtn", { attrs: { "form-id": _vm.formId } }),
      _vm._v(" "),
      _c("FormArchiveBtn", { attrs: { "form-id": _vm.formId } }),
      _vm._v(" "),
      _c("FormDeleteBtn", { attrs: { "form-id": _vm.formId } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }