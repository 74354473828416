<template>
    <div>
        <InternalLinkBtn :id="currentData[field.name]" :field="field" :form-id="formId"
                         :select-content-mode="selectContentMode" :on-change="onChange"
                         :remove-disabled-btn="removeDisabledBtn"/>
    </div>
</template>

<script>
import uniqueValueRequired from '../../vue/mixins/unique-value-required'
import InternalLinkBtn from '../InternalLinkBtn'

export default {
    name: "FormControlTypeInternalLink",
    mixins: [uniqueValueRequired],
    components: {InternalLinkBtn},
    props: {
        formId: String,
        field: Object
    },
    computed: {
        currentData() {
            return this.$store.state[this.formId].currentData
        },
        removeDisabledBtn() {
            // Prevent the content::pageId field from being unset
            return this.tableName === 'content' && this.field.name === 'pageId'
        },
        tableName() {
            return this.$store.state[this.formId].tableName
        },
        selectContentMode() {
            return !!this.field.targetContent
        }
    },
    created() {
        this.$store.dispatch(this.formId + '/setDefaultValue', {
            name: this.field.name,
            value: 0
        })
    },
    methods: {
        onChange(id) {
            this.currentData[this.field.name] = id
        }
    }
}
</script>

<style scoped>

</style>