<template>
    <div ref="overlay" v-show="show">
        <a @wheel="hideIframesOnWheel" @click.prevent="edit" role="button"
           class="d-block w-100 h-100 d-flex align-items-center justify-content-center h1">
            <!--<i class="bi bi-pencil-square"/>-->
        </a>
    </div>
</template>

<script>
export default {
    name: "PageEditorContentOverlay",
    computed: {
        pageEditor() {
            return this.$store.state.pageEditor
        },
        pageId() {
            return this.pageEditor.pageId
        },
        hoverPageContentId() {
            return this.pageEditor.hoverPageContentId
        },
        activeContent() {
            return this.pageEditor.contentItems.find(o => o.pageContentId === this.hoverPageContentId)
        },
        show() {
            return !!this.hoverPageContentId
        }
    },
    mounted() {
        this.activeContent.iframe.contentDocument.body.append(this.$refs.overlay)
    },
    methods: {
        hideIframesOnWheel() {
            this.$store.dispatch('pageEditor/hideIframesOnWheel')
        },
        async edit() {
            if (parseInt(this.$route.query.id || 0) !== this.hoverPageContentId) {
                await this.$router.push({
                    name: 'pageEditor',
                    params: {pageId: this.pageId},
                    query: {id: this.hoverPageContentId}
                })
            }

            await this.$store.dispatch('pageEditor/positionAllIframes')

            this.$store.commit('pageEditor/leftSidebarActiveTab', 'Content')

            this.pageEditor.toolbarDropdown?.click() // Hide the menu
        }
    }
}
</script>

<style scoped>

</style>