<template>
    <FormControlTypeSelect :current-data="currentData" :field="field" :form-id="formId" :options="options"/>
</template>

<script>
import FormControlTypeSelect from '../form-control-type/FormControlTypeSelect'

export default {
    name: "FormControlCategoriesComponentMenu",
    components: {
        FormControlTypeSelect
    },
    props: {
        formId: String,
        currentData: Object,
        field: Object
    },
    computed: {
        options() {
            return this.$store.state.components.items
                .filter(o => o.categoryComponent > 0 && o.categoryList)
                .map(o => ({ value: o.id, text: o.title }))
                .sort((a, b) => (a.text > b.text) ? 1 : -1)
        }
    },
    created() {
        this.field.required = true
    }
}
</script>

<style scoped>

</style>