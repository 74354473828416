var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.id
        ? _c(
            "FormSidebarBtn",
            {
              attrs: {
                "data-bs-toggle": "offcanvas",
                "data-bs-target": "#historyOffcanvas",
              },
            },
            [
              _c("i", { staticClass: "me-2 bi-clock-history" }),
              _vm._v("\n        Open history\n    "),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("FormHistoryOffcanvas", { attrs: { "form-id": _vm.formId } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }