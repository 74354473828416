<template>
    <div v-if="contentTypeTitle" class="d-inline">
        <i class="bi-chevron-compact-right mx-1 align-middle"/>

        <Button class="btn-sm lh-1 p-1" type="button" @click.native="selectContent">
            <small>{{ contentTypeTitle }}</small>
        </Button>
    </div>
</template>

<script>
import Button from "../../common/Button.vue"

export default {
    name: "PageEditorToolbarContentBtn",
    components: {Button},
    computed: {
        pageEditor() {
            return this.$store.state.pageEditor
        },
        activePageContentId() {
            return this.pageEditor.activePageContentId
        },
        pageContent() {
            return this.$store.state.pageContentData.items.find(o => o.id === this.activePageContentId)
        },
        contentId() {
            return this.pageContent?.contentId
        },
        contentTypeTitle() {
            return this.$store.getters['templates/contentTypes'].find(o => o.id === this.content?.contentType)?.title
        }
    },
    asyncComputed: {
        content() {
            if (this.contentId) {
                return this.$store.dispatch('itemData/get', {
                    tableName: 'content',
                    id: this.contentId
                })
            }
        }
    },
    methods: {
        async selectContent() {
            await this.$store.dispatch('pageEditor/scrollToContent', {
                pageContentId: this.activePageContentId
            })

            this.$store.commit('pageEditor/leftSidebarActiveTab', 'Content')
        }
    }
}
</script>

<style scoped>

</style>