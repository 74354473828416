var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "select",
    {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.formData.primaryCategory,
          expression: "formData.primaryCategory",
        },
      ],
      staticClass: "form-select form-select-sm",
      attrs: { name: "primaryCategory", required: "" },
      on: {
        change: function ($event) {
          var $$selectedVal = Array.prototype.filter
            .call($event.target.options, function (o) {
              return o.selected
            })
            .map(function (o) {
              var val = "_value" in o ? o._value : o.value
              return val
            })
          _vm.$set(
            _vm.formData,
            "primaryCategory",
            $event.target.multiple ? $$selectedVal : $$selectedVal[0]
          )
        },
      },
    },
    [
      _c("option", { attrs: { value: "", selected: "", disabled: "" } }, [
        _vm._v("Select…"),
      ]),
      _vm._v(" "),
      _vm._l(_vm.categories, function (category) {
        return _c(
          "option",
          { key: category.id, domProps: { value: category.id } },
          [_vm._v("\n        " + _vm._s(category.title) + "\n    ")]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }