<template>
    <select v-model="formData.reportName" class="form-select form-select-sm" name="reportName">
        <option value="" selected disabled>Select a report&hellip;</option>
        <option v-for="report in reports" :value="report.name">{{ report.label }}</option>
    </select>
</template>

<script>
export default {
    name: "ReportMenu",
    props: {
        reportsModule: Object
    },
    computed: {
        formData() {
            return this.reportsModule.formData
        },
        reports() {
            return this.reportsModule.reports
        }
    },
    created() {
        const reportName = this.$route.query.reportName || ''
        this.$set(this.formData, 'reportName', reportName)
    }
}
</script>

<style scoped>

</style>