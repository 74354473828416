var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "offcanvas",
      staticClass: "offcanvas",
      class: {
        "offcanvas-start": !_vm.offcanvasEnd,
        "offcanvas-end": _vm.offcanvasEnd,
      },
      attrs: {
        id: "addExistingContentOffcanvas",
        "aria-labelledby": "addExistingContentOffcanvasLabel",
        tabindex: "-1",
      },
    },
    [
      _c(
        "div",
        { staticClass: "offcanvas-header" },
        [
          _c("SiteTreeNavigationMenu", {
            staticClass: "w-auto",
            attrs: { "site-tree-id": _vm.siteTreeId },
          }),
          _vm._v(" "),
          _c("button", {
            ref: "closeBtn",
            staticClass: "btn-close",
            attrs: {
              type: "button",
              "data-bs-dismiss": "offcanvas",
              "aria-label": "Close",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "offcanvas-body" },
        [_c("SiteTree", { attrs: { "site-tree-id": _vm.siteTreeId } })],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }