var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "ul",
        { staticClass: "list-group mb-2" },
        _vm._l(_vm.items, function (item) {
          return _c(
            "li",
            {
              key: item.id,
              staticClass: "list-group-item d-flex align-items-center",
              class: { "py-1 px-2 small": _vm.formSmall },
            },
            [
              _c(
                "div",
                { staticClass: "flex-fill" },
                [
                  _c("PagePath", {
                    staticClass: "me-2",
                    attrs: { "page-id": item.pageId },
                  }),
                  _vm._v(
                    "\n                " +
                      _vm._s(item.contentArea) +
                      "\n            "
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "Button",
                {
                  staticClass: "btn-sm",
                  nativeOn: {
                    click: function ($event) {
                      return _vm.goToPage(item.pageId)
                    },
                  },
                },
                [_c("i", { staticClass: "bi-pencil-square" })]
              ),
            ],
            1
          )
        }),
        0
      ),
      _vm._v(" "),
      _c(
        "Button",
        {
          staticClass: "btn-sm border",
          nativeOn: {
            click: function ($event) {
              return _vm.showSiteTree.apply(null, arguments)
            },
          },
        },
        [_c("i", { staticClass: "bi bi-plus-lg" })]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }