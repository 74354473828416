<template>
    <Button class="border" data-bs-toggle="offcanvas" data-bs-target="#contentTypeFieldsetOffcanvas"
            aria-controls="contentTypeFieldsetOffcanvas" :disabled="!hasFieldsets">
        <i class="bi bi-plus-lg"/>
        Fieldset
    </Button>
</template>

<script>
import Button from "../Button.vue"

export default {
    name: "ContentTypeNewFieldsetBtn",
    components: {Button},
    computed: {
        hasFieldsets() {
            return this.$store.state.componentFields.fieldsets.length > 0
        }
    }
}
</script>

<style scoped>

</style>