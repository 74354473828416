var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Button",
    {
      staticClass: "btn-sm btn-link text-decoration-none",
      attrs: {
        "data-bs-toggle": "offcanvas",
        "data-bs-target": "#" + _vm.offCanvasId,
        "aria-controls": _vm.offCanvasId,
      },
    },
    [_c("small", [_vm._v("filters")])]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }