var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.contentIds.length,
          expression: "contentIds.length",
        },
      ],
    },
    [
      _c("div", { staticClass: "p-1 px-2 small fw-bold text-body-secondary" }, [
        _c("span", { staticClass: "small" }, [_vm._v(_vm._s(_vm.contentArea))]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "list-group" },
        _vm._l(_vm.contentIds, function (contentId) {
          return _c("SiteTreeContentItem", {
            key: contentId,
            attrs: {
              "site-tree-id": _vm.siteTreeId,
              "page-content-id": contentId,
            },
          })
        }),
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }