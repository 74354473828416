<template>
    <small class="float-end p-1 text-body-secondary" title="The number of allowed characters remaining">
        {{ remaining }}
    </small>
</template>

<script>
export default {
    name: "MaxLengthCounter",
    props: {
        maxlength: Number,
        value: [String, Number],
    },
    computed: {
        remaining() {
            const value = this.value + ''
            return this.maxlength - (value.length || 0)
        }
    }
}
</script>

<style scoped>

</style>