<template>
    <Button v-if="categoryTableName" @click.native="selectCategory" class="btn-sm border">
        <!--<i v-if="!value" class="bi bi-plus-lg"/>-->
        <span v-if="!value">Browse…</span>
        <FormControlCategoryTitle v-else :id="value" :table-name="categoryTableName"/>
    </Button>
</template>

<script>
import FormControlCategoryTitle from '../../common/form-control/FormControlCategoryTitle'
import Button from "../Button.vue"

export default {
    name: "SearchFilterFieldCategory",
    components: {
        Button,
        FormControlCategoryTitle
    },
    props: {
        viewId: String,
        filterKey: Number,
        value: Number
    },
    data() {
        return {
            categoryTableName: undefined
        }
    },
    computed: {
        filter() {
            return this.$store.state[this.viewId].filters[this.filterKey]
        },
        tableName() {
            return this.$store.state[this.viewId].tableName
        },
        componentId() {
            return this.$store.state.components.componentIds[this.tableName]
        }
    },
    watch: {
        // Update the table name whenever the filter is modified, and when the component is generated (immediate).
        // Otherwise, for example, if changing from Author to Category the latter select list would show authors.
        filter: {
            deep: true,
            immediate: true,
            handler() {
                this.setCategoryTableName()
            }
        }
    },
    methods: {
        setCategoryTableName() {
            // Load the name of the category component for use by the select listing service.
            const structure = this.$store.getters['componentStructure/get'](this.componentId)
                .find(o => o.columnName === this.filter.columnName)
            const component = this.$store.state.components.items
                .find(o => o.id === structure.categoryComponentId)
            this.categoryTableName = component.tableName
        },
        selectCategory() {
            const id = this.filter.value
            const modal = this.$store.dispatch('modals/show', {
                componentName: 'SelectListModal',
                obj: {
                    // Use the column's name as a namespace for the listing name to isolate it from the main listing.
                    // This allows its options, such as sort by, to operate independently.
                    listingName: this.categoryTableName + '.' + this.filter.columnName,
                    selectedIds: id ? [id] : [],
                    onSelect: (selectedIds) => {
                        // input targets the component's v-model property.
                        this.$emit('input', selectedIds.pop() || undefined)

                        modal.then((obj) => {
                            this.$store.dispatch('modals/remove', obj.index)
                        })
                    }
                }
            })
        }
    }
}
</script>

<style scoped>

</style>