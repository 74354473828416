<template>
    <Button v-if="page" class="btn-sm lh-1 p-1" type="button" @click.native="selectPage">
        <small>{{ page.title }}</small>
    </Button>
</template>

<script>
import Button from "../../common/Button.vue"

export default {
    name: "PageEditorToolbarPageBtn",
    components: {Button},
    computed: {
        pageEditor() {
            return this.$store.state.pageEditor
        },
        pageId() {
            return this.pageEditor.pageId
        },
        siteTreeId() {
            return this.pageEditor.siteTreeId
        }
    },
    asyncComputed: {
        async page() {
            return await this.$store.dispatch('itemData/get', {
                tableName: 'pages',
                id: this.pageId
            })
        }
    },
    methods: {
        async selectPage() {
            if (this.$route.params.pageId !== this.pageId || this.$route.fullPath.includes('?')) {
                await this.$router.push({
                    name: 'pageEditor',
                    params: {pageId: this.pageId},
                    query: null
                })
            }

            await this.$store.dispatch(this.siteTreeId + '/setPageId', this.pageId)

            this.$store.commit('pageEditor/leftSidebarActiveTab', 'Site tree')
        }
    }
}
</script>

<style scoped>

</style>