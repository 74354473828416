<template>
    <Button class="border" title="Add a field" @click.native="doShowAddFieldForm"
            :active-class="'btn-primary'" :active-value="activeEditId === 0">
        <i class="bi bi-plus-lg"/>
        Field
    </Button>
</template>

<script>
import Button from "../Button.vue"

export default {
    name: "ContentTypeNewAttributeBtn",
    components: {Button},
    computed: {
        activeEditId() {
            return this.$store.state.componentFields.activeEditId
        },
        isContentTypeComponent() {
            return this.$store.getters['componentFields/isContentType']
        }
    },
    methods: {
        doShowAddFieldForm() {
            // Relates to when editing a content type's fields in Themes -> Content types
            if (this.isContentTypeComponent) {
                this.$store.commit('componentFields/activeEditId', 0)
            } else {
                this.$router.push({
                    name: 'componentFields',
                    params: {
                        fieldsetId: this.$store.state.componentFields.activeFieldsetId || 0,
                        fieldId: 0
                    }
                })
            }
        }
    }
}
</script>

<style scoped>

</style>