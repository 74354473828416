<template>
    <div>
        <FormPublishBtn :form-id="formId" class="mb-3"/>
        <FormDisplayOrderMenu v-if="currentData.id" :form-id="formId" class="mb-2"/>
        <FormDuplicateBtn :form-id="formId"/>
        <FormArchiveBtn :form-id="formId"/>
        <FormDeleteBtn :form-id="formId"/>
    </div>
</template>

<script>
import FormDisplayOrderMenu from "../../common/form/FormDisplayOrderMenu"
import FormDuplicateBtn from "../../common/form/FormDuplicateBtn"
import FormDeleteBtn from "../../common/form/FormDeleteBtn"
import FormPublishBtn from "../../common/form/FormPublishBtn"
import FormArchiveBtn from "../../common/form/FormArchiveBtn.vue"

export default {
    name: "PageEditorPageSettings",
    components: {
        FormArchiveBtn,
        FormPublishBtn,
        FormDeleteBtn,
        FormDuplicateBtn,
        FormDisplayOrderMenu
    },
    props: {
        formId: String
    },
    computed: {
        currentData() {
            return this.$store.state[this.formId].currentData
        }
    }
}
</script>

<style scoped>

</style>