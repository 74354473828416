<template>
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">{{ modalTitle }}</h4>

                <button type="button" class="btn-close" @click="$emit('hide-modal')" aria-label="Close"></button>
            </div>

            <div class="modal-body">
                <!--<FormHeader :form-id="formId"/>-->

                <Form v-if="formId" :id="id" :form-id="formId"/>
            </div>

            <div class="modal-footer">
                <button v-if="id" class="btn btn-danger" type="button" @click.prevent="deleteItem">
                    <i class="bi-trash3"/>
                </button>

                <FormSaveBtn :form-id="formId"/>
            </div>
        </div>
    </div>
</template>

<script>
import Form from '../../common/form/Form'
import FormSaveBtn from '../../common/form/FormSaveBtn'

export default {
    name: "ThemesEditorAddModal",
    components: {
        Form,
        FormSaveBtn,
    },
    props: {
        modal: Object,
        options: Object,
    },
    data() {
        return {
            id: this.options.id || 0,
        }
    },
    computed: {
        formId() {
            return 'form:' + this.tableName + ':' + this.id
        },
        tableName() {
            return this.$store.state.themes.tableName
        },
        sectionName() {
            return this.$store.state.themes.sectionName
        },
        config() {
            return this.$store.state.settings.config
        }
    },
    created() {
        this.createForm()
        this.setModalTitle()
    },
    methods: {
        createForm() {
            let fieldNames = [
                'title'
            ]
            switch (this.sectionName) {
                case 'page_templates':
                    fieldNames.push(
                        'cssGroupName'
                    )
                    break
                case 'content_types':
                    fieldNames.push(
                        'contentType',
                        'componentId',
                        'filterAttribute',
                        'detailsPageLayout',
                        'lazyLoad'
                    )
                    break
                case 'themes_css':
                    fieldNames.push(
                        'mode',
                        'groups'
                    )
                    break
                default:
                    fieldNames.push(
                        'pageHeader',
                        'pageFooter'
                    )
            }

            // Enables the unsaved form changes confirmation modal
            this.modal.formId = this.formId

            this.$store.dispatch('forms/createForm', {
                tableName: this.tableName,
                id: this.id,
                fieldNames: fieldNames,
                currentData: {
                    type: this.getType()
                },
                onInit: () => {
                    const firstField = this.$store.state[this.formId].fields[0]
                    firstField.autofocus = true
                },
                onSave: (obj) => {
                    // If adding a new item load it in themes.
                    if (!this.id) {
                        this.$store.dispatch('themes/setItems')
                        this.$router.push({name: 'themesEditor', query: {section: this.sectionName, id: obj.id}})

                        // When inserting a new CT update the components modal to include its new component.
                        if (this.sectionName === 'content_types') {
                            this.$store.dispatch('components/init')
                        }
                    }

                    // This hack was required to allow FormSaveBtn::onSave to execute, as it references the form's
                    // VueX module, which is destroyed when the modal is removed.
                    setTimeout(() => {
                        this.$emit('hide-modal')
                    }, 100)
                }
            })
        },
        getType() {
            let type = ''
            if (this.sectionName === 'page_templates') {
                type = 'page'
            } else if (this.sectionName === 'content_types') {
                type = 'contentType'
            }
            return type
        },
        setModalTitle() {
            let titles = {
                themes_css: 'CSS',
                themes_javascript: 'script',
                content_types: 'content type',
                page_templates: 'page template',
                templates_templates: 'template'
            }
            let prefix = this.id ? 'Edit ' : 'New '
            this.modalTitle = prefix + titles[this.sectionName]
        },
        deleteItem() {
            this.$store.dispatch('themes/deleteItem', {
                router: this.$router,
                id: this.id,
                onSuccess: () => {
                    this.$emit('hide-modal')
                }
            })
        }
    }
}
</script>

<style scoped>

</style>