<template>
    <Button class="d-block btn-sm w-100 text-start">
        <slot/>
    </Button>
</template>

<script>
import {defineComponent} from 'vue'
import Button from "../Button.vue"

export default defineComponent({
    name: "FormSidebarBtn",
    components: {Button}
})
</script>

<style scoped>

</style>