<template>
    <select :id="field.id" v-model="currentData[field.name]" :name="field.name" :required="field.required"
            class="form-select">
        <option v-for="option in options" :value="option.value">
            {{ option.text }}
        </option>
    </select>
</template>

<script>
export default {
    name: "FormControlMProductsProductsCategoryId",
    props: {
        formId: String,
        currentData: Object,
        field: Object,
    },
    data() {
        return {
            options: [],
            // For required select menus to fail checkValidity they must have a blank value.
            unsetValue: this.field.required ? '' : 0
        }
    },
    created() {
        this.$store.dispatch(this.formId + '/setDefaultValue', {
            name: this.field.name,
            value: this.unsetValue,
        })

        this.setOptions()
    },
    methods: {
        setOptions() {
            this.$store.dispatch('request/get', {
                    url: 'api/component/m_products_categories',
                    params: {
                        parentId: 0,
                        sort: 'title',
                    }
                })
                .then((obj) => {
                    let options = [{
                        value: this.unsetValue,
                        text: 'Select…',
                    }]
                    let items = obj.data.items
                    if (items) {
                        items.forEach((item) => {
                            options.push({
                                value: item.id,
                                text: item.title,
                            })
                        })
                    }
                    this.options = options
                })
        }
    }
}
</script>

<style scoped>

</style>