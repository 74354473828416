var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "aside",
    {
      staticClass: "overflow-y-scroll",
      class: { "open border-start": _vm.isOpen, closed: !_vm.isOpen },
    },
    [
      _vm.activeContentAreaName
        ? _c("PageEditorContentAreaPanel")
        : _vm.activePageContentId
        ? _c("PageEditorContentPanel", { key: _vm.activePageContentId })
        : _c("PageEditorPagePanel", { key: _vm.pageId }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }