var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.categoryTableName
    ? _c(
        "Button",
        {
          staticClass: "btn-sm border",
          nativeOn: {
            click: function ($event) {
              return _vm.selectCategory.apply(null, arguments)
            },
          },
        },
        [
          !_vm.value
            ? _c("span", [_vm._v("Browse…")])
            : _c("FormControlCategoryTitle", {
                attrs: { id: _vm.value, "table-name": _vm.categoryTableName },
              }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }