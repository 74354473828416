<template>
    <div v-if="show" class="dropdown pb-3 border-bottom">
        <Button data-bs-toggle="dropdown" class="btn-sm border dropdown-toggle">
            {{ selectedFilter ? selectedFilter.name : 'Saved filters' }}
        </Button>

        <div class="dropdown-menu">
            <div v-for="(o, i) in savedFilters" :key="i" @click="loadSavedFilter(o.name)" role="button"
                 class="dropdown-item pe-2 d-flex align-items-center" :class="{'active': isSelected(o.filters)}">
                <span class="flex-fill">{{ o.name }}</span>

                <button @click.stop="deletedSavedFilter(o.name)" class="ms-3 btn btn-sm btn-danger p-0 lh-1 rounded-5"><i class="bi bi-x"/></button>
            </div>
        </div>
    </div>
</template>

<script>
import Button from "../Button.vue"

export default {
    name: "SearchFilterSavedFiltersMenu",
    components: {Button},
    props: {
        viewId: String
    },
    computed: {
        tableName() {
            return this.$store.state[this.viewId].tableName
        },
        savedFilters() {
            return this.$store.state[this.viewId].savedFilters
        },
        show() {
            return this.savedFilters.length > 0
        },
        filters() {
            return this.$store.state[this.viewId].filters
        },
        selectedFilter() {
            return this.savedFilters.find(o => JSON.stringify(o.filters) === JSON.stringify(this.filters))
        },
        isAnySelected() {
            return this.savedFilters.find(o => JSON.stringify(o.filters) === JSON.stringify(this.filters))
        }
    },
    methods: {
        isSelected(filters) {
            return JSON.stringify(filters) === JSON.stringify(this.filters)
        },
        loadSavedFilter(name) {
            this.$store.dispatch(this.viewId + '/loadSavedFilter', name)
        },
        deletedSavedFilter(name) {
            this.$store.dispatch(this.viewId + '/deleteSavedFilter', name)
        }
    }
}
</script>

<style scoped>
.dropdown-item button {
    visibility: hidden;
}

.dropdown-item:hover button {
    visibility: visible;
}
</style>