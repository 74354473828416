export default {
    namespaced: true,
    state: {
        items: []
    },
    mutations: {
        items(state, items) {
            state.items = items
        }
    },
    actions: {
        async init({commit, dispatch}) {
            const o = await dispatch('request/get', {
                url: 'api/page-content-link-data'
            }, {root: true})
            
            commit('items', o.data)
        }
    }
}