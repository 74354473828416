<template>
    <div class="dropdown">
        <Button data-bs-toggle="dropdown" class="btn-sm border text-nowrap focus-ring">{{ columnTitle }}</Button>
        <ul class="dropdown-menu pt-0" role="menu">
            <template v-for="fieldset in fieldsets">
                <li class="dropdown-item disabled" :class="{'pt-3': fieldset.legend !== '&nbsp;'}"
                    v-html="fieldset.legend"/>
                <li v-for="column in getColumns(fieldset.id)" :key="column.id" @click="setColumn(column.columnName)">
                    <a href @click.prevent :class="{'active': column.columnName === columnName}" class="dropdown-item">
                        {{ column.title }}
                    </a>
                </li>
            </template>
        </ul>
    </div>
</template>

<script>
import Button from "../Button.vue"

export default {
    name: "SearchFilterColumnMenu",
    components: {Button},
    props: {
        viewId: String,
        filterKey: Number,
        columns: Array
    },
    computed: {
        tableName() {
            return this.$store.state[this.viewId].tableName
        },
        filter() {
            return this.$store.state[this.viewId].filters[this.filterKey]
        },
        columnName() {
            return this.filter.columnName
        },
        columnTitle() {
            return this.columns.find(o => o.columnName === this.columnName)?.title
        },
        componentId() {
            return this.$store.state.components.componentIds[this.tableName]
        },
        fieldsets() {
            const fieldsets = this.$store.state.fieldsets.items.filter(o => o.componentId === this.componentId)

            fieldsets.push({id: 0, legend: '&nbsp;'})

            return fieldsets
        }
    },
    methods: {
        getColumns(fieldsetId) {
            return this.columns.filter(o => o.fieldsetId === fieldsetId)
        },
        setColumn(columnName) {
            this.setFilter('columnName', columnName)

            const column = this.columns.find(o => o.columnName === columnName)
            this.setFilter('type', column.type)

            const defaultOption = this.$store.getters['advancedFilters/typesOptions'](column.type)[0]
            this.setFilter('condition', defaultOption.condition)
            this.setFilter('predicate', defaultOption.predicate)
            this.setFilter('conditionText', defaultOption.text)

            // Unset the previously set value, otherwise it might conflict with the new field's props.
            // For example, the relationshipOneToMany field's component expects a String value.
            // UPDATE: The default option must be the value which the emptied field will set. For example, a text field
            // sets an empty string. This is so that saved filters' JSON string will match the JSON string of active
            // filters when their fields are emptied.
            this.setFilter('value', defaultOption.defaultValue)
        },
        setFilter(name, value) {
            this.$store.dispatch(this.viewId + '/setFilterProp', {
                key: this.filterKey,
                name: name,
                value: value
            })
        }
    }
}
</script>

<style scoped>

</style>