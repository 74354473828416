var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", {
    staticClass: "h-100 px-1 position-absolute top-0 end-0",
    class: {
      "sidebar-toggle-hidden": !_vm.open,
      "border-end": !_vm.placementRight,
      "border-start": _vm.placementRight,
    },
    attrs: { role: "button" },
    on: { click: _vm.toggle },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }