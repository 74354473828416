<template>
    <a role="button" class="list-group-item list-group-item-action py-1 small d-inline-flex align-items-center gap-1"
       :class="classNames" @mouseover="mouseoverContent" @mouseout="mouseoutContent" @click="selectContent">
        <span class="flex-fill small">{{ contentTypeTitle }}</span>

        <ItemStatusIcons :table-name="'content'" :id="contentId" :is-active="isActive"/>
    </a>
</template>

<script>
import ItemStatusIcons from "../../common/ItemStatusIcons.vue"

export default {
    name: "PageEditorContentNavContentItem",
    components: {ItemStatusIcons},
    props: {
        pageContentId: Number
    },
    computed: {
        contentId() {
            return this.$store.state.pageContentData.items.find(o => o.id === this.pageContentId)?.contentId
        },
        contentTypeTitle() {
            return this.$store.getters['templates/contentTypes'].find(o => o.id === this.content?.contentType)?.title
        },
        pageEditor() {
            return this.$store.state.pageEditor
        },
        pageId() {
            return this.pageEditor.pageId
        },
        activePageContentId() {
            return this.pageEditor.activePageContentId
        },
        hoverPageContentId() {
            return this.pageEditor.hoverPageContentId
        },
        isActive() {
            return this.pageContentId === this.activePageContentId
        },
        classNames() {
            if (this.pageContentId === this.activePageContentId) {
                return 'active'
            } else if (this.pageContentId === this.hoverPageContentId) {
                return 'list-group-item-hover'
            }
        }
    },
    asyncComputed: {
        content() {
            if (this.contentId) {
                return this.$store.dispatch('itemData/get', {
                    tableName: 'content',
                    id: this.contentId
                })
            }
        }
    },
    methods: {
        mouseoverContent() {
            this.$store.commit('pageEditor/highlightPageContentId', this.pageContentId)
        },
        mouseoutContent() {
            this.$store.commit('pageEditor/highlightPageContentId', 0)
        },
        async selectContent() {
            if (this.$route.params.pageId !== this.pageId || this.$route.query.id !== this.pageContentId) {
                await this.$router?.push({
                    name: 'pageEditor',
                    params: {pageId: this.pageId},
                    query: {id: this.pageContentId}
                })
            }

            await this.$store.dispatch('pageEditor/positionAllIframes')

            await this.$store.dispatch('pageEditor/scrollToContent', {pageContentId: this.pageContentId})
        }
    }
}
</script>

<style scoped>
.list-group-item {
    border-color: transparent !important;
}

.list-group-item-hover {
    z-index: 1;
    color: var(--bs-list-group-action-hover-color);
    text-decoration: none;
    background-color: var(--bs-list-group-action-hover-bg);
}
</style>