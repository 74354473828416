var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("span", { staticClass: "d-flex align-items-center" }, [
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.formData.startDate,
          expression: "formData.startDate",
        },
      ],
      staticClass: "form-control form-control-sm",
      attrs: {
        type: "date",
        required: "",
        oninvalid: "this.setCustomValidity('Please enter a start date')",
        oninput: "this.setCustomValidity('')",
      },
      domProps: { value: _vm.formData.startDate },
      on: {
        input: function ($event) {
          if ($event.target.composing) return
          _vm.$set(_vm.formData, "startDate", $event.target.value)
        },
      },
    }),
    _vm._v(" "),
    _c("span", { staticClass: "ms-2 text-muted" }, [_vm._v("to")]),
    _vm._v(" "),
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.formData.endDate,
          expression: "formData.endDate",
        },
      ],
      staticClass: "ms-2 form-control form-control-sm",
      attrs: {
        type: "date",
        required: "",
        oninvalid: "this.setCustomValidity('Please enter an end date')",
        oninput: "this.setCustomValidity('')",
      },
      domProps: { value: _vm.formData.endDate },
      on: {
        input: function ($event) {
          if ($event.target.composing) return
          _vm.$set(_vm.formData, "endDate", $event.target.value)
        },
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }