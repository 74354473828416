<template>
    <Button @click.native="onClick" class="border">{{ field.buttonText || 'Edit' }}</Button>
</template>

<script>
import Button from "../Button.vue"

export default {
    name: "CodemirrorShowModalBtn",
    components: {Button},
    props: {
        formId: String,
        field: Object,
        modalTitle: String,
        currentData: Object,
        mode: String,
    },
    data() {
        return {
            codeMirrorState: {},
        }
    },
    computed: {
        originalData() {
            return this.$store.state[this.formId].originalData
        },
        tableName() {
            return this.$store.state[this.formId].tableName
        },
    },
    created() {
        if (this.currentData[this.field.name] === undefined) {
            this.$store.commit(this.formId + '/presetData', {
                name: this.field.name,
                value: ''
            })
        }

        // This ensures that whenever code is edited the latest version of it is presented. This is to get
        // around a bug whereby cached data was being presented and if saved would result in loss of the
        // latest data. This may be a wider issue which needs to be resolved elsewhere, but for now it will
        // suffice.
        if (this.currentData.id) {
            this.$store.dispatch('itemData/get', {
                    tableName: this.tableName,
                    id: this.currentData.id,
                    refresh: true,
                })
                .then((obj) => {
                    this.currentData[this.field.name] = obj[this.field.name]
                })
        }
    },
    methods: {
        onClick() {
            let indent = 2
            let mode = this.mode || 'text/html'

            let originalValue = this.originalData[this.field.name]
            if (typeof originalValue === 'object') {
                originalValue = JSON.stringify(originalValue, undefined, indent)
            }

            let value = this.currentData[this.field.name]
            if (typeof value === 'object') {
                value = JSON.stringify(value, undefined, indent)
            }

            this.$store.dispatch('modals/show', {
                componentName: 'CodeMirrorModal',
                obj: {
                    formId: this.formId,
                    tableName: this.tableName,
                    field: this.field,
                    modalTitle: this.modalTitle,
                    mode: mode,
                    readOnly: this.readOnly,
                    originalValue: originalValue,
                    currentData: this.currentData,
                    value: value,
                    state: this.codeMirrorState,
                }
            })
        }
    }
}
</script>

<style scoped>

</style>