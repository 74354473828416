<template>
    <div v-if="columns" class="root d-flex align-items-center py-3 border-bottom">
        <div class="flex-fill d-flex align-items-center">
            <SearchFilterColumnMenu :view-id="viewId" :filterKey="filterKey" :columns="columns"/>
            <SearchFilterColumnOptions :view-id="viewId" :filterKey="filterKey" :columns="columns" class="ms-1"/>
            <SearchFilterField :view-id="viewId" :filterKey="filterKey" class="ms-1 focus-ring"
                               data-search-filter-field/>
            <SearchFilterItemResetBtn :view-id="viewId" :filter-key="filterKey" class="ms-1"/>
        </div>

        <Button @click.native="deleteFilter" class="ms-1 btn-sm focus-ring"><i class="bi bi-trash3"/></Button>
    </div>
</template>

<script>
import SearchFilterColumnMenu from './SearchFilterColumnMenu'
import SearchFilterColumnOptions from './SearchFilterColumnOptions'
import SearchFilterField from './SearchFilterField'
import SearchFilterItemResetBtn from "./SearchFilterItemResetBtn.vue"
import Button from "../Button.vue"

export default {
    name: "SearchFilterItem",
    components: {
        Button,
        SearchFilterItemResetBtn,
        SearchFilterColumnMenu,
        SearchFilterColumnOptions,
        SearchFilterField
    },
    props: {
        viewId: String,
        filterKey: Number
    },
    computed: {
        tableName() {
            return this.$store.state[this.viewId].tableName
        },
        componentId() {
            return this.$store.state.components.componentIds[this.tableName]
        },
        fields() {
            return this.$store.getters['componentStructure/get'](this.componentId)
        },
        firstFieldsetId() {
            return this.$store.state.fieldsets.items.find(o => o.componentId === this.componentId).id
        },
        columns() {
            const idColumn = {
                fieldsetId: this.firstFieldsetId,
                columnName: this.tableName + 'ID',
                title: 'ID',
                type: 'number'
            }

            let activeDataTypes = [
                'checkbox',
                'color',
                'currency',
                'date',
                'dateDate',
                'datetime-local',
                'email',
                'internalLink',
                //'file',
                'number',
                'password',
                'range',
                'relationshipOneToMany',
                'relationshipManyToMany',
                'select',
                'tel',
                'text',
                'textarea',
                'texteditor',
                'time',
                'url',
            ]

            const columns = this.fields.filter(o => {
                return activeDataTypes.indexOf(o.type) >= 0
            })

            return [idColumn].concat(columns)
        }
    },
    methods: {
        deleteFilter() {
            this.$store.dispatch(this.viewId + '/deleteFilter', this.filterKey)
        }
    }
}
</script>

<style scoped>
.root > button {
    visibility: hidden;
}

.root:hover button {
    visibility: visible;
}
</style>