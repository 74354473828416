<template>
    <Button v-if="show" :disabled="isSaving" class="rounded-0" title="Save and close" @click.native="onClick"
            :active-class="'btn-warning'" :active-value="isModified">
        <i class="bi-chevron-right"/>
    </Button>
</template>

<script>
import Button from "../Button.vue"

export default {
    name: "FormSaveCloseBtn",
    components: {Button},
    props: {
        formId: String,
    },
    computed: {
        isModified() {
            return this.$store.getters[this.formId + '/isModified']()
        },
        isSaving() {
            return this.$store.state[this.formId].isSaving
        },
        tableName() {
            return this.$store.state[this.formId].tableName
        },
        show() {
            return this.tableName.indexOf('product_variations__') === -1
                && this.tableName !== 'files'
        },
        isSiteTree() {
            return this.$route.name === 'siteTree'
        },
        onSaveRedirectUrl() {
            if (this.isSiteTree) {
                // The page form doesn't have a Save & Close btn, so it'll always be 'content'
                //if (this.tableName === 'content') {
                const path = this.$route.path
                const parts = path.split('/')
                const pageContentId = parseInt(parts.pop().substring(1))
                const obj = this.$store.state.pageContentData.items.find(o => o.id === pageContentId)
                return obj.pageId ? '/#/site_tree/' + obj.pageId : '/#/site_tree'
            }

            let url = '/#/' + this.tableName

            let search = document.location.hash.split('?')[1]
            if (search) {
                url += '?' + search
            }

            return url
        }
    },
    methods: {
        onClick() {
            // This has to be calculated here. If calculated within the then() below the URL was missing the query
            // params for some reason.
            //console.log(this.onSaveRedirectUrl)

            this.$store.dispatch(this.formId + '/save')
                .then((obj) => {
                    if (obj) {
                        // The form VueX module's save action will return invalid: true if validation fails.
                        // If the form's validation fails don't redirect.
                        if (obj.invalid) {
                            return
                        }

                        window.location = this.onSaveRedirectUrl
                    }
                })
        }
    },
}
</script>

<style scoped>

</style>