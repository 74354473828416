<template>
    <div :class="{'sidebar-toggle-hidden': !open, 'border-end': !placementRight, 'border-start': placementRight}"
         class="h-100 px-1 position-absolute top-0 end-0" role="button" @click="toggle"/>
</template>

<script>
export default {
    name: "SidebarToggleBar",
    props: {
        name: String,
        placementRight: Boolean
    },
    computed: {
        open() {
            return this.$store.state.sidebarToggle[this.name]
        }
    },
    created() {
        if (this.$store.state.sidebarToggle[this.name] === undefined) {
            this.$set(this.$store.state.sidebarToggle, this.name, true)
        }
    },
    methods: {
        toggle() {
            this.$store.state.sidebarToggle[this.name] = !this.$store.state.sidebarToggle[this.name]
        }
    }
}
</script>

<style scoped>
[data-bs-theme="dark"] .border-end:hover,
[data-bs-theme="dark"] .border-start:hover,
[data-bs-theme="light"] .border-end:hover,
[data-bs-theme="light"] .border-start:hover {
    border-color: var(--bs-secondary-color) !important;
}

.border-end.sidebar-toggle-hidden,
.border-start.sidebar-toggle-hidden {
    border-left-width: 5px;
}
</style>