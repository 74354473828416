var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "sidebar-toggle" }, [
    _vm.$slots.leftSidebar
      ? _c(
          "aside",
          {
            staticClass:
              "sidebar-toggle-left position-relative d-none d-md-flex",
            class: { "sidebar-toggle-hidden": !_vm.isOpen(_vm.leftName) },
          },
          [
            _vm._t("leftSidebar"),
            _vm._v(" "),
            _c("SidebarToggleBar", { attrs: { name: _vm.leftName } }),
          ],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "sidebar-toggle-section" },
      [_vm._t("default")],
      2
    ),
    _vm._v(" "),
    _vm.$slots.rightSidebar
      ? _c(
          "aside",
          {
            staticClass: "sidebar-toggle-right d-none d-md-flex",
            class: { "sidebar-toggle-hidden": !_vm.isOpen(_vm.rightName) },
          },
          [
            _c("SidebarToggleBar", {
              attrs: { name: _vm.rightName, "placement-right": true },
            }),
            _vm._v(" "),
            _vm._t("rightSidebar"),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }