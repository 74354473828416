<template>
    <div v-if="isLoading" class="text-center">
        <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading&hellip;</span>
        </div>
    </div>

    <div v-else-if="noItemHistory" class="text-center">No history</div>

    <div v-else>
        <div class="list-group list-group-flush">
            <div v-for="item in items" class="list-group-item d-flex p-0">
                <div class="m-2 mb-0">
                    <UserAvatar :user-id="item.createdBy" :tooltip-placement="'right'"/>
                </div>

                <div class="flex-fill list-group list-group-flush m-0">
                    <ItemHistoryChange v-for="(oldValue, property) in item.data.old" :key="property" :form-id="formId"
                                       :property="property" :table-name="tableName" :id="id" :value="oldValue"
                                       :new-value="item.data?.new?.[property]" :old-value="oldValue"
                                       class="list-group-item px-0 visible-on-hover" style="border-style: dashed"/>
                </div>

                <div :title="getDate(item.createdDate)" class="m-2 mb-0 mt-1 text-nowrap small text-end code">
                    {{ getDate(item.createdDate) }}
                    <br>
                    {{ getTime(item.createdDate) }}
                </div>
            </div>
        </div>

        <div class="mt-3 text-center">
            <div v-if="!loadingMore">
                <p v-if="isShowingAll" class="small fst-italic">Showing all</p>
                <p v-else-if="total">Showing 1 to {{ start + limit }} of {{ total }}</p>

                <div v-if="!isShowingAll">
                    <Button @click.native="viewMore" class="border">View {{ limit }} more</Button>
                </div>
            </div>

            <div v-else class="spinner-border" role="status">
                <span class="visually-hidden">Loading&hellip;</span>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'

import UserAvatar from '../UserAvatar'
import ItemHistoryChange from './ItemHistoryChange'
import Button from "../Button.vue"

export default {
    name: "ItemHistory",
    components: {
        Button,
        UserAvatar,
        ItemHistoryChange
    },
    props: {
        formId: String,
    },
    data() {
        return {
            start: 0,
            limit: 5,
            loadingMore: false,
            total: null
        }
    },
    computed: {
        tableName() {
            return this.$store.state[this.formId].tableName
        },
        id() {
            return this.$store.state[this.formId].id
        },
        componentId() {
            return this.$store.state.components.componentIds[this.tableName]
        },
        isLoading() {
            return this.items === null
        },
        noItemHistory() {
            return this.items?.length === 0
        },
        isShowingAll() {
            return this.start + this.limit > this.total
        }
    },
    asyncComputed: {
        async items() {
            const o = await this.$store.dispatch('request/get', {
                url: 'api/activity/' + this.componentId + '/' + this.id,
                params: {
                    start: this.start,
                    limit: this.limit
                }
            })

            this.total = o.data.total

            let items = this.items || []

            items.push(...o.data.items)

            this.loadingMore = false

            return items
        }
    },
    watch: {
        loadingMore(newValue) {
            if (!newValue) {
                const el = this.$el.closest('.offcanvas-body')

                // this.$nextTick wouldn't work, no matter how many levels I added, but this does for some reason
                setTimeout(() => {
                    el.scrollTo(0, el.scrollHeight)
                }, 0)
            }
        }
    },
    methods: {
        viewMore() {
            this.loadingMore = true

            this.start += this.limit
        },
        getDate(timestamp) {
            return moment(timestamp * 1000).format('DD/MM/YY')
        },
        getTime(timestamp) {
            return moment(timestamp * 1000).format('HH:mm')
        }
    }
}
</script>

<style scoped>

</style>