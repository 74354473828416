<template>
    <div v-if="contentAreaName" class="d-inline">
        <i class="bi-chevron-compact-right mx-1 align-middle"/>

        <Button class="btn-sm lh-1 p-1" type="button" @click.native="selectContentArea">
            <small>{{ contentAreaName }}</small>
        </Button>
    </div>
</template>

<script>
import Button from "../../common/Button.vue"

export default {
    name: "PageEditorToolbarContentAreaBtn",
    components: {Button},
    computed: {
        pageEditor() {
            return this.$store.state.pageEditor
        },
        pageId() {
            return this.pageEditor.pageId
        },
        activePageContentId() {
            return this.pageEditor.activePageContentId
        },
        activeContent() {
            return this.pageEditor.contentItems.find(o => o.pageContentId === this.activePageContentId)
        },
        contentAreaName() {
            return this.pageEditor.activeContentAreaName || this.activeContent?.contentAreaName
        },
    },
    methods: {
        async selectContentArea() {
            if (this.$route.query.area !== this.contentAreaName) {
                await this.$router.push({
                    name: 'pageEditor',
                    params: {pageId: this.pageId},
                    query: {area: this.contentAreaName}
                })
            }

            this.$store.commit('pageEditor/leftSidebarActiveTab', 'Content')

            await this.$store.dispatch('pageEditor/scrollToContentArea', {
                contentAreaName: this.contentAreaName
            })
        }
    }
}
</script>

<style scoped>

</style>