<template>
    <Button v-if="hasAccess" class="btn-sm" @click.native="edit">Edit</Button>
</template>

<script>
import Button from "../Button.vue"

export default {
    name: "TableViewEditBtn",
    components: {Button},
    props: {
        viewId: String,
        id: Number,
        selectMode: Boolean,
    },
    computed: {
        tableName() {
            return this.$store.state[this.viewId].tableName
        },
        hasAccess() {
            if (
                this.tableName.indexOf('product_variations__') === 0
                || this.selectMode
            ) {
                return false
            }

            return this.$store.getters['user/access'](this.tableName, 'edit')
        },
    },
    methods: {
        edit() {
            window.location = '/#/' + this.tableName + '/' + this.id
        }
    }
}
</script>

<style scoped>

</style>