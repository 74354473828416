var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "select",
    {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.formData.schoolId,
          expression: "formData.schoolId",
        },
      ],
      staticClass: "form-select form-select-sm",
      attrs: {
        required: "",
        name: "school",
        oninvalid: "this.setCustomValidity('Please select a school')",
        oninput: "this.setCustomValidity('')",
      },
      on: {
        change: function ($event) {
          var $$selectedVal = Array.prototype.filter
            .call($event.target.options, function (o) {
              return o.selected
            })
            .map(function (o) {
              var val = "_value" in o ? o._value : o.value
              return val
            })
          _vm.$set(
            _vm.formData,
            "schoolId",
            $event.target.multiple ? $$selectedVal : $$selectedVal[0]
          )
        },
      },
    },
    [
      _c("option", { attrs: { value: "" } }, [_vm._v("Select…")]),
      _vm._v(" "),
      _vm._l(_vm.schools, function (school) {
        return _vm.schools
          ? _c("option", { key: school.id, domProps: { value: school.id } }, [
              _vm._v("\n        " + _vm._s(school.name) + "\n    "),
            ])
          : _vm._e()
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }