<template>
    <fieldset :id="fieldsetId" ref="fieldset" :name="legend" class="fieldset">
        <legend v-if="!isFirst && showLegends" class="legend">
            {{ legend }}
        </legend>

        <FormGroup v-for="(field, index) in fieldsetsFields" :key="field.id" :field="field" :form-id="formId"
                   :hide-help-text="hideHelpText" class="mb-4 pb-3"/>
    </fieldset>
</template>

<script>
import FormGroup from './FormGroup'

export default {
    name: "FormFieldset",
    components: {
        FormGroup,
    },
    props: {
        formId: String,
        isFirst: Boolean,
        isLast: Boolean,
        legend: String,
        showLegends: Boolean,
        hideHelpText: Boolean
    },
    computed: {
        tableName() {
            return this.$store.state[this.formId].tableName
        },
        fieldsetId() {
            return this.legend.replace(/[^A-Z0-9]+/ig, '_')
        },
        fields() {
            return this.$store.state[this.formId].fields
        },
        fieldsetsFields() {
            let fields = []

            let fieldsets = this.$store.state.fieldsets.items
            if (fieldsets.length) {
                if (this.legend === 'Scheduling') {
                    fields = this.fields.filter(o => ['validFrom', 'validTo'].indexOf(o.columnName) > -1)
                } else if (this.legend === 'SEO') {
                    fields = this.fields.filter(o => ['metaTitle', 'metaDescription', 'noIndexNoFollow'].indexOf(o.columnName) > -1)
                } else {
                    const componentId = this.$store.state.components.componentIds[this.tableName]
                    const fieldset = this.$store.state.fieldsets.items
                        .find(o => o.componentId === componentId && o.legend === this.legend)
                    if (fieldset) {
                        fields = this.fields
                            .filter(o => o.fieldsetId === fieldset.id)
                            .sort((a, b) => (a.displayOrder > b.displayOrder) ? 1 : -1)
                    }
                }

                // todo - deprecated - pre fieldsets form
                /*} else {
                    let obj = this.fields[0]
                    let currentLegend = obj.type === 'fieldset'
                        ? obj.title
                        : 'General' // If the first element in the form is not a legend then default to General

                    this.fields.forEach((obj) => {
                        // As we pass each legend element update the currentLegend variable.
                        if (obj.type === 'fieldset') {
                            currentLegend = obj.title
                        }

                        // Only include fields whilst the currentLegend matches ctrl.legend.
                        if (currentLegend === this.legend) {
                            fields.push(obj)
                        }
                    })*/
            }

            return fields
        },
        //isSidebarToggleOpen() {
        //    return this.$store.state.sidebarToggle[this.tableName]
        //}
    },
    mounted() {
        this.resizeLastFieldset()
    },
    //watch: {
    //    isSidebarToggleOpen() {
    //        this.resizeLastFieldset()
    //    }
    //},
    methods: {
        resizeLastFieldset() {
            if (this.isLast) {
                this.$nextTick(() => {
                    const fieldset = this.$refs.fieldset

                    //if (this.isSidebarToggleOpen) {
                    const scrollableEl = fieldset.closest('.sidebar-toggle-section')
                    if (scrollableEl) {
                        // If the last fieldset is too short, when the anchor is used the form won't scroll far enough.
                        // This is fixed by adding bottom margin to the fieldset. The margin is calculated by subtracting
                        // the height of the area above the form and the height of the last fieldset itself from the
                        // viewport height.
                        let top = this.$refs.fieldset.closest('form').getBoundingClientRect().top
                        let marginBottom = window.innerHeight - fieldset.offsetHeight - top
                        if (marginBottom > 0) {
                            fieldset.style.marginBottom = marginBottom + 'px'
                        }
                    }
                    //} else {
                    //    fieldset.style.height = ''
                    //}
                })
            }
        }
    }
}
</script>

<style scoped>
.fieldset {
    margin: 0 0 50px;
}

.fieldset:last-child {
    margin-bottom: 0;
}

.legend {
    margin-bottom: 0;
    padding: 0 0 15px 0;
    color: #999;
    font-size: 36px;
    font-weight: 300;
    border-bottom: none;
}

.form-groups-container {
    padding: 15px;
    border-radius: 15px;
    background: #eee;
}

.form-groups-container > :last-child {
    margin-bottom: 5px;
}

.modal .form-groups-container {
    border-radius: 5px;
}

fieldset > :last-child {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
}
</style>