<template>
    <a role="button" class="list-group-item list-group-item-action py-2 strong text-uppercase small" :class="classNames"
       @mouseover="onMouseover" @mouseout="onMouseout" @click="onClick">
        <small class="flex-fill">{{ contentAreaName }}</small>
    </a>
</template>

<script>
export default {
    name: "PageEditorContentNavContentArea",
    props: {
        contentAreaName: String
    },
    computed: {
        pageEditor() {
            return this.$store.state.pageEditor
        },
        pageId() {
            return this.pageEditor.pageId
        },
        activeContentAreaName() {
            return this.pageEditor.activeContentAreaName
        },
        hoverContentAreaName() {
            return this.pageEditor.hoverContentAreaName
        },
        classNames() {
            if (this.contentAreaName === this.activeContentAreaName) {
                return 'active'
            } else if (this.contentAreaName === this.hoverContentAreaName) {
                return 'list-group-item-hover'
            } else {
                return 'text-body-tertiary'
            }
        }
    },
    methods: {
        onMouseover() {
            this.$store.commit('pageEditor/hoverContentAreaName', this.contentAreaName)
        },
        onMouseout() {
            this.$store.commit('pageEditor/hoverContentAreaName', '')
        },
        async onClick() {
            if (this.$route.params.pageId !== this.pageId || this.$route.query.area !== this.contentAreaName) {
                await this.$router.push({
                    name: 'pageEditor',
                    params: {pageId: this.pageId},
                    query: {area: this.contentAreaName}
                })
            }

            await this.$store.dispatch('pageEditor/scrollToContentArea', {contentAreaName: this.contentAreaName})
        }
    }
}
</script>

<style scoped>
.list-group-item {
    border-color: transparent !important;
}

.list-group-item-hover {
    z-index: 1;
    color: var(--bs-list-group-action-hover-color);
    text-decoration: none;
    background-color: var(--bs-list-group-action-hover-bg);
}
</style>