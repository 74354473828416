<template>
    <Button v-if="!isHeaderOrFooterContent" @click.native="moveUp" class="btn-sm d-flex align-items-center gap-2">
        <i class="bi bi-chevron-up"></i>
        Move up
    </Button>
</template>

<script>
import Button from "../../common/Button.vue"

export default {
    name: "PageEditorContentMoveUpBtn",
    components: {Button},
    computed: {
        pageEditor() {
            return this.$store.state.pageEditor
        },
        pageContentId() {
            return this.pageEditor.activePageContentId
        },
        pageContent() {
            return this.pageEditor.contentItems.find(o => o.pageContentId === this.pageContentId)
        },
        isHeaderOrFooterContent() {
            return this.pageContent?.contentAreaName === 'Page header' || this.pageContent?.contentAreaName === 'Page footer'
        }
    },
    methods: {
        moveUp() {
            this.$store.dispatch('pageEditor/moveUp', this.pageContentId)
        }
    }
}
</script>

<style scoped>

</style>