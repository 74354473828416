<template>
    <aside class="border-end overflow-y-scroll" :class="{'open':activeTab,'closed':!activeTab}">
        <section class="d-flex h-100">
            <div class="d-flex flex-column" style="margin-top: -1px">
                <PageEditorContentPanelTabBtn v-for="tab in tabs" :key="tab.name" :icon-class="tab.icon"
                                              :tab-name="tab.name" :active-tab-prop-name="'leftSidebarActiveTab'"
                                              :border-class-name="'border-end'"/>

                <div class="flex-fill bg-body-tertiary border-end"></div>
            </div>

            <!-- Right margin is used here so that when overflow-scroll kicks in for controls too wide for the sidebar,
                 some spacing appears to the right of the controls. -->
            <div v-if="activeTab" class="flex-fill overflow-scroll">
                <template v-if="activeTab === 'Site tree'">
                    <PageEditorSiteTree/>
                </template>

                <PageEditorContentNav v-if="activeTab === 'Content'"/>
            </div>
        </section>
    </aside>
</template>

<script>
import siteTree from "../../store/modules/siteTree"
import PageEditorContentPanelTabBtn from "./PageEditorContentPanelTabBtn.vue"
import PageEditorSiteTree from "./PageEditorSiteTree.vue"
import PageEditorContentNav from "./PageEditorContentNav.vue"

export default {
    name: "PageEditorLeftSidebar",
    components: {
        PageEditorContentNav,
        PageEditorContentPanelTabBtn,
        PageEditorSiteTree
    },
    data() {
        return {
            tabs: [
                {name: 'Site tree', icon: 'bi-diagram-3'},
                {name: 'Content', icon: 'bi-list'}
            ]
        }
    },
    computed: {
        pageEditor() {
            return this.$store.state.pageEditor
        },
        activeTab() {
            return this.pageEditor.leftSidebarActiveTab
        },
        siteTreeId() {
            return this.pageEditor.siteTreeId
        },
        pageId() {
            return this.pageEditor.pageId || this.$store.state.settings.homePage
        },
        activeContentAreaName() {
            return this.pageEditor.activeContentAreaName
        },
        activePageContentId() {
            return this.pageEditor.activePageContentId
        }
    },
    created() {
        if (this.activePageContentId || this.activeContentAreaName) {
            this.pageEditor.leftSidebarActiveTab = 'Content'
        }

        if (!this.$store.hasModule(this.siteTreeId)) {
            this.$store.registerModule(this.siteTreeId, siteTree)
        }
        this.$store.dispatch(this.siteTreeId + '/init', {
            onPageSelect: (pageId) => {
                if (
                    this.pageEditor.pageId !== pageId
                    || this.$route.query.area
                    || this.$route.query.id
                ) {
                    this.$store.commit(this.siteTreeId + '/selectedPageId', pageId, {root: true})
                    this.$router.push({name: 'pageEditor', params: {pageId: pageId}})
                }
            }
        })

        this.setPageId()
    },
    watch: {
        pageId() {
            this.setPageId()
        }
    },
    methods: {
        setPageId() {
            this.$store.dispatch(this.siteTreeId + '/setPageId', this.pageId)
            this.$store.dispatch(this.siteTreeId + '/setExpandedPageIds', this.pageId)
        }
    }
}
</script>

<style scoped>
.closed {
    flex: 0 0 32px
}

.open {
    flex: 0 0 250px
}
</style>