var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "modal-dialog modal-lg site-tree-modal" }, [
    _c("div", { staticClass: "modal-content" }, [
      _c(
        "div",
        { staticClass: "modal-header" },
        [
          _c("SiteTreeNavigationMenu", {
            staticClass: "w-auto",
            attrs: { "site-tree-id": _vm.siteTreeId },
          }),
          _vm._v(" "),
          _c("button", {
            staticClass: "btn-close",
            attrs: { "aria-label": "Close", type: "button" },
            on: {
              click: function ($event) {
                return _vm.$emit("hide-modal")
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "modal-body" },
        [_c("SiteTree", { attrs: { "site-tree-id": _vm.siteTreeId } })],
        1
      ),
      _vm._v(" "),
      _vm.propagatedContentId
        ? _c("div", { staticClass: "modal-footer" }, [
            _c(
              "form",
              {
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.saveSelectedPageIds.apply(null, arguments)
                  },
                },
              },
              [
                _c(
                  "Button",
                  {
                    staticClass: "ms-1 border",
                    attrs: {
                      type: "submit",
                      disabled: _vm.isSaveBtnDisabled,
                      "active-class": "btn-warning",
                      "active-value": !_vm.isSaveBtnDisabled,
                    },
                  },
                  [_vm._v("\n                    Save\n                ")]
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }