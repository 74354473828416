<template>
    <div v-show="contentIds.length">
        <div class="p-1 px-2 small fw-bold text-body-secondary">
            <span class="small">{{ contentArea }}</span>
        </div>

        <div class="list-group">
            <SiteTreeContentItem v-for="contentId in contentIds" :key="contentId" :site-tree-id="siteTreeId"
                                 :page-content-id="contentId"/>
        </div>
    </div>
</template>

<script>
import SiteTreeContentItem from './SiteTreeContentItem'

export default {
    name: "SiteTreeContentArea",
    components: {
        SiteTreeContentItem,
    },
    props: {
        siteTreeId: String,
        pageId: Number,
        contentArea: String
    },
    computed: {
        contentIds() {
            return this.$store.state.pageContentData.items
                .filter(o => o.pageId === this.pageId && o.contentArea === this.contentArea)
                .map(o => o.id)
        }
    }
}
</script>

<style scoped>

</style>