var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasGroupAccess ||
    _vm.isUnpublished ||
    _vm.isPending ||
    _vm.isExpiring ||
    _vm.isExpired ||
    _vm.isArchived
    ? _c("div", { staticClass: "d-inline-flex gap-1" }, [
        _vm.hasGroupAccess
          ? _c("i", {
              staticClass: "bi bi-person-fill",
              attrs: { title: "This page is assigned group access" },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.isArchived
          ? _c("i", {
              staticClass: "bi bi-archive-fill",
              class: { "text-danger": !_vm.isActive },
              attrs: { title: "Archived" },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.isUnpublished
          ? _c("i", {
              staticClass: "bi bi-x-circle-fill",
              class: { "text-danger": !_vm.isActive },
              attrs: { title: "Unpublished" },
            })
          : _vm.isPending
          ? _c("i", {
              staticClass: "bi bi-clock-fill",
              class: { "text-info": !_vm.isActive },
              attrs: { title: "Pending" },
            })
          : _vm.isExpiring
          ? _c("i", {
              staticClass: "bi bi-clock-fill",
              class: { "text-warning": !_vm.isActive },
              attrs: { title: "Expiring" },
            })
          : _vm.isExpired
          ? _c("i", {
              staticClass: "bi bi-clock-fill",
              class: { "text-danger": !_vm.isActive },
              attrs: { title: "Expired" },
            })
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }