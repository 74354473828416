var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.show
    ? _c(
        "form",
        {
          ref: "form",
          staticClass: "input-group d-inline-flex w-auto",
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.onSubmit.apply(null, arguments)
            },
          },
        },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.name,
                expression: "name",
              },
            ],
            ref: "input",
            staticClass: "form-control form-control-sm",
            attrs: {
              list: "savedFilterNames",
              autocomplete: "false",
              placeholder: "Name",
              required: "",
            },
            domProps: { value: _vm.name },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.name = $event.target.value
              },
            },
          }),
          _vm._v(" "),
          _vm.savedFilterNames
            ? _c(
                "datalist",
                { attrs: { id: "savedFilterNames" } },
                _vm._l(_vm.savedFilterNames, function (name) {
                  return _c("option", { domProps: { value: name } })
                }),
                0
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "Button",
            {
              staticClass: "btn-sm border border-start-0 focus-ring",
              attrs: { type: "submit" },
            },
            [_vm._v("Save")]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }