<template>
    <span>
        <Button @click.native="openSiteTreeModal" class="btn-sm border me-2">Browse…</Button>
        <PagePath class="small" v-if="pageId" :page-id="pageId"/>
    </span>
</template>

<script>
import PagePath from '../../common/PagePath'
import Button from "../Button.vue"

export default {
    name: "CollectionFilterControlTypeInternalLink",
    components: {
        Button,
        PagePath
    },
    props: {
        field: Object,
        item: Object
    },
    computed: {
        pageId() {
            return parseInt(this.item.value)
        }
    },
    methods: {
        openSiteTreeModal() {
            this.$store.dispatch('modals/show', {
                componentName: 'SiteTreeModal',
                obj: {
                    pageId: this.pageId,
                    onPageSelect: pageId => this.item.value = pageId
                }
            })
        }
    }
}
</script>

<style scoped>

</style>