var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.tableName
    ? _c(
        "div",
        [
          _vm._l(_vm.itemIds, function (itemId) {
            return _vm.itemIds
              ? _c(
                  "div",
                  {
                    staticClass:
                      "mb-1 text-bg-light rounded p-1 small d-flex align-items-center",
                  },
                  [
                    _c("FormControlCategoryTitle", {
                      staticClass: "flex-fill",
                      attrs: {
                        "table-name": _vm.tableName,
                        id: parseInt(itemId),
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "Button",
                      {
                        staticClass: "ms-2 btn-sm border",
                        nativeOn: {
                          click: function ($event) {
                            return _vm.removeItem(itemId)
                          },
                        },
                      },
                      [_c("i", { staticClass: "bi bi-x" })]
                    ),
                  ],
                  1
                )
              : _vm._e()
          }),
          _vm._v(" "),
          _c(
            "Button",
            {
              staticClass: "btn-sm border",
              nativeOn: {
                click: function ($event) {
                  return _vm.openSelectListModal.apply(null, arguments)
                },
              },
            },
            [_c("i", { staticClass: "bi bi-plus-lg" })]
          ),
        ],
        2
      )
    : !_vm.componentId
    ? _c(
        "small",
        { staticClass: "text-danger form-text ms-2 mb-1 fst-italic small" },
        [_vm._v("Error: No\n    collection targeted")]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }