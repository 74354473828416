var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "select",
    {
      staticClass: "form-select form-select-sm w-auto",
      on: {
        change: _vm.setPropagatedContent,
        click: function ($event) {
          $event.stopPropagation()
          $event.preventDefault()
        },
      },
    },
    [
      _c("option", { attrs: { selected: "", disabled: "" } }, [
        _vm._v("Select…"),
      ]),
      _vm._v("-->\n    "),
      _vm._l(_vm.pageTemplatesContentAreas, function (contentArea) {
        return _c(
          "option",
          { domProps: { selected: contentArea === _vm.selectedContentArea } },
          [_vm._v("\n        " + _vm._s(contentArea) + "\n    ")]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }