var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "offcanvas",
      staticClass: "offcanvas offcanvas-start w-50",
      attrs: {
        "data-bs-scroll": "true",
        "data-bs-backdrop": "false",
        tabindex: "-1",
        id: _vm.offCanvasId,
        "aria-labelledby": _vm.offCanvasId + "Label",
      },
    },
    [
      _c("div", { staticClass: "offcanvas-header" }, [
        _c(
          "h5",
          {
            staticClass: "offcanvas-title",
            attrs: { id: _vm.offCanvasId + "Label" },
          },
          [_vm._v("Filters")]
        ),
        _vm._v(" "),
        _c("button", {
          staticClass: "btn-close",
          attrs: {
            type: "button",
            "data-bs-dismiss": "offcanvas",
            "aria-label": "Close",
          },
        }),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "offcanvas-body" },
        [
          _c("SearchFilterSavedFiltersMenu", {
            attrs: { "view-id": _vm.viewId },
          }),
          _vm._v(" "),
          _c("SearchFilters", { attrs: { "view-id": _vm.viewId } }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "pt-3 d-flex align-items-center" },
            [
              _c(
                "div",
                { staticClass: "flex-fill" },
                [
                  _c(
                    "Button",
                    {
                      staticClass: "btn-sm border focus-ring",
                      nativeOn: {
                        click: function ($event) {
                          return _vm.addFilter.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "bi bi-plus-lg" }),
                      _vm._v(" filter\n                "),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("SearchFilterSaveFiltersForm", {
                attrs: { "view-id": _vm.viewId },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }