<template>
    <div>
        <FormPublishBtn :form-id="formId" class="mb-3"/>
        <FormDisplayOrderMenu v-if="showDisplayOrderOption && currentData.id" :form-id="formId" class="mb-2"/>
        <FormDuplicateBtn v-if="!isHeaderOrFooterContent" :form-id="formId"/>
        <PageEditorDetachFromPageBtn :form-id="formId"/>
        <FormDeleteBtn :form-id="formId" :always-show="!isHeaderOrFooterContent"/>
    </div>
</template>

<script>
import FormDisplayOrderMenu from "../../common/form/FormDisplayOrderMenu"
import PageEditorDetachFromPageBtn from "../../common/form/PageEditorDetachFromPageBtn"
import FormDuplicateBtn from "../../common/form/FormDuplicateBtn"
import FormDeleteBtn from "../../common/form/FormDeleteBtn"
import FormPublishBtn from "../../common/form/FormPublishBtn"

export default {
    name: "PageEditorContentSettings",
    components: {
        FormPublishBtn,
        FormDeleteBtn,
        FormDuplicateBtn,
        PageEditorDetachFromPageBtn,
        FormDisplayOrderMenu
    },
    props: {
        formId: String
    },
    computed: {
        pageEditor() {
            return this.$store.state.pageEditor
        },
        pageContentId() {
            return this.pageEditor.activePageContentId
        },
        pageContent() {
            return this.pageEditor.contentItems.find(o => o.pageContentId === this.pageContentId)
        },
        isHeaderOrFooterContent() {
            return this.pageContent?.contentAreaName === 'Page header' || this.pageContent?.contentAreaName === 'Page footer'
        },
        currentData() {
            return this.$store.state[this.formId].currentData
        },
        showDisplayOrderOption() {
            return this.$store.getters[this.formId + '/showDisplayOrderOption']
        }
    }
}
</script>

<style scoped>

</style>