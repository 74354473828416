<template>
    <div class="modal-dialog">
        <div class="modal-content">
            <div v-if="options.modalTitle" class="modal-header">
                <h4 class="modal-title">{{ options.modalTitle }}</h4>

                <button aria-label="Close" class="btn-close" type="button" @click="$emit('hide-modal')"></button>
            </div>

            <div class="modal-body">
                <div v-html="options.modalBody"></div>

                <form v-if="confirmStr" @submit.prevent="onSubmit">
                    <p>
                        Please type <strong>{{ confirmStr }}</strong> to confirm.
                    </p>

                    <p>
                        <input v-model="input" v-select autocomplete="no" class="form-control" type="text">
                    </p>

                    <p>
                        <button :disabled="isDisabled" class="btn btn-danger mt-2 w-100">
                            I understand the consequences, confirm delete.
                        </button>
                    </p>
                </form>
            </div>

            <div v-if="!confirmStr" class="modal-footer text-end">
                <Button @click.native="$emit('hide-modal')">Cancel</Button>
                <Button ref="submitBtn" @click.native="onSubmit" :default-class="'btn-warning'">OK</Button>
            </div>
        </div>
    </div>
</template>

<script>
import Button from "../../common/Button.vue"

export default {
    name: "ConfirmModal",
    components: {Button},
    props: {
        options: Object,
    },
    data() {
        return {
            confirmStr: this.options.confirmStr,
            onConfirm: this.options.onConfirm,
            input: ''
        }
    },
    mounted() {
        if (this.$refs.submitBtn) {
            this.$refs.submitBtn.$el.focus()
        }
    },
    computed: {
        isDisabled() {
            return this.input !== this.confirmStr
        }
    },
    methods: {
        onSubmit() {
            this.$emit('hide-modal')
            this.onConfirm()
        },
    },
}
</script>

<style scoped>

</style>