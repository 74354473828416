var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ms-3 my-1 rounded-1 bg-secondary-subtle p-2 pt-1 small" },
    _vm._l(_vm.contentAreas, function (contentArea) {
      return _vm.contentAreas.length
        ? _c("SiteTreeContentArea", {
            key: contentArea,
            attrs: {
              "content-area": contentArea,
              "page-id": _vm.pageId,
              "site-tree-id": _vm.siteTreeId,
            },
          })
        : _vm._e()
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }