<template>
    <div>
        <BigBrandBedsReports v-if="isBigBrandBeds"/>
        <OdpReports v-if="isOdp"/>
        <PrintGiftsReports v-if="isPrintGifts"/>
    </div>
</template>

<script>
import BigBrandBedsReports from './BigBrandBedsReports'
import OdpReports from './OdpReports'
import PrintGiftsReports from './PrintGiftsReports'

export default {
    name: "Reports",
    components: {
        BigBrandBedsReports,
        OdpReports,
        PrintGiftsReports
    },
    data() {
        return {}
    },
    computed: {
        sitename() {
            return this.$store.state.sitename
        },
        isBigBrandBeds() {
            return this.sitename.indexOf('bigbrandbeds') === 0
        },
        isOdp() {
            return this.sitename.indexOf('outdoorsproject') === 0
        },
        isPrintGifts() {
            return this.sitename.indexOf('printgifts') === 0
        }
    }
}
</script>

<style scoped>

</style>