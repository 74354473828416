var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.page
    ? _c(
        "Button",
        {
          staticClass: "btn-sm lh-1 p-1",
          attrs: { type: "button" },
          nativeOn: {
            click: function ($event) {
              return _vm.selectPage.apply(null, arguments)
            },
          },
        },
        [_c("small", [_vm._v(_vm._s(_vm.page.title))])]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }