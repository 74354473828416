var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "list-group list-group-flush" },
    [
      _vm.currentData.modifiedDate
        ? _c(
            "a",
            {
              staticClass:
                "list-group-item list-group-item-action border-bottom-0 d-flex align-items-start px-2 py-1 small",
              attrs: {
                "data-bs-toggle": "offcanvas",
                role: "button",
                "data-bs-target": "#historyOffcanvas",
              },
            },
            [
              _vm.currentData.modifiedBy
                ? _c("UserAvatar", {
                    staticClass: "mt-1 me-2",
                    attrs: {
                      "user-id": _vm.currentData.modifiedBy,
                      "is-small": false,
                      "tooltip-placement": "right",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "small",
                [
                  _vm.currentData.modifiedBy
                    ? _c("UserName", {
                        attrs: {
                          id: _vm.currentData.modifiedBy,
                          "no-user-found-str": "<em>user not found</em>",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.getDate(_vm.currentData.modifiedDate * 1000)) +
                      "\n        "
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("FormHistoryOffcanvas", { attrs: { "form-id": _vm.formId } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }