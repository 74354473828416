<template>
    <Button data-bs-toggle="offcanvas" :data-bs-target="'#' + offCanvasId" :aria-controls="offCanvasId"
            class="btn-sm btn-link text-decoration-none">
        <small>filters</small>
    </Button>
</template>

<script>
import Button from "../Button.vue"

export default {
    name: "SearchFilterToolbarFiltersBtn",
    components: {Button},
    props: {
        viewId: String,
        offCanvasId: String
    }
}
</script>

<style scoped>

</style>