<template>
    <section class="d-flex h-100">
        <div v-if="activeTab" class="wrapper flex-fill overflow-scroll">
            <div class="d-flex flex-column h-100">
                <!-- Right margin is used here so that when overflow-scroll kicks in for controls too wide for the
                     sidebar, some spacing appears to the right of the controls. -->
                <div class="flex-fill p-3 pe-0 me-3 overflow-scroll">
                    <PageEditorPageForm v-if="activeTab === 'Edit'" :form-id="formId" :key="'edit'"/>
                    <PageEditorPageForm v-else-if="['Scheduling', 'Access', 'SEO', 'Settings'].indexOf(activeTab) > -1"
                                        :form-id="formId" :key="activeTab"/>
                </div>

                <footer class="border-top">
                    <PageEditorModified :form-id="formId"/>
                </footer>
            </div>
        </div>

        <div class="d-flex flex-column" style="margin-top: -1px">
            <PageEditorContentPanelTabBtn v-for="tab in tabs" :key="tab.name" :icon-class="tab.icon"
                                          :tab-name="tab.name" :active-tab-prop-name="'pagePanelActiveTab'"
                                          :border-class-name="'border-start'"/>
            <div class="flex-fill bg-body-tertiary border-start"></div>
        </div>
    </section>
</template>

<script>
import PageEditorContentPanelTabBtn from "./PageEditorContentPanelTabBtn.vue"
import PageEditorPageForm from "./PageEditorPageForm.vue"
import PageEditorModified from "./PageEditorModified.vue"
import PageEditorContentMoveUpBtn from "./PageEditorContentMoveUpBtn.vue"
import PageEditorContentForm from "./PageEditorContentForm.vue"
import PageEditorContentMoveDownBtn from "./PageEditorContentMoveDownBtn.vue"

export default {
    name: "PageEditorPagePanel",
    components: {
        PageEditorContentMoveDownBtn,
        PageEditorContentForm,
        PageEditorContentMoveUpBtn, PageEditorModified, PageEditorPageForm, PageEditorContentPanelTabBtn},
    data() {
        return {
            tabs: [
                {name: 'Edit', icon: 'bi-pencil'},
                {name: 'Scheduling', icon: 'bi-calendar-date'},
                {name: 'Access', icon: 'bi-person-lock'},
                {name: 'SEO', icon: 'bi-bar-chart'},
                {name: 'Settings', icon: 'bi-gear'}
            ]
        }
    },
    computed: {
        pageEditor() {
            return this.$store.state.pageEditor
        },
        pageId() {
            return this.pageEditor.pageId
        },
        activeTab() {
            return this.pageEditor.pagePanelActiveTab
        },
        formId() {
            return 'form:pages:' + this.pageId
        }
    },
    watch: {
        activeTab: {
            immediate: true,
            handler() {
                let fieldNames = []
                switch (this.activeTab) {
                    case 'Edit':
                        fieldNames = ['title', 'url', 'parent', 'navigation', 'hidden', 'templateId', 'type', 'componentId', 'displayInSitemap']
                        break
                    case 'Scheduling':
                        fieldNames = ['validFrom', 'validTo']
                        break
                    case 'Access':
                        fieldNames = ['groupAccess']
                        break
                    case 'SEO':
                        fieldNames = ['metaTitle', 'metaDescription', 'noIndexNoFollow']
                        break
                    case 'Settings':
                        fieldNames = ['']
                        break
                }

                this.$store.dispatch('forms/createForm', {
                    tableName: 'pages',
                    id: this.pageId,
                    fieldNames: fieldNames,
                    formSmall: true
                })
            }
        }
    }
}
</script>

<style scoped>
.wrapper {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.wrapper::-webkit-scrollbar {
    display: none;  /* Safari and Chrome */
}
</style>