<template>
    <div>
        <div v-if="navigationGroup === selectedNavigationGroup" v-for="(pageIds, navigationGroup) in groupedPageIds"
             :key="navigationGroup" class="list-group list-group-flush">
            <SiteTreePageItem v-for="pageId in pageIds" :key="pageId" :page-id="pageId" :site-tree-id="siteTreeId"/>
        </div>
    </div>
</template>

<script>
import SiteTreePageItem from './SiteTreePageItem'

export default {
    name: "SiteTree",
    components: {
        SiteTreePageItem,
    },
    props: {
        siteTreeId: String
    },
    computed: {
        groupedPageIds() {
            return this.$store.state[this.siteTreeId]?.groupedPageIds
        },
        selectedNavigationGroup() {
            return this.$store.state[this.siteTreeId]?.selectedNavigationGroup
        }
    },
    beforeCreate() {
        this.$store.dispatch('templates/init')
    }
}
</script>

<style scoped>

</style>