<template>
    <div class="sidebar-toggle">
        <aside v-if="$slots.leftSidebar" :class="{ 'sidebar-toggle-hidden': !isOpen(leftName) }"
               class="sidebar-toggle-left position-relative d-none d-md-flex">
            <slot name="leftSidebar"></slot>

            <SidebarToggleBar :name="leftName"/>
        </aside>

        <section class="sidebar-toggle-section">
            <slot></slot>
        </section>

        <aside v-if="$slots.rightSidebar" :class="{ 'sidebar-toggle-hidden': !isOpen(rightName) }"
               class="sidebar-toggle-right d-none d-md-flex">
            <SidebarToggleBar :name="rightName" :placement-right="true"/>

            <slot name="rightSidebar"></slot>
        </aside>
    </div>
</template>

<script>
import SidebarToggleBar from './SidebarToggleBar'

export default {
    name: "SidebarToggle",
    components: {
        SidebarToggleBar
    },
    props: {
        name: String
    },
    computed: {
        leftName() {
            return this.name + 'Left'
        },
        rightName() {
            return this.name + 'Right'
        },
    },
    methods: {
        isOpen(name) {
            return this.$store.state.sidebarToggle[name]
        }
    },
}
</script>

<style scoped>
.sidebar-toggle {
    display: flex;
}

.sidebar-toggle-left {
    display: flex;
    flex: 0 1px;
    transition: all 300ms;
    overflow-y: scroll;
}

.sidebar-toggle-left > :first-child {
    flex: 1;
}

.sidebar-toggle-section {
    flex: 1;
    overflow-y: auto;
}

.sidebar-toggle-right {
    position: relative;
    display: flex;
    flex: 0 1;
    transition: all 300ms;
    overflow-y: scroll;
}

.sidebar-toggle-right > :last-child {
    flex: 1;
}

/* SIZES */

/* Left sidebar */

.sidebar-toggle-left {
    flex-basis: 160px;
    font-size: .9rem;
}

.sidebar-toggle-hidden.sidebar-toggle-left {
    margin-left: -150px; /* The aside flex width minus the toggle bar's width */
}

@media (min-width: 992px) {
    .sidebar-toggle-left {
        flex-basis: 220px;
        font-size: 1rem;
    }

    .sidebar-toggle-hidden.sidebar-toggle-left {
        margin-left: -212px; /* The aside flex width minus the toggle bar's width */
    }
}

/* Right sidebar */

.sidebar-toggle-right {
    flex-basis: 220px;
}

.sidebar-toggle-hidden.sidebar-toggle-right {
    margin-right: -210px; /* The aside flex width minus the toggle bar's width */
}

@media (min-width: 1200px) {
    .sidebar-toggle-right {
        flex-basis: 280px;
    }

    .sidebar-toggle-hidden.sidebar-toggle-right {
        margin-right: -270px; /* The aside flex width minus the toggle bar's width */
    }
}

</style>