<template>
    <div class="list-group-item py-1 pe-2 d-flex align-items-center">
        <div class="flex-fill">
            <FormPageContentField :content-id="contentId"/>
        </div>

        <ContentUsageCount :page-content-id="pageContentDataItem.id" :show-menu="true" class="me-2 lh-1"/>

        <Button class="btn-sm" @click.native="goToContent"><i class="bi-pencil-square"/></Button>
        <Button class="btn-sm" @click.prevent.stop.native="moveUp"><i class="bi-chevron-up"/></Button>
        <Button class="btn-sm" @click.native="removeContent"><i class="bi-x-lg"/></Button>
    </div>
</template>

<script>
import FormPageContentField from "../form/FormPageContentField.vue"
import ContentUsageCount from "../ContentUsageCount.vue"
import Button from "../Button.vue"

export default {
    name: "PageContentOrphanedItem",
    components: {Button, ContentUsageCount, FormPageContentField},
    props: {
        pageContentDataItem: Object,
        lastContentArea: String
    },
    computed: {
        contentId() {
            return this.pageContentDataItem.contentId
        },
        isSiteTree() {
            return this.$route.name === 'siteTree'
        }
    },
    asyncComputed: {
        item() {
            return this.$store.dispatch('itemData/get', {
                tableName: 'content',
                id: this.contentId
            })
        }
    },
    methods: {
        goToContent() {
            this.isSiteTree
                ? this.$router.push({
                    name: 'siteTree',
                    params: {
                        pageOrContentId: 'c' + this.pageContentDataItem.id
                    }
                })
                : this.$router.push({
                    name: 'form',
                    params: {
                        component: 'content',
                        id: this.pageContentDataItem.contentId
                    }
                })
        },
        moveUp() {
            // Get the number of items in the new area
            let items = this.$store.state.pageContentData.items.filter((obj) => {
                return (
                    obj.pageId === this.pageContentDataItem.pageId
                    && obj.contentArea === this.lastContentArea
                )
            })
            let newDisplayOrder = items.length + 1

            this.$store.dispatch('request/post', {
                    url: 'api/move-page-content',
                    postData: {
                        pageContentLinkId: this.pageContentDataItem.id,
                        contentArea: this.lastContentArea,
                        displayOrder: newDisplayOrder
                    }
                })
                .then(() => {
                    this.$store.dispatch('pageContentData/init')
                })
        },
        removeContent() {
            this.$store.dispatch('modals/show', {
                componentName: 'ConfirmModal',
                obj: {
                    modalTitle: 'Are you sure?',
                    //modalBody: '<p>Deleting <strong>' + obj.title + '</strong>.</p>',
                    onConfirm: () => {
                        this.$store.dispatch('request/delete', {
                            url: 'api/delete-page-content-link-id/' + this.pageContentDataItem.id
                        }).then(() => {
                            this.$store.dispatch('pageContentData/init')
                        })
                    }
                }
            })
        }
    }
}
</script>

<style scoped>

</style>