<template>
    <div>
        <FormSidebarBtn v-if="id" data-bs-toggle="offcanvas" data-bs-target="#historyOffcanvas">
            <i class="me-2 bi-clock-history"/>
            Open history
        </FormSidebarBtn>

        <FormHistoryOffcanvas :form-id="formId"/>
    </div>
</template>

<script>
import FormSidebarBtn from "./FormSidebarBtn.vue"
import FormHistoryOffcanvas from "./FormHistoryOffcanvas.vue"

export default {
    name: "FormHistoryBtn",
    components: {FormHistoryOffcanvas, FormSidebarBtn},
    props: {
        formId: String
    },
    computed: {
        id() {
            return this.$store.state[this.formId].id
        }
    }
}
</script>

<style scoped>

</style>