export default {
    namespaced: true,
    state: {
        pageTemplatesContentIds: {},
        pageTemplatesContentAreas: undefined
    },
    mutations: {
        pageTemplatesContentIds(state, {templateId, contentIds}) {
            state.pageTemplatesContentIds[templateId] = contentIds
        },
        pageTemplatesContentAreas(state, pageTemplatesContentAreas) {
            state.pageTemplatesContentAreas = pageTemplatesContentAreas
        }
    },
    getters: {
        contentAreas: (state, getters, rootState) => (templateHtml) => {
            let contentAreas = []
            
            if (templateHtml) {
                // First check if the template extends a base template
                let extendsMatch = templateHtml.match(/\{% extends '(.*)' %}/)
                if (extendsMatch) {
                    const title = extendsMatch[1]
                    const item = rootState.templates.items.find(o => o.title === title)
                    let parentHtml = item.templateHtml
                    
                    // Replace the parent HTML's blocks with those found in the page template
                    let blockMatches = templateHtml.matchAll(/\{% block (.*) %}((.|\n)*){% endblock %}/g)
                    for (const match of blockMatches) {
                        const blockName = match[1]
                        const replaceHtml = match[2]
                        // Note, the ? after * makes it non-greedy. Without this, multiple end blocks had issues
                        const regEx = new RegExp('{% block ' + blockName + ' %}((.|\n)*?){% endblock %}')
                        parentHtml = parentHtml.replace(regEx, replaceHtml)
                    }
                    
                    templateHtml = parentHtml
                }
                
                let matches = templateHtml.match(/content\[['"]{1}([A-Za-z0-9-_ ]+)['"]]{1}/g)
                if (matches) {
                    matches.forEach((match) => {
                        const quote = match.indexOf("'") > -1 ? "'" : '"' // Single or double
                        const contentArea = match.split(quote)[1]
                        contentAreas.push(contentArea)
                    })
                }
            }
            
            return contentAreas
        },
        contentIds: () => (templateHtml) => {
            let contentIds = []
            
            if (templateHtml) {
                const matches = [...templateHtml.matchAll(/content\(([0-9]+)\)/g)]
                if (matches) {
                    matches.forEach((match) => {
                        contentIds.push(parseInt(match[1]))
                    })
                }
            }
            
            return contentIds
        },
    },
    actions: {
        async init({state, dispatch, rootGetters, getters, commit}) {
            if (state.pageTemplatesContentAreas === undefined) {
                await dispatch('templates/init', false, {root: true})
                
                const areas = {}
                rootGetters['templates/pageTemplates'].forEach((o) => {
                    areas[o.id] = getters['contentAreas'](o.templateHtml)
                })
                
                commit('pageTemplatesContentAreas', areas)
            }
        },
        getPagesContentAreas({dispatch, rootState, getters}, pageId) {
            return dispatch('itemData/get', {
                tableName: 'pages',
                id: pageId,
            }, {root: true})
                .then((obj) => {
                    const templateId = obj.templateId
                    
                    return dispatch('itemData/get', {
                        tableName: 'templates_templates',
                        id: templateId,
                    }, {root: true})
                        .then((o) => {
                            return getters['contentAreas'](o.templateHtml)
                        })
                })
        },
        getPageTemplatesContentIds({state, dispatch, rootState, commit}, templateId) {
            // New page forms don't have a templateId
            if (templateId) {
                const contentIds = state.pageTemplatesContentIds[templateId]
                if (contentIds) {
                    return contentIds
                }
                
                return dispatch('request/get', {
                    url: rootState.websiteEndpoint + '/controller/page_template_content_ids/' + templateId
                }, {root: true})
                    .then((obj) => {
                        commit('pageTemplatesContentIds', {
                            templateId: templateId,
                            contentIds: obj.data.contentIds
                        })
                        
                        return obj.data.contentIds
                    })
            }
        },
        getAllContentAreas({dispatch, getters, rootGetters}) {
            return dispatch('templates/init', false, {root: true})
                .then(() => {
                    const items = rootGetters['templates/pageTemplates']
                    
                    let areas = []
                    items.forEach((o) => {
                        areas = areas.concat(getters['contentAreas'](o.templateHtml))
                    })
                    areas = [...new Set(areas)] // Unique
                    
                    return areas.sort()
                })
        }
    }
}