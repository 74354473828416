var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { ref: "container" }, [
        _c("nav", { staticClass: "navbar navbar-default" }, [
          _c("div", { staticClass: "container-fluid" }, [
            _c(
              "form",
              {
                ref: "form",
                staticClass: "float-start d-flex align-items-center gap-1",
              },
              [
                _c("ReportMenu", {
                  attrs: { "reports-module": _vm.reportsModule },
                }),
                _vm._v(" "),
                _vm.formData.reportName === "relatedProducts"
                  ? [
                      _c("PrimaryCategoryFilter"),
                      _vm._v(" "),
                      _c("DateRangeFilter", {
                        attrs: { "reports-module": _vm.reportsModule },
                      }),
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.formData.reportName !== ""
                  ? _c(
                      "button",
                      {
                        staticClass: "ms-2 btn btn-primary btn-sm",
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.submitForm()
                          },
                        },
                      },
                      [_vm._v("Go\n                    ")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.rows && _vm.rows.length
                  ? _c("span", { staticClass: "ms-2 text-nowrap" }, [
                      _vm._v(
                        "\n                        " +
                          _vm._s(_vm._f("numberFormat")(_vm.rows.length)) +
                          " records found\n                    "
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.isLoading
                  ? _c("span", { staticClass: "ms-2 ajax-spinner" }, [
                      _c("span", {
                        staticClass: "ajax-spinner-icon ajax-spinner-icon-sm",
                      }),
                    ])
                  : _vm._e(),
              ],
              2
            ),
            _vm._v(" "),
            _vm.rows && _vm.rows.length
              ? _c("div", { staticClass: "float-end" }, [
                  _vm._m(0),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-default btn-transparent",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.downloadCSVData.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "bi-download" }),
                      _vm._v(" "),
                      _c("span", { staticClass: "sr-only" }, [
                        _vm._v("Download CSV"),
                      ]),
                    ]
                  ),
                ])
              : _vm._e(),
          ]),
        ]),
        _vm._v(" "),
        _vm.rows && _vm.rows.length === 0
          ? _c("p", { staticClass: "mx-2 alert alert-warning" }, [
              _vm._v("\n            No records match your search.\n        "),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.error
          ? _c("p", {
              staticClass: "mx-2 alert alert-warning",
              domProps: { innerHTML: _vm._s(_vm.error) },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.rows && _vm.rows.length
          ? _c("div", { staticClass: "padding-sm table-wrapper" }, [
              _c(
                "table",
                { staticClass: "table table-condensed table-striped" },
                [
                  _c("thead", [
                    _c(
                      "tr",
                      _vm._l(_vm.rows[0], function (value, heading) {
                        return _c(
                          "td",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.setSortBy(heading)
                              },
                            },
                          },
                          [
                            heading === _vm.sortBy
                              ? _c("span", {
                                  class: {
                                    "bi-chevron-up": _vm.sortDir === "asc",
                                    "bi-chevron-down": _vm.sortDir === "desc",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(
                              "\n                        " +
                                _vm._s(heading) +
                                "\n                    "
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    _vm._l(_vm.sortedRows, function (row) {
                      return _c(
                        "tr",
                        _vm._l(row, function (value, heading) {
                          return _c("td", {
                            domProps: { innerHTML: _vm._s(value) },
                          })
                        }),
                        0
                      )
                    }),
                    0
                  ),
                ]
              ),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _vm.rows
        ? _c("PrintReport", {
            ref: "toPrint",
            staticStyle: { display: "none" },
            attrs: {
              heading: _vm.reportLabel,
              rows: _vm.sortedRows,
              "start-date": _vm.startDate,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          ref: "modal",
          staticClass: "modal fade",
          attrs: {
            id: "printModal",
            "aria-labelledby": "myModalLabel",
            role: "dialog",
            tabindex: "-1",
          },
        },
        [
          _c("div", { staticClass: "modal-dialog modal-fullscreen" }, [
            _c("div", { staticClass: "modal-content" }, [
              _vm._m(1),
              _vm._v(" "),
              _c("div", { staticClass: "modal-body" }, [
                _c("iframe", {
                  ref: "iframe",
                  attrs: { srcdoc: _vm.iframeHtml },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "modal-footer" }),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "btn btn-default btn-transparent",
        attrs: {
          "data-target": "#printModal",
          "data-toggle": "modal",
          type: "button",
        },
      },
      [
        _c("i", { staticClass: "bi-printer" }),
        _vm._v(" "),
        _c("span", { staticClass: "sr-only" }, [_vm._v("Print report")]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-header" }, [
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            "aria-label": "Close",
            "data-dismiss": "modal",
            type: "button",
          },
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      ),
      _vm._v(" "),
      _c("h4", { staticClass: "modal-title" }, [_vm._v("Print report")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }