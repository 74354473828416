<template>
    <div class="offcanvas offcanvas-start" tabindex="-1" id="contentTypeFieldsetOffcanvas"
         aria-labelledby="contentTypeFieldsetOffcanvasLabel">
        <div class="offcanvas-header">
            <h5 class="offcanvas-title" id="contentTypeFieldsetOffcanvasLabel">{{ title }}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>

        <div class="offcanvas-body">
            <form ref="form">
                <input v-model="formData.legend" name="legend" class="form-control" placeholder="Legend">
                <div class="mt-3 d-flex">
                    <button class="btn btn-primary" :disabled="formData.legend === ''">Save</button>
                </div>
            </form>
        </div>

        <div class="p-3 text-end">
            <button v-if="fieldsetId" class="btn btn-danger" @click.prevent="deleteFieldset">Delete</button>
        </div>
    </div>
</template>

<script>
import {Offcanvas} from "bootstrap"

export default {
    name: "ContentTypeFieldsetOffcanvas",
    data() {
        return {
            bsOffcanvas: undefined
        }
    },
    computed: {
        formData() {
            return this.$store.state.componentFields.fieldsetFormData
        },
        fieldsetId() {
            return this.formData.id
        },
        title() {
            return this.fieldsetId ? 'Edit fieldset' : 'New fieldset'
        }
    },
    mounted() {
        this.bsOffcanvas = new Offcanvas('#contentTypeFieldsetOffcanvas')

        // Focus on the Legend field
        const offcanvasNode = document.getElementById('contentTypeFieldsetOffcanvas')
        offcanvasNode.addEventListener('shown.bs.offcanvas', event => {
            this.$refs.form.querySelectorAll('[name="legend"]')[0].select()
        })
        offcanvasNode.addEventListener('hidden.bs.offcanvas', event => {
            this.$store.state.componentFields.fieldsetFormData = {}
        })

        this.$refs.form.addEventListener('submit', (e) => {
            e.preventDefault()

            if (this.fieldsetId) {
                this.$store.dispatch('request/patch', {
                        url: 'api/component/fieldset/' + this.fieldsetId,
                        postData: {
                            legend: this.formData.legend
                        }
                    })
                    .then(this.onComplete)
            } else {
                this.$store.dispatch('request/post', {
                        url: 'api/component/fieldset',
                        postData: {
                            componentId: this.$store.state.componentFields.componentId,
                            legend: this.formData.legend
                        }
                    })
                    .then((obj) => {
                        this.$router.push({
                            name: 'componentFields',
                            params: {
                                fieldsetId: obj.data.id,
                                fieldId: null
                            }
                        })
                    })
                    .then(this.onComplete)
            }

        })
    },
    methods: {
        deleteFieldset() {
            // The offcanvas has to close otherwise the form in the confirmation can't be clicked.
            this.bsOffcanvas.hide()

            const fieldsetId = this.fieldsetId

            this.$store.dispatch('modals/show', {
                componentName: 'ConfirmModal',
                obj: {
                    modalTitle: 'Deleting the fieldset "' + this.formData.legend + '"',
                    modalBody: `
                          <div class="alert alert-danger">
                            This action will result in loss of data.
                          </div>
                          <p>
                            This action <strong>cannot</strong> be undone. All fields of the fieldset
                            <strong>` + this.formData.legend + `</strong> will be permanently deleted.
                          </p>
                        `,
                    confirmStr: this.formData.legend,
                    onConfirm: () => {
                        this.$store.dispatch('request/delete', {
                                url: 'api/component/fieldset/' + fieldsetId
                            })
                            .then(this.onComplete)
                            .then(() => {
                                this.$router.push({
                                    name: 'componentFields',
                                    params: {
                                        fieldsetId: null,
                                        fieldId: null
                                    }
                                })
                            })
                    }
                }
            })
        },
        onComplete() {
            return this.$store.dispatch('componentFields/reloadFieldsets')
                .then(() => {
                    this.bsOffcanvas.hide()
                })
        }
    }
}
</script>

<style scoped>

</style>