<template>
    <div class="pt-1 list-group list-group-flush">
        <template v-for="contentAreaName in contentAreaNames">
            <PageEditorContentNavContentArea :content-area-name="contentAreaName"/>

            <PageEditorContentNavContent :content-area-name="contentAreaName"/>
        </template>
    </div>
</template>

<script>
import PageEditorContentNavContent from "./PageEditorContentNavContent"
import PageEditorContentNavContentArea from "./PageEditorContentNavContentArea.vue"

export default {
    name: "PageEditorContentNav",
    components: {PageEditorContentNavContentArea, PageEditorContentNavContent},
    computed: {
        pageEditor() {
            return this.$store.state.pageEditor
        },
        contentAreaNames() {
            return this.pageEditor.contentAreas.map(o => o.name)
        }
    }
}
</script>

<style scoped>

</style>