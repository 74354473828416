var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isBigBrandBeds ? _c("BigBrandBedsReports") : _vm._e(),
      _vm._v(" "),
      _vm.isOdp ? _c("OdpReports") : _vm._e(),
      _vm._v(" "),
      _vm.isPrintGifts ? _c("PrintGiftsReports") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }