var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    [
      _c(
        "Button",
        {
          staticClass: "btn-sm border me-2",
          nativeOn: {
            click: function ($event) {
              return _vm.openSiteTreeModal.apply(null, arguments)
            },
          },
        },
        [_vm._v("Browse…")]
      ),
      _vm._v(" "),
      _vm.pageId
        ? _c(
            "span",
            { staticClass: "well well-sm m-0 p-1 text-nowrap" },
            [_c("PagePath", { attrs: { "page-id": _vm.pageId } })],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }