<template>
    <div class="modal-dialog modal-lg site-tree-modal">
        <div class="modal-content">
            <div class="modal-header">
                <SiteTreeNavigationMenu :site-tree-id="siteTreeId" class="w-auto"/>

                <button aria-label="Close" class="btn-close" type="button" @click="$emit('hide-modal')"></button>
            </div>

            <div class="modal-body">
                <SiteTree :site-tree-id="siteTreeId"/>
            </div>

            <div v-if="propagatedContentId" class="modal-footer">
                <form @submit.prevent="saveSelectedPageIds">
                    <Button type="submit" class="ms-1 border" :disabled="isSaveBtnDisabled" :active-class="'btn-warning'"
                            :active-value="!isSaveBtnDisabled">
                        Save
                    </Button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import siteTree from '../../store/modules/siteTree'

import SiteTreeNavigationMenu from '../../common/site-tree/SiteTreeNavigationMenu'
import SiteTree from '../../common/site-tree/SiteTree'
import Button from "../../common/Button.vue"

export default {
    name: "SiteTreeModal",
    components: {Button, SiteTreeNavigationMenu, SiteTree},
    props: {
        options: Object
    },
    data() {
        return {
            siteTreeId: undefined,
            showContent: this.options.showContent || this.options.selectContentMode,
            // todo - This doesn't work, because it cannot detect the pageContentId from a contentId.
            //  For example, click the ShowSiteTreeBtn when editing content doesn't allow the modal to know which
            //  specific content of what page was clicked.
            //contentId: this.options.contentId,
            pageContentLinkId: this.options.pageContentLinkId,
            pageId: this.options.pageId,
            originalSelectedPageIds: [],
            onPageSelect: this.options.onPageSelect,
            onContentSelect: this.options.onContentSelect,
            onSave: this.options.onSave,
            selectContentMode: this.options.selectContentMode,

            // Content propagation properties
            propagatedContentId: this.options.propagatedContentId,
            originalPropagatedPageIds: []
        }
    },
    computed: {
        isSaveBtnDisabled() {
            const pageIds = this.$store.state[this.siteTreeId].propagationData.map(o => o.pageId).sort()
            return JSON.stringify(this.originalPropagatedPageIds) === JSON.stringify(pageIds)
        },
        pageContentLinkItem() {
            if (this.pageContentLinkId) {
                return this.$store.state.pageContentData.items.find(o => o.id === this.pageContentLinkId)
            }
        }
    },
    async created() {
        this.siteTreeId = 'siteTreeModal'
        this.$store.registerModule(this.siteTreeId, siteTree)
        await this.$store.dispatch(this.siteTreeId + '/init', {
            selectContentMode: this.selectContentMode,
            showContent: this.showContent,
            propagatedContentId: this.propagatedContentId,
            onPageSelect: pageId => {
                if (this.onPageSelect) {
                    const bool = this.onPageSelect(pageId)
                    if (bool || bool === undefined) {
                        this.$emit('hide-modal')
                    }
                }
            },
            onContentSelect: pageContentId => {
                const bool = this.onContentSelect(pageContentId)

                if (bool || bool === undefined) {
                    this.$emit('hide-modal')
                }
            }
        })

        this.setSelectedVars()

        if (this.propagatedContentId) {
            this.originalPropagatedPageIds = this.$store.state[this.siteTreeId].propagationData.map(o => o.pageId).sort()

            await this.$store.dispatch('pageTemplatesContentAreas/init')
        }
    },
    destroyed() {
        this.$store.unregisterModule(this.siteTreeId)
    },
    methods: {
        setSelectedVars() {
            if (this.pageId) {
                this.$store.dispatch(this.siteTreeId + '/setPageId', this.pageId)
                this.$store.dispatch(this.siteTreeId + '/setExpandedPageIds', this.pageId)
            } else if (this.pageContentLinkId) {
                this.$store.dispatch(this.siteTreeId + '/setContentId', this.pageContentLinkId)
                let pageId = this.pageContentLinkItem.pageId
                this.$store.commit(this.siteTreeId + '/addExpandedPageId', pageId)
                this.$store.dispatch(this.siteTreeId + '/setExpandedPageIds', pageId)
            }
        },
        saveSelectedPageIds() {
            this.onSave({
                propagationData: this.$store.state[this.siteTreeId]?.propagationData
            })

            this.$emit('hide-modal')
        }
    }
}
</script>

<style scoped>

</style>